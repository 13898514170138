import React, { Children, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: "flex",
        '& > *': {
            marginRight: "0.5rem"
        },
        '& > *:last-child': {
            marginRight: 0
        },
    },
});


const LabelsField = withStyles(styles)(({ classes, className, children, ...props }) => {
    return (
        <div className={classes.root}>
        { Children.map(children, field =>
            field && isValidElement(field)
            ? React.cloneElement(field, {...props})
            : null
        )}
        </div>
    )
});


LabelsField.propTypes = {
    addLabel: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};


LabelsField.defaultProps = {
    addLabel: true,
};

export default LabelsField;