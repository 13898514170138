import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import OrderAttachedIcon from '@material-ui/icons/AttachFile';
import DeadlineIcon from '../Icons/Skull';

const styles = theme => ({
    nowrap: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
});

const FileAttachedField = ({ className, classes, record, translate, ...props }) => {
    if (!record) return null;

    return (record.files && record.files.length > 0)
    ? (
        <Tooltip title={translate("resources.Order.helper.filesAttached")} placement="top">
            <Typography variant="body1" color="textSecondary" noWrap >
                <OrderAttachedIcon />
            </Typography>
        </Tooltip> 
    )
    : null;
} 


FileAttachedField.propTypes = {
    addLabel: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};


FileAttachedField.defaultProps = {
    classes: {},
    record: {},
    addLabel: true,
};

export default compose(
    translate,
    withStyles(styles)
)(FileAttachedField);