import React from 'react';
import {translate} from 'react-admin';

const TypeField = ({ record = {}, translate, ...props }) => ( 
    
<span>{translate(`resources.Customer.types.${record.type}`)}</span>

);

TypeField.defaultProps = {  
    source: 'type',
    addLabel: true
};

export default translate(TypeField);




