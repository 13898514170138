import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import get from 'lodash/get';
import { getFormValues, FormName, change } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';



const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    flex: { display: "flex" },
    spacer: { width: "1em" },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});



export const CCSVTypeInput = ({ form, formData, change, className, classes, ...props }) => {
    const { ccsvType } = formData;
    const ccsvTypeId = get(formData, ["ccsvTypeId"])
    const counterpartyName = get(formData, ["verifier", "counterparty", "name"])
    const [manual, setManual] = useState(false);
    const blocked = !manual || !!ccsvTypeId;

    useEffect(() => {
        if (!ccsvType) return;
        change(form, 'ccsvTypeCCSV', ccsvType.ccsv);
    }, [ccsvType])

    const handleManual = (event) => {
        if (!manual) {
            change(form, 'ccsvTypeId', null);
        }

        setManual(!manual);
    };

    return (
        <div className={classes.root}>

            <div className={classes.flex}>
                <ReferenceInput {...props} className={className} source="ccsvTypeId" reference="CCSVType" filter={{ nameOrg: counterpartyName }} filterToQuery={searchText => ({ ccsv: searchText })} allowEmpty options={{ disabled: !blocked }} >
                    <AutocompleteInput optionText="ccsv" />
                </ReferenceInput>
                <Typography className={classes.message} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                    <span>Это поле получает данные из госреестра ФГИС "АРШИН" УСЛОВНЫЕ ШИФРЫ ЗНАКОВ ПОВЕРКИ, согласно наименованию контрагента Поверителя. Если список шифров пуст, проверьте наименование контрагента Поверителя или введите данные вручную. Дополнительная информация на <a href="https://fgis.gost.ru/fundmetrology/registry/15">https://fgis.gost.ru/fundmetrology/registry/15</a></span>
                </Typography>
            </div>

            <FormControlLabel control={<Checkbox checked={manual} onChange={handleManual} value="manual" color="primary" />} label="Ввести данные шифра знака поверки вручную" />

            <Grid container spacing={16}>
                <Grid item xs={12} sm={4}>
                    <TextInput {...props} disabled={blocked} source="ccsvTypeCCSV" options={{ fullWidth: true }} />
                </Grid>
            </Grid>
        </div>


    )
};

const mapStateToProps = (state, { form, record }) => {

    const formData = getFormValues(form)(state) || record;

    const ccsvTypeId = get(formData, ["ccsvTypeId"])
    const ccsvType = ccsvTypeId ? get(state, ["admin", "resources", "CCSVType", "data", ccsvTypeId]) : undefined;

    return {
        formData: {
            ...formData,
            ccsvType,
        },
    }
};

const mapDispatchToProps = {
    change,
}






const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
);

const ConnectedFormDataConsumerView = enhance(CCSVTypeInput);

const FormDataConsumer = (props) => (
    <FormName>
        {({ form }) => <ConnectedFormDataConsumerView form={form} {...props} />}
    </FormName>
);




export default FormDataConsumer;
