import dataProviderCore from './Core/graphqlProvider'
import dataProviderBlob from './Blob/graphqlProvider'
import dataProviderFias from './Fias/graphqlProvider'
import dataProviderFgis from './Fgis/graphqlProvider'


const dataProviders = [dataProviderCore, dataProviderBlob, dataProviderFias, dataProviderFgis];

export const dataProviderFactory = () => {
  return Promise.resolve((type, resource, params) => {

    const findDataProvider = dataProviders.find(dp => dp.knownResources.includes(resource));

    if (!findDataProvider) throw new Error('Provider is not loaded')
    return findDataProvider.dataProvider(type, resource, params);
  });
};