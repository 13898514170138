import gql from 'graphql-tag'
import { apolloNotificationsClient } from './graphqlProvider';

const CLEAR_NOTIFICATION = gql`
    mutation disconnectNotification($id: ID!) { 
        disconnectNotification(id: $id) { 
            id
        }
    }
`;


export default (args) => {
    return apolloNotificationsClient.mutate({
        mutation: CLEAR_NOTIFICATION,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    });
};
