import gql from 'graphql-tag'
import { apolloNotificationsClient } from './graphqlProvider';

const GET_MORE_NOTIFICATIONS = gql`
    query moreNotifications($cursor: String, $first: Int, $sortField: String, $sortOrder: String, $filter: NotificationFilter) {
        items: moreNotifications(cursor: $cursor, first: $first, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
            id 
            app 
            title 
            body 
            data 
            actions 
            tag 
            requireInteraction 
            type 
            priority 
            ttl 
            ttlAt 
            timestamp
        }
        total: _allNotificationsMeta(filter: $filter) {
            count
        }
    }
`;

export default (args) => {
    return apolloNotificationsClient.query({
        query: GET_MORE_NOTIFICATIONS,
        fetchPolicy: 'network-only',
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    });
};
