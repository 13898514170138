import React from 'react';
import compose from 'recompose/compose';
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import PhoneIcon from '@material-ui/icons/Call';
import MeterIcon from '@material-ui/icons/DevicesOther';
import { ability } from '../../ability'

import AddressIcon from '@material-ui/icons/Place';
import CommentIcon from '@material-ui/icons/Comment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import StatusChip from '../Fields/StatusChip';
import { DeadlineChip } from '../Fields/DeadlineField';
import ArchiveChip from '../Fields/ArchiveChip';
import MeterField from '../Meter/MeterField';
import LinkField from '../Fields/linkField';
import PhoneField from '../Fields/PhoneField';

const styles = theme => ({
    avatar: {
        width: 40,
        height: 40,
        backgroundColor:theme.palette.grey["400"],
    },
    headline:{
        display: "flex",
    },
    textSecondary:{
        color: theme.palette.text.secondary,
    },
    subheading:{
        display: "flex",
    },
    flex: {
        display: "flex",
    },
    flexColumn: {
        flexDirection: "column"
    },
    justifyEnd: {
        justifyContent: "flex-end"
    },
    mlAuto: {
        marginLeft: "auto",
    },
    ml1:{
        marginLeft: "0.25rem"
    },
    ml2:{
        marginLeft: "0.5rem"
    },
    ml3:{
        marginLeft: "1rem"
    },
    mt2:{
        marginTop: "0.5rem"
    },
    mt3:{
        marginTop: "1rem"
    },
    iconHeadline:{
        fontSize:"inherit",
        marginRight:"0.5rem",
        height: "1.35417em"
    },
    iconSubheading:{
        fontSize:"inherit",
        marginRight:"0.25rem",
        height: "1.5em"
    },
    iconTextSecondary:{
        color:theme.palette.grey["400"],
    },
    chipSuccess:{
        backgroundColor: green["400"],
    },
    chipError:{
        backgroundColor: red["400"],
    },
    iconChip:{
        marginLeft:"4px",
        height: 24,
        width: 24,
    
    },
});

const FastView = ({ className, classes, locales, options={}, translate, record, ...props }) =>{

    if (!record) return null;
   
    return(
        <CardContent >
            <Grid container spacing={16}>
                <Grid item xs={12} sm className={`${classes.flex} ${classes.flexColumn}`}>
                    {(!!record.customer && ability.can('read', "Order", "customer")) && (
                        <div className={`${classes.flex}`}>                        
                            <Avatar className={classes.avatar} >NN</Avatar>
                            <div className={`${classes.ml2}`}>
                                <Typography className={classes.headline} variant="title">{record.customer.name}</Typography>
                                <Typography className={classes.subheading} variant="caption" noWrap><PhoneIcon className={classes.iconSubheading}/><PhoneField record={record.customer} source="phone" variant="caption" color="inherit"/></Typography>
                            </div>
                        </div>
                    )}
                    { ability.can('read', "Order", "addrManual") && (
                    <div className={`${classes.flex} ${classes.ml2} ${classes.mt3}`}>
                        <AddressIcon className={`${classes.iconTextSecondary}`}/>
                        <Typography className={`${classes.ml2}`}  variant="subheading" >{record.addrManual}</Typography>
                    </div>
                    )}
                    { ability.can('read', "Order", "meter") && (
                    <div className={`${classes.flex} ${classes.ml2} ${classes.mt3}`}>
                        <MeterIcon className={`${classes.iconTextSecondary}`}/>
                        <LinkField basePath={props.basePath} record={record} source="meter" reference="Meter" emptyValue="raExt.notification.order_meter_empty_data" className={`${classes.ml2}`} linkType={false}><MeterField variant="subheading" /></LinkField>
                    </div>
                    )}
                    { ability.can('read', "Order", "comment") && (
                    <div className={`${classes.flex} ${classes.ml2} ${classes.mt3}`}>                        
                        <CommentIcon className={`${classes.iconTextSecondary}`}/>
                        <Typography className={`${classes.ml2}`} variant="subheading" >{(!!record.comment)? record.comment : <span className={`${classes.textSecondary}`}>Без комментариев</span>}</Typography>
                    </div>
                    )}
                </Grid>
                <Grid item xs={12} sm className={`${classes.flex} ${classes.justifyEnd}`}>
                    <StatusChip record={record} />
                    { ability.can('read', "Order", "completedDeadlineAt") && <DeadlineChip record={record} className={`${classes.ml1}`} />}
                    <ArchiveChip record={record} className={`${classes.ml1}`} />
                </Grid>
            </Grid>
            <div className={`${classes.flex} ${classes.justifyEnd}`}>
                { ability.can('read', "Order", "creator") && <Typography color="textSecondary" variant="caption" >{`${translate('resources.Order.fields.created')}: ${record.creator.name}`}</Typography> }
                { ability.can('read', "Order", "createdAt") && <Typography color="textSecondary" variant="caption" className={`${classes.ml2}`} >{`${translate('resources.Order.fields.createdAt')}: ${moment(record.createdAt).format('L')}`}</Typography> }
                { ability.can('read', "Order", "updatedAt") && <Typography color="textSecondary" variant="caption" className={`${classes.ml2}`} >{`${translate('resources.Order.fields.updatedAt')}: ${moment(record.updatedAt).format('L')}`}</Typography> }
                { ability.can('read', "Order", "completedDeadlineAt") && <Typography color="textSecondary" variant="caption" className={`${classes.ml2}`} >{`${translate('resources.Order.fields.completedDeadlineAt')}: ${moment(record.completedDeadlineAt).format('L')}`}</Typography> }
                { ability.can('read', "Order", "completedAt") && <Typography color="textSecondary" variant="caption" className={`${classes.ml2}`} >{`${translate('resources.Order.fields.completedAt')}: ${(!record.completedAt) ? translate('raExt.notification.noData') : moment(record.completedAt).format('L')}`}</Typography> }
            </div>
        </CardContent>
    )
};


export default compose( withStyles(styles), translate, )(FastView);

