import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import AdminIcon from '@material-ui/icons/Android';
import DispatcherIcon from '@material-ui/icons/HeadsetMic';
import VerifierIcon from '@material-ui/icons/Build';
import CustomerIcon from '@material-ui/icons/AccountCircle';

const styles = theme => ({
    avatar:{
        color: "inherit",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    },
});

const getRoleIcon = role => {
    switch (role) {
        case "SUPERADMIN":
        case "ADMIN":
        case "MANAGER":{
            return <AdminIcon />
        }
        break;

        case "DISPATCHER":{
            return <DispatcherIcon />
        }
        break;

        case "VERIFIER":{
            return <VerifierIcon />
        }
        break;

        case "EMPLOYEE":{
            return <DispatcherIcon />
        }
        break;

        case "CUSTOMER":{
            return <CustomerIcon />
        }
        break;

        default:{
            return <CustomerIcon />
        }
        break;

    }
}

const RoleChip = ({ className, classes, translate, record, }) => {
    if (!record) return null;
    
    return (
        <Chip avatar={<Avatar>{getRoleIcon(record.role)}</Avatar>} label={translate(`resources.User.roles.${record.role}`)} classes={{avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className}`} />
    );
}


export default compose( withStyles(styles), translate, )(RoleChip);


