import React, { useEffect } from 'react';
import { translate } from 'ra-core';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { initialNotifications, removeNotification, closeNotification } from '../../redux/notifications/actions';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Drawer from '@material-ui/core/Drawer';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import getListNotifications from '../../dataProviders/Notifications/getListNotifications';
import clearAllNotifications from '../../dataProviders/Notifications/clearAllNotifications';
import clearNotifications from '../../dataProviders/Notifications/clearNotifications';
import getMoreNotifications from '../../dataProviders/Notifications/getMoreNotifications';
import InfoBanner from '../Layout/InfoBanner';
import { NAME_APP } from '../../appsettings';
import { NEWORDERSnackbarContent } from './NotificationDefault';
import Notification from './Notification';


const getChildren = (tag) => {
    switch (tag) {
        case "NEWORDER":
            return <Notification />;

        default:
            return null;
    }
};

const styles = theme => ({
    paper: {
        minWidth: "24rem",
        maxWidth: "28rem",
    },
    mlAuto: { marginLeft: "auto" },
    notificationHeader: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    action: {
        marginTop: 0,
    },
    notification: {
        margin: 8,
    },
    notificationWrapper: {
        paddingTop: 0,
    },
    notificationFooter: {
        paddingTop: 0,
        marginTop: "auto",
        display: "flex",
        alignItems: "center",
    },
    getMoreButton: {
        marginLeft: "auto",
        marginTop: -8,
        marginBottom: -8,

    }
});



const NotificationsDrawer = ({ open, setOpen, notifications, total, initialNotifications, enqueueNotification, closeNotification, removeNotification, translate, classes, ...props }) => {

    const availableDistrictIdOrCity = ({ items, total }) => {

        let count = total.count
        const districtIdOrCityIds = localStorage.getItem('districtIdOrCityIds').split(',');

        const myNotifications = items.filter(notification => {
            const districtIdOrCityId = notification.data && notification.data.order && notification.data.order.districtIdOrCityId;
            const isAvaliable = districtIdOrCityId ? districtIdOrCityIds.some(id => id === districtIdOrCityId) : false;
            if (!isAvaliable) { --count }
            return isAvaliable
        });

        return {
            items: myNotifications,
            total: { count: count }
        }
    };

    useEffect(() => {

        async function fetchData() {

            getListNotifications({
                filter: {
                    app: NAME_APP,
                },
                page: 0,
                perPage: 15,
                sortField: "timestamp",
                sortOrder: "DESC",
            }).then(({ data: data }) => {

                const availableData = availableDistrictIdOrCity(data);
                initialNotifications(availableData);

            });

        }


        fetchData();


        return () => {
            initialNotifications([])
        };

    }, []);


    const handleCloseClick = () => {
        setOpen(false);
    };

    const handleClearAllNotifications = () => {
        clearNotifications({ id_in: notifications.map(notification => notification.id) }).then(data => {
            removeNotification()
        });

    };

    const handleGetMoreNotifications = () => {
        const last = notifications[notifications.length - 1]
        getMoreNotifications({
            filter: {
                app: NAME_APP,
            },
            cursor: last.id,
            first: 15,
            sortField: "timestamp",
            sortOrder: "DESC"
        }).then(({ data: data }) => {

            const availableData = availableDistrictIdOrCity(data);
            initialNotifications(availableData);
        });
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleCloseClick} classes={{ paper: classes.paper }} ModalProps={{ hideBackdrop: false }}>
            <CardHeader
                title={translate("resources.Notification.name", { smart_count: 2 })}
                titleTypographyProps={{ variant: 'subheading' }}
                action={
                    <>
                        <Tooltip title={translate("raExt.action.clear_all")} enterDelay={700} >
                            <IconButton aria-label="ClearAll" onClick={handleClearAllNotifications} disabled={!notifications.length > 0}>
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("raExt.action.close")} enterDelay={700}>
                            <IconButton onClick={handleCloseClick} aria-label="Close" >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                classes={{ root: classes.notificationHeader, action: classes.action }}
            />
            { (notifications.length > 0)
                ? (
                    <CardContent className={classes.notificationWrapper}>
                        {notifications.map(notification => {

                            const children = getChildren(notification.tag)

                            return React.cloneElement(children, {
                                key: notification.id,
                                notification,
                            })
                        })}
                    </CardContent>
                )
                : (
                    <InfoBanner
                        title={translate("resources.Notification.no_notifications")}
                        subtitle="Как только появится что-то интересное, Вы об этом узнаете"
                    >
                        <NotificationsOffIcon color="disabled" style={{ fontSize: 48 }} />
                    </InfoBanner>
                )
            }
            <CardContent className={classes.notificationFooter}>
                <Typography variant="caption">{`Отображается ${notifications.length} из ${total.count}`}</Typography>
                {(notifications.length < total.count) && <Button size="small" onClick={handleGetMoreNotifications} className={classes.getMoreButton} >{translate("raExt.action.get_more")}</Button>}
            </CardContent >
        </Drawer>

    );
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications.items,
        total: state.notifications.total,
    }
};

export default compose(
    withStyles(styles),
    translate,
    connect(
        mapStateToProps,
        {
            initialNotifications,
            closeNotification,
            removeNotification,
        },
    )
)(NotificationsDrawer);
