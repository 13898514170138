
import { buildQuery } from 'ra-data-graphql-simple'
import globalBuiltQuery from '../globalBuiltQuery'
import gql from 'graphql-tag'
import { apolloFiasClient } from '../Fias/graphqlProvider';
import { orderBy } from 'lodash';

const GET_LIST_ORDER = gql`
    query allOrders($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: OrderFilter) {
        items: allOrders(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
            id
            humanId
            createdAt
            updatedAt
            completedAt
            statusRef
            resultRef
            resultDesc
            meter {
                id
                type
                name
                verifyInterval
            }
            meterLocation
            meterUninstallAt
            comment
            customer {
                id
                name
                phone
            }
            verifier {
                id
                name
                phone
            }
            dispatcher {
                id
                name
                phone
            }
            creator {
                id
                name
                phone
            }
            regionId
            districtIdOrCityId
            districtId
            cityId
            streetId
            houseNum
            houseCorp
            houseFlat
            addrManual
            files{
                id
                idBlob
                url
                urlThumbnail
                desc
                catalog
                ext
                size
                createdAt
                createdBy
                createdByAuth
            }
            isArchive
            completedDeadlineAt
            invoice
            personalAccount
            isSealNotInstalled
        },
        total: _allOrdersMeta(page: $page, perPage: $perPage, filter: $filter) {
            count                          
        }
    }
`
const GET_MANY_ORDERS = gql`
    query allOrders($filter: OrderFilter) {
        items: allOrders(filter: $filter) {
            id
            humanId
            createdAt
            updatedAt
            completedAt
            statusRef
            resultRef
            resultDesc
            meter {
                id
                type
                name
                verifyInterval
            }
            meterLocation
            meterUninstallAt
            comment
            customer {
                id
                name
                phone
            }
            verifier {
                id
                name
                phone
            }
            dispatcher {
                id
                name
                phone
            }
            creator {
                id
                name
                phone
            }
            regionId
            districtIdOrCityId
            districtId
            cityId
            streetId
            houseNum
            houseCorp
            houseFlat
            addrManual
            files{
                id
                idBlob
                url
                urlThumbnail
                desc
                catalog
                ext
                size
                created
                createdAt
                createdBy
                createdByAuth
            }
            isArchive
            completedDeadlineAt
            invoice
            personalAccount
            isSealNotInstalled
        },
        total: _allOrdersMeta(filter: $filter) {
            count                          
        }
    }
`
const GET_ONE_ORDER = gql`
    query Order($id: ID!) {
        data: Order(id: $id) {
            id
            humanId
            createdAt
            updatedAt
            completedAt
            statusRef
            resultRef
            resultDesc
            meter {
                id
                type
                name
                verifyInterval
            }
            meterLocation
            meterUninstallAt
            comment
            customer {
                id
                name
                phone
            }
            verifier {
                id
                name
                phone
            }
            dispatcher {
                id
                name
                phone
            }
            creator {
                id
                name
                phone
            }
            regionId
            districtIdOrCityId
            districtId
            cityId
            streetId
            houseNum
            houseCorp
            houseFlat
            addrManual
            files{
                id
                idBlob
                url
                urlThumbnail
                desc
                catalog
                ext
                size
                created
                createdAt
                createdBy
                createdByAuth
            }
            isArchive
            completedDeadlineAt
            invoice
            personalAccount
            isSealNotInstalled
            miYear
            miTypeNumber
            miManufactureNum
            miName
            miYearMpi
            miMonthMpi
            miManufacturer
            signCipher
            isSIOwnedByVerifier
            signMi
            signPass
            validAt
            certificateNumber
            stickerNumber
            gsiType
            gsiNumber
            gsiName
            meansUveNumber
        }
    }
`
const UPDATE_ORDER = gql`
    mutation updateOrder( $id: ID!, $isArchive: Boolean, $isFavorite: Boolean, $statusRef:String, $customer:UserOrder, $verifier:UserOrder, $dispatcher:UserOrder, $addrManual: String, $regionId: String, $districtIdOrCityId: String, $districtId: String, $cityId: String, $streetId:String, $houseNum: String, $houseCorp: String, $houseFlat: String, $meter: MeterWhereUniqueInput, $meterLocation: String, $meterUninstallAt: DateTime, $comment: String, $files:FilesInput, $resultRef:String, $resultDesc:String, $invoice: String, $personalAccount: String, $isSealNotInstalled: Boolean, $completedDeadlineAt: DateTime, $nextCompletedDeadlineAt: DateTime ){
        data: updateOrder( id: $id, isArchive: $isArchive, isFavorite: $isFavorite, statusRef: $statusRef, customer: $customer, verifier: $verifier, dispatcher: $dispatcher, addrManual: $addrManual, regionId: $regionId, districtIdOrCityId: $districtIdOrCityId, districtId: $districtId, cityId: $cityId, streetId: $streetId, houseNum: $houseNum, houseCorp: $houseCorp, houseFlat: $houseFlat, meter: $meter, meterLocation: $meterLocation, meterUninstallAt:  $meterUninstallAt, comment: $comment, files:$files, resultRef: $resultRef, resultDesc: $resultDesc, invoice: $invoice, personalAccount: $personalAccount, isSealNotInstalled: $isSealNotInstalled, completedDeadlineAt: $completedDeadlineAt, nextCompletedDeadlineAt: $nextCompletedDeadlineAt) {
            id
            humanId
            createdAt
            updatedAt
            completedAt
            statusRef
            resultRef
            resultDesc
            meter {
                id
                type
                name
                verifyInterval
            }
            meterLocation
            meterUninstallAt
            comment
            customer {
                id
                name
                phone
            }
            verifier {
                id
                name
                phone
            }
            dispatcher {
                id
                name
                phone
            }
            creator {
                id
                name
                phone
            }
            regionId
            districtIdOrCityId
            districtId
            cityId
            streetId
            houseNum
            houseCorp
            houseFlat
            addrManual
            files{
                id
                idBlob
                url
                urlThumbnail
                desc
                catalog
                ext
                size
                created
                createdAt
                createdBy
                createdByAuth
            }
            isArchive
            completedDeadlineAt
            invoice
            personalAccount
            isSealNotInstalled
        }
    }
`



const GET_LIST_ORDERHISTORIES = gql`
    query allOrderHistories($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: OrderHistoryFilter) {
        items: allOrderHistories(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
            id
            order {
                id
                humanId
            }
            orderHumanId
            user {
                id
                name
                phone
            }
            userName
            action
            changes
            meta
            updated
            createdAt
        },
        total: _allOrderHistoriesMeta(page: $page, perPage: $perPage, filter: $filter) {
            count                          
        }
    }
`
const GET_ONE_ORDERHISTORY = gql`
    query OrderHistory($id: ID!) {
        data: OrderHistory(id: $id) {
            id
            order {
                id
                humanId
            }
            orderHumanId
            user {
                id
                name
                phone
            }
            userName
            action
            updated
            changes
            meta
            createdAt
        }
    }
`


const GET_LIST_METERS = gql`
    query allMeters($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: MeterFilter) {
        items: allMeters(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
            id
            type
            name
            description
            documentation {
                id
                url
                desc
                ext
                size
            }
            verifyInterval
        },
        total: _allMetersMeta(page: $page, perPage: $perPage, filter: $filter) {
            count                          
        }
    }
`
const GET_ONE_METER = gql`
    query Meter($id: ID!) {
        data: Meter(id: $id) {
            id
            type
            name
            description
            documentation {
                id
                idBlob
                url
                urlThumbnail
                desc
                catalog
                ext
                size
                createdAt
                createdBy
                createdByAuth
            }
            verifyInterval
        }
    }
`

const UPDATE_METER = gql`
    mutation updateMeter( $id: ID!, $type: String!, $name: String!, $description: String, $verifyInterval: Int, $documentation: FilesInput ){
        data: updateMeter( id: $id, type: $type, name: $name, description: $description, verifyInterval: $verifyInterval, documentation: $documentation) {
            id
            type
            name
            description
            documentation {
                id
                idBlob
                url
                urlThumbnail
                desc
                catalog
                ext
                size
                createdAt
                createdBy
                createdByAuth
            }
            verifyInterval
        }
    }
`

const GET_ONE_USER = gql`
    query User($id: ID!) {
        data: User(id: $id) {
            id
            name
            email
            phone
            role
            authId
            authStatus
            createdAt
            updatedAt
            regionIds
            districtIdOrCityIds
            notificationTags
            isArchive
            online
            customer {
                id
                type
                organizationName
                organizationInn
                payment
            }
        }
    }
`

const GET_PROFILE = gql`
    query Profile($id: ID!) {
        data: Profile(id: $id) {
            id
            name
            email
            phone
            role
            authId
            authStatus
            createdAt
            updatedAt
            regionIds
            districtIdOrCityIds
            notificationTags
            isArchive
            online
            customer {
                id
                type
                organizationName
                organizationInn
                payment
            }
        }
    }
`

export default (apolloCoreClient) => introspection => (fetchType, resource, params) => {

    const builtQuery = globalBuiltQuery(buildQuery(introspection)(fetchType, resource, params), fetchType, resource, params);

    if (resource === 'Statistic' && fetchType === 'GET_LIST') {
        let result = {
            ...builtQuery,
            parseResponse: async function (response) {

                let result = [];
                let total_item = { id: 'Total' };
                const locationIds = response.data.items.map(item => item.id);

                const allLocations = await apolloFiasClient.query({
                    query: gql`
                        query allLocations($filter: LocationFilter) {
                            allLocations(filter: $filter) {
                                id
                                typeShort
                                name
                                unrestrictedName
                                level                        
                        }
                    }
                    `,
                    variables: {
                        filter: {
                            ids: locationIds
                        }
                    },
                    onError: (error) => {
                        alert(error)
                    }
                });

                const locations = allLocations.data.allLocations

                result = response.data.items.filter(item => item.id !== 'Total').map(item => {

                    Object.keys(item).forEach(prop => {
                        if (prop === 'id') return;
                        if (total_item[prop] === undefined) {
                            total_item[prop] = item[prop];
                        } else {
                            total_item[prop] += item[prop];
                        }
                    });

                    const location = locations.find(location => item.id === location.id);

                    return { ...item, ...location }

                });

                const sortField = builtQuery.variables.sortField || "id"
                const sortOrder = builtQuery.variables.sortOrder || "DESC"

                result = orderBy(result, [sortField], [sortOrder.toLowerCase()]);

                result.push(total_item);

                response.data.items = result

                return builtQuery.parseResponse(response);
            }
        }

        return result;
    }

    if (resource === 'Profile') {

        if (fetchType === 'GET_ONE') {
            return {
                ...builtQuery,
                query: GET_PROFILE,
            };

        }

        if (fetchType === 'UPDATE') {
            return {
                ...builtQuery,
                parseResponse: response => {
                    const { data: { data: user } } = response;
                    localStorage.setItem('regionIds', user.regionIds);
                    localStorage.setItem('districtIdOrCityIds', user.districtIdOrCityIds);
                    return builtQuery.parseResponse(response);
                },
            };
        }
    };

    if (resource === 'User') {
        switch (fetchType) {
            case 'GET_ONE':
                return {
                    ...builtQuery,
                    query: GET_ONE_USER
                };
                break;

            default: break;

        }


    }

    if (resource === 'Order') {

        switch (fetchType) {
            case 'GET_LIST':
            case 'GET_MANY_REFERENCE': {

                if (builtQuery.variables.filter.oneOfUserManyId) {
                    let id = builtQuery.variables.filter.oneOfUserManyId;
                    delete builtQuery.variables.filter.oneOfUserManyId;
                    if (!builtQuery.variables.filter.oneOfUserMany) { builtQuery.variables.filter.oneOfUserMany = {} }
                    builtQuery.variables.filter.oneOfUserMany.id = id;
                }

                return {
                    ...builtQuery,
                    query: GET_LIST_ORDER,
                };
            }

            case 'GET_MANY': {
                return {
                    ...builtQuery,
                    query: GET_MANY_ORDERS,
                };
            }

            case 'GET_ONE': {
                return {
                    ...builtQuery,
                    query: GET_ONE_ORDER,

                };
            }

            case 'UPDATE': {
                return {
                    ...builtQuery,
                    query: UPDATE_ORDER,

                };
            }
            default: break;
        }
    }

    if (resource === 'OrderHistory') {


        switch (fetchType) {
            case 'GET_LIST':
            case 'GET_MANY_REFERENCE': {
                //TODO: Костыль для отображения истории
                //поле orderId начало появляться после обновления ra-data-graphql-simple на версию 2.9.0 

                if (builtQuery.variables.filter.orderId) {
                    let id = builtQuery.variables.filter.orderId;
                    delete builtQuery.variables.filter.orderId;
                    if (!builtQuery.variables.filter.order) { builtQuery.variables.filter.order = {} }
                    builtQuery.variables.filter.order.id = id;
                }

                if (builtQuery.variables.filter.userId) {
                    let id = builtQuery.variables.filter.userId;
                    delete builtQuery.variables.filter.userId;
                    if (!builtQuery.variables.filter.user) { builtQuery.variables.filter.user = {} }
                    builtQuery.variables.filter.user.id = id;
                }

                return {
                    ...builtQuery,
                    query: GET_LIST_ORDERHISTORIES,
                };
            }

            case 'GET_ONE': {
                return {
                    ...builtQuery,
                    query: GET_ONE_ORDERHISTORY,

                };
            }
            default: break;
        }
    }

    if (resource === 'Meter') {

        switch (fetchType) {
            case 'GET_LIST':
            case 'GET_MANY_REFERENCE': {
                return {
                    ...builtQuery,
                    query: GET_LIST_METERS,
                };
            }

            case 'GET_ONE': {
                return {
                    ...builtQuery,
                    query: GET_ONE_METER,

                };
            }

            case 'UPDATE': {
                return {
                    ...builtQuery,
                    query: UPDATE_METER,

                };
            }
            default: break;
        }
    }

    return builtQuery;
}

