import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';
import PhotoIcon from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import Rocket from '../../files/Rocket.svg';
import { withStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js'



const styles = theme => ({
  paper: {
    borderRadius: 10
  },
  captionIcon: {
    color: theme.palette.text.hint,
    marginRight: "0.5em"
  },
  py0: {
    paddingTop: 0,
    paddingBottom: 0
  },
  dialogTitle: {
    paddingTop: 0,
  },
  rowField: {
    display: "flex",
    alignItems: "baseline",
    marginTop: "1em"
  },
  rowTitle: {
    width: "30%",
    marginRight: "1em"
  },
  rowContent: {
    fontWeight: "bold"
  },
  alignCenter: {
    textAlign: "center"
  },
  media: {
    height: 140,
    backgroundSize: "unset",
  },
  mtAuto: {
    marginTop: "auto"
  },
  captionContent: {
    display: "flex",
    alignItems: "center"
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateOrderDialog = ({record, open, onClose, classes}) => {
  if (!record) return null;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={onClose}
        classes={{paper:classes.paper}}
      >
        <CardMedia
          className={classes.media}
          image={Rocket}
          title="Заявка создана!"
        />
        <DialogTitle className={`${classes.dialogTitle} ${classes.alignCenter}`}>{"Заявка создана!"}</DialogTitle>
        <DialogContent >
          <DialogContentText className={`${classes.alignCenter}`}>Заявка на проведение поверки успешно создана. Для подтверждения заявки с Вами скоро свяжется Диспетчер.</DialogContentText>
        </DialogContent>
        <DialogContent>
            <Typography variant="display1" color="textPrimary" gutterBottom className={`${classes.rowContent} ${classes.alignCenter}`}>{record.humanId}</Typography>
            <DialogContentText color="textPrimary" className={`${classes.alignCenter}`}>{`Заказчик: ${record.customer.name}. Телефон: ${parsePhoneNumberFromString(record.customer.phone, 'RU').formatNational()}. Населенный пункт: ${record.addrManual}.`}</DialogContentText>
        </DialogContent>
        <DialogContent className={`${classes.captionContent}`}>
          <PhotoIcon className={`${classes.captionIcon}`}/>
          <Typography color="textSecondary" >Рекомендуем сфотографировать эту информацию, она может понадобиться Вам в дальнейшем.</Typography>
        </DialogContent>
        <DialogActions style={{justifyContent: "space-between"}}>
          <Typography variant="caption" style={{marginLeft: "2em"}}>Телефон для справок: 8 (800) 888-88-88</Typography>
          <Button onClick={onClose} color="primary">
            Понятно
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



export default withStyles(styles)(CreateOrderDialog)