import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import JSONPretty from 'react-json-prettify';
import {github} from 'react-json-prettify/dist/themes';


const styles = theme => ({
    root: {
        backgroundColor: github.background,
        padding:"0.125em 1.5em", 
        borderRadius: theme.shape.borderRadius
    },
});


const JsonField = ({ className, classes, record, source, ...props }) => {
    const data = get(record, source)
    const json = (typeof data === 'object') ? data : JSON.parse(data);

    return (
        <div className={classes.root}> 
            <JSONPretty json={json} theme={github} padding={4} />
        </div>
    );
     
   

} 


JsonField.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string, 
    record: PropTypes.object,
    source: PropTypes.string.isRequired,

};


const EnhancedJsonField = withStyles(styles)(JsonField);

EnhancedJsonField.defaultProps = {
    addLabel: true,
};

export default EnhancedJsonField;
