import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root:{
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
});

const WidgetGroup = withStyles(styles)(({ classes, title, children, ...props }) => {

    if (!children || children.length === 0) return null;

    return (
        <div className={classes.root}>
            { (!!title) && <Typography variant="body2" color="textSecondary" gutterBottom noWrap>{title}</Typography> }
            {children}
        </div>
    )

});


export default WidgetGroup;