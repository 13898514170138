import gql from 'graphql-tag'
import { apolloNotificationsClient } from './graphqlProvider';

const GET_LIST_NOTIFICATIONS = gql`
    query allNotifications($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: NotificationFilter) {
        items: allNotifications(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
            id 
            app 
            title 
            body 
            data 
            actions 
            tag 
            requireInteraction 
            type 
            priority 
            ttl 
            ttlAt 
            timestamp
        }
        total: _allNotificationsMeta(filter: $filter) {
            count
        }
    }
`;

export default (args) => {
    return apolloNotificationsClient.query({
        query: GET_LIST_NOTIFICATIONS,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};
