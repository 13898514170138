import { Ability } from '@casl/ability'
import { createContext } from 'react'
import { createContextualCan } from '@casl/react'


//export const ability = process.env.REACT_APP_MODE != 1 ? new Ability([]) : { can: function() { return true; } }

export const ability = new Ability([]);


export const defineAbilityFor = function (rules) {
  

  //throw new Error(`Error.${error.message.replace('Error: ', '').replace('GraphQL error: ', '')}`) 
  rules.filter(r => r.conditions).forEach(r => { for(let key in r.conditions) { if(r.conditions[key] === "undefined") { r.conditions[key] = undefined; }}});

  ability.update([]);
  ability.update(rules);
  
  return rules;
} 

export const AbilityContext = createContext()
export const Can = createContextualCan(AbilityContext.Consumer)




// export const defineAbilityFor = function (role) {
//   const { rules, can, /* cannot */ } = AbilityBuilder.extract()

//   // type Order {
//   //   id: ID! @unique
//   //   humanId: String! @unique
//   //   createdAt: DateTime!
//   //   updatedAt: DateTime!
//   //   completedAt: DateTime
//   //   statusRef: String  @default(value: "new")
//   //   resultRef: String
//   //   resultDesc: String
//   //   meterRef: String!
//   //   meterLocation: MeterLocation @default(value: "UNKNOWN")
//   //   meterUninstallAt: DateTime
//   //   comment: String
//   //   customerToUserRef: String!
//   //   verifierToUserRef: String
//   //   dispatcherToUserRef: String
//   //   regionId: String
//   //   districtIdOrCityId: String
//   //   cityId: String
//   //   streetId: String
//   //   houseNum: String
//   //   houseCorp: String
//   //   houseFlat: String
//   //   addrManual: String
//   //   documents: [Document]
//   //   isArchive: Boolean! @default(value: "false")
//   // }
  


//   switch(role) {
//     case 'SUPERADMIN':
//       can(['list', 'show', 'edit', 'create', 'delete', 'archive'], ['Order','Statistic', 'User', 'Meter', 'OrderHistory', 'File', 'Location'])
//     break;

//     case 'ADMIN':
//       can(['list', 'show', 'edit', 'create', 'delete', 'archive'], ['Order','Statistic', 'User', 'Meter'])
//       can(['show'], ['File'])
//     break;

//     case 'MANAGER':
//       can(['list', 'show', 'edit', 'create', 'archive'], ['Order', 'Statistic', 'User', 'Meter'])
//       can(['show'], ['File'])
//     break;

//     case 'DISPATCHER':  
//       can(['list', 'show', 'edit', 'create'], ['Order', 'User'])
//       can('list', 'Statistic')
//       can(['list', 'show'], ['Meter'])
//     break;

//     case 'VERIFIER':  
//       can('list', 'Order')
//       can('show', 'Order', [
//         'humanId',
//         'createdAt',
//         'updatedAt',
//         'completedAt',
//         'statusRef',
//         'resultRef',
//         'resultDesc',
//         'meterRef',
//         'meterLocation',
//         'meterUninstallAt',
//         'comment',
//         'customerToUserRef',
//         'verifierToUserRef',
//         'dispatcherToUserRef',
//         'addrManual',
//         'files',
//         'isArchive',
//         'completedDeadlineAt',
//         'invoice',
//         'isSealNotInstalled',
//       ])
//       can('edit', 'Order', [
//         'statusRef',
//         'resultRef',
//         'resultDesc',
//         'meterRef',
//         'meterLocation',
//         'meterUninstallAt',
//         'comment',
//         'verifierToUserRef',
//         'files',
//         'invoice',
//         'isSealNotInstalled',
//       ])
//       can('list', 'Statistic')
//       can('show', 'User')
//       can(['list', 'show'], 'Meter')
//       can(['list', 'show'], 'File')
//       can('show', 'Location')
//     break;

//     case 'EMPLOYEE':  
//       can(['list', 'show'], ['Order'])
//       can(['show'], ['User', 'Meter'])
//     break;

//     case 'CUSTOMER':  
//     can(['list', 'create'], ['Order'])
//     break;

//     case 'GUEST':
//     default:
//       can(['create'], ['Order'])
//     break;
// }

//   return rules
// }
