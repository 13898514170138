import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { reduxForm, Field } from 'redux-form';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import {translate} from 'react-admin';



const styles = theme => ({
    search: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        
        marginLeft: 8,
            width: '100%',

        [theme.breakpoints.up('sm')]: {
            marginLeft: "auto",
        width: 'auto',
        },
      },
      searchIcon: {
        marginLeft:0,
        width: theme.spacing.unit * 6,
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        paddingLeft: theme.spacing.unit * 2,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 192,
          '&:focus': {
            width: 256,
          },
        },
      },
});



const renderTextField = ({ input, translate, classes, ...props }) => (
  <Input
      placeholder={translate('ra.action.search')}
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      disableUnderline
      endAdornment={
        <InputAdornment position="end" className={classes.searchIcon}>
            <SearchIcon />
        </InputAdornment>
      }
      {...input}
      {...props}
  />
);

const TitleSearchField = ({ classes, translate, ...props }) => (
  <div className={classes.search}>
    <Field name="q" component={renderTextField} translate={translate} classes={classes}/>
  </div>
);



const enhance = compose(
  withStyles(styles),
  translate,
  reduxForm({
      form: 'filterForm',
      enableReinitialize: true,
      destroyOnUnmount: false,
      onChange: (values, dispatch, props) => props && props.setFilters(values), 
  })
);

const EnhancedTitleSearchField = enhance(TitleSearchField);

EnhancedTitleSearchField.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
};

export default EnhancedTitleSearchField;