import Icon from '@material-ui/icons/History';
import OrderHistoryList from './OrderHistoryList';
import OrderHistoryShow from './OrderHistoryShow';
import { OrderHistoryDatagridForUser, OrderHistoryDatagridForOrder } from './OrderHistoryList';

const OrderHistoryIcon = Icon;

export {
    OrderHistoryIcon,
    OrderHistoryList,
    OrderHistoryShow,
    OrderHistoryDatagridForUser,
    OrderHistoryDatagridForOrder,
};