import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, translate, DashboardMenuItem } from 'react-admin';
import { withRouter } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/ViewList';
import FiberNewIcon from '@material-ui/icons/FiberNew';
// import MenuGroup from './MenuGroup'
import {VERSION_APP} from '../../appsettings';


const styles = theme => ({
    root: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        borderRadius: theme.shape.borderRadius,
    },
    iconRight:{
        marginLeft: "auto",
    },
});

const Menu = ({ translate, resources, onMenuClick, classes,hasDashboard, logout }) => {

    const VERSION = 'version-app'
    const version = localStorage.getItem(VERSION)
    const newRelease = version !== VERSION_APP

    return (
        <div>
            {hasDashboard && <DashboardMenuItem classes={{root: classes.root}} onClick={onMenuClick} />}
            {resources.filter(resource => resource.hasList).map(resource => (
                <MenuItemLink
                    dense
                    classes={{root: classes.root}}
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText = {translate(`resources.${resource.name}.name`, { smart_count: 2 })}
                    leftIcon= { resource.icon ? <resource.icon /> : <DefaultIcon /> }
                    onClick={onMenuClick}
                />
            ))}
            <MenuItemLink
                dense
                classes={{root: classes.root}}
                to="/About"
                primaryText={newRelease ? (<>{translate(`resources.AboutApp.name`)}<FiberNewIcon color="error" className={classes.iconRight}/></>) : translate(`resources.AboutApp.name`)}
                leftIcon={<InfoIcon />}
                onClick={onMenuClick} />
        </div>
    )
};

const mapStateToProps = state => ({
    resources: getResources(state),
});

const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps,{}),
    translate,
)

export default enhance(Menu);