import React from 'react';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';


const RoleField = ({ record = {}, translate, ...props }) => ( 
    <span>{translate(`resources.User.roles.${record.role}`)}</span>
);

RoleField.defaultProps = {
    source: 'role',
    addLabel: true
};

export default translate(RoleField);




