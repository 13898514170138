import React from 'react';
import compose from 'recompose/compose';
import { Button, withDataProvider, showNotification, } from 'react-admin';
import FileDownloadIcon from '../Icons/FileDownload';
import getUrlArchive from '../../providers/Blob/archiveFiles';
import { GetAccessToken } from '../../providers/authProvider';
import { BLOB_SETHOST } from '../../appsettings';

const getUrlWithToken = (url, token) => {
    return url.indexOf('blob:') < 0 ? `${url}?${token}` : url;
};

const renameUrl = (url) => {
    if (BLOB_SETHOST && BLOB_SETHOST !== '') {
        const parsed_url = new URL(url);
        const new_url = url.replace(parsed_url.origin, BLOB_SETHOST);
        return new_url;
    }

    return url;
}


const DownloadFilesButton = ({ record, dataProvider, dispatch, ...props }) => {

    const handleDownloadFiles = async () => {

        let orders = [];

        orders.push({
            //id: record.id,
            humanId: record.humanId,
            addrManual: record.addrManual,
            idsBlob: record.files.map(file => (file.idBlob)),
        });

        orders = orders.filter(order => {
            if (order.idsBlob.length > 0) return true;
            dispatch(showNotification('raExt.notification.download_files_empty', 'info', { messageArgs: { smart_count: 1 } }));
            return false;
        });

        if (orders.length > 0) {
            /////////////////////// тут будет вызов клиента /////////////////

            var responce = await getUrlArchive(orders);
            const token = await GetAccessToken();
            const url = renameUrl(responce.data.getUrlArchiveFiles.url);
            const urlWithToken = getUrlWithToken(url, token)

            window.open(urlWithToken, '_blank');
        };

    };

    return (
        <Button label="raExt.action.download_files" onClick={handleDownloadFiles} {...props} >
            <FileDownloadIcon />
        </Button>
    );
}

export default compose(
    withDataProvider,
)(DownloadFilesButton);
