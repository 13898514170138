import React from 'react';
import { AutocompleteInput, AutocompleteArrayInput, Create, FormDataConsumer, ReferenceInput, ReferenceArrayInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, required, minLength, email, } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ability } from '../../ability'
import CheckBoxGroupInputWithCheckAll from '../Inputs/CheckBoxGroupInputWithCheckAll';
import { AddressSuggestionComponent, AddressCheckComponent } from '../Order/AddressComponents';
import TypeField from '../Customer/TypeField';
import PaymentField from '../Customer/PaymentField';
import PhoneInput from '../Inputs/PhoneInput';
import RoleField from './RoleField';
import { getCreateRoles } from './roles';

const arrTypes = [
    { type: 'PHIS' },
    { type: 'JURE' },
];

const arrPayments = [
    { payment: 'CASH' },
    { payment: 'CASHLESS' },
];

// class User {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }


const validateName = [required(), minLength(2)];
// const nameFormat = (value) => {
//     if (!value) return value
//     return value.replace(/\s+/g, " ").replace(/([.!?\s]+)(?=\S)/g, "$1 ")
// }
const validateEmail = [email()];

const styles = theme => ({
    mr2: { marginRight: ".5rem" },
});




const UserCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <TextInput source="name" /*format={nameFormat}*/ validate={validateName} />
                <PhoneInput source="phone" inputProps={{ autoComplete: "new-password" }} />
                <SelectInput source="role" choices={getCreateRoles()} optionValue="role" optionText={<RoleField />} validate={required()} />
                <FormDataConsumer style={{ display: "flex", flexDirection: "column" }}>{
                    ({ formData, ...rest }) => formData.role === "CUSTOMER" && [
                        <SelectInput key="type" source="customer.type" choices={arrTypes} optionValue="type" optionText={<TypeField />} validate={required()} {...rest} />,
                        <FormDataConsumer style={{ display: "flex", flexDirection: "column" }}>{
                            ({ formData }) => (!!formData.customer && (formData.customer.type === undefined || formData.customer.type === "JURE")) && [
                                <TextInput key="organizationName" source="customer.organizationName" {...rest} />,
                                <TextInput key="organizationInn" source="customer.organizationInn" {...rest} />,
                                <SelectInput key="payment" source="customer.payment" choices={arrPayments} optionValue="payment" optionText={<PaymentField />}  {...rest} />,
                            ]
                        }
                        </FormDataConsumer>,
                    ]
                }
                </FormDataConsumer>
                <TextInput source="email" type="email" validate={validateEmail} inputProps={{ autoComplete: "new-password" }} />
                <TextInput source="password" type="password" inputProps={{ autoComplete: "new-password" }} />
                {ability.can('create', 'User', 'counterparty') && <ReferenceInput source="counterparty.id" reference="Counterparty" sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ name: searchText })} label="resources.User.fields.counterparty" allowEmpty >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>}
                {ability.can("create", "User", "regionIds") && (
                    <ReferenceArrayInput source="regionIds" reference="Location" filter={{ type: 'REGION' }} options={{ fullWidth: true }}>
                        <AutocompleteArrayInput suggestionComponent={AddressSuggestionComponent} optionText={choice => `${choice.name} ${choice.typeShort}`} />
                    </ReferenceArrayInput>
                )}
                {ability.can("create", "User", "districtIdOrCityIds") && (
                    <FormDataConsumer>{
                        ({ formData, className, ...rest }) =>
                            <ReferenceArrayInput key={formData.regionIds} source="districtIdOrCityIds" reference="Location" filter={{ type: 'DISTRICT', parentIds: !!formData.regionIds ? formData.regionIds : [] }} perPage={1000} {...rest} >
                                <CheckBoxGroupInputWithCheckAll optionText={AddressCheckComponent} />
                            </ReferenceArrayInput>
                    }
                    </FormDataConsumer>
                )}

            </SimpleForm>
        </Create>
    )
};


const CreateToolbar = withStyles(styles)(({ classes, ...props }) => {
    const { permissions, record } = props;

    return (
        <Toolbar {...props} >
            { ability.can("create", { constructor: { name: "User" }, role: record.role }) && (
                <SaveButton className={`${classes.mr2}`} label="ra.action.create" redirect="list" submitOnEnter={true} />
            )}
            { ability.can("create", { constructor: { name: "User" }, role: record.role }) && (
                <SaveButton label="raExt.action.create_and_continue" redirect={false} submitOnEnter={false} variant="outlined" />
            )}
        </Toolbar>
    );
});

export default UserCreate;