import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import 'moment/locale/ru';

const VerifyIntervalField = ({ record, className, }) => {
    if (!record || !record.verifyInterval) return null;
    return (<Typography className={className} >{moment.duration(record.verifyInterval, 'years').humanize()}</Typography>);
}

VerifyIntervalField.defaultProps = {
    addLabel: true,
};

export default VerifyIntervalField;