import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import lightBlue from '@material-ui/core/colors/lightBlue';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { translate } from 'react-admin';
import CreatedIcon from '@material-ui/icons/NewReleases';
import UpdatedIcon from '@material-ui/icons/Save';
import DeletedIcon from '@material-ui/icons/Delete';
import OtherIcon from '@material-ui/icons/Create';
import ArchivedIcon from '@material-ui/icons/Archive';
import UnarchivedIcon from '@material-ui/icons/Unarchive';
import ProcessedIcon from '@material-ui/icons/HeadsetMic';
import InWorkIcon from '@material-ui/icons/Build';
import CompletedIcon from '@material-ui/icons/CheckCircle';
import CancelledIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@material-ui/icons/Sync';
import SyncErrIcon from '@material-ui/icons/SyncProblem';


const styles = theme => ({
    avatar:{
        color: "inherit",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    },
    CREATED:{
        backgroundColor:amber[500],
        color: theme.palette.getContrastText(amber[500])
    },
    UPDATED:{
        backgroundColor:lightBlue[500], 
        color: "#fff",
    },
    DELETED:{
        backgroundColor:red[500], 
        color: theme.palette.getContrastText(red[500])
    },
    ARCHIVED:{
        backgroundColor:red[500], 
        color: theme.palette.getContrastText(red[500])
    },
    UNARCHIVED:{
        backgroundColor:lightBlue[500], 
        color: "#fff",
    },
    PROCESSED:{
        backgroundColor:lightBlue[500], 
        color: theme.palette.getContrastText(lightBlue[500])
    },
    INWORK:{
        backgroundColor:blue[500], 
        color: theme.palette.getContrastText(blue[500])
    },
    COMPLETED:{
        backgroundColor:green[500], 
        color: theme.palette.getContrastText(green[500])
    },
    CANCELLED:{
        backgroundColor:red[500], 
        color: theme.palette.getContrastText(red[500])
    },
});

const getIcon = action => {
    switch (action) { 
        case "CREATED":{ return <CreatedIcon /> } break;
        case "UPDATED":{ return <UpdatedIcon /> } break;
        case "DELETED":{ return <DeletedIcon /> } break;
        case "ARCHIVED":{ return <ArchivedIcon /> } break;
        case "UNARCHIVED":{ return <UnarchivedIcon /> } break;
        case "PROCESSED":{ return <ProcessedIcon /> } break;
        case "INWORK":{ return <InWorkIcon /> } break;
        case "COMPLETED":{ return <CompletedIcon /> } break;
        case "CANCELLED":{ return <CancelledIcon /> } break;
        case "SYNCHRONIZED":{ return <SyncIcon /> } break;
        case "SYNCHRONIZEDERROR":{ return <SyncErrIcon /> } break;
        default:{ return <OtherIcon /> } break;
    }
}


const ActionChip = ({ className, classes, translate, record, }) => {
    if (!record) return null;
    
    return (
        <Chip avatar={<Avatar>{getIcon(record.action)}</Avatar>} label={translate(`resources.OrderHistory.actions.${record.action}`)} classes={{avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className}`} />
    );
}



const enhaenceActionChip = compose( withStyles(styles), translate, )(ActionChip)
enhaenceActionChip.defaultProps = {
    source: 'action',
    addLabel: true
};

export default enhaenceActionChip;