import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TitleSearchField from '../Inputs/TitleSearchInput';



const styles = theme => ({
  title:{
      display: "flex",
      alignItems: "center"
  },
});

const Title = ({ defaultTitle, setFilters, classes }) => {
  return (
    <div className={classes.title} >
      {defaultTitle}
      <TitleSearchField setFilters={setFilters}/>
    </div>
  );
};

Title.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
};


export default withStyles(styles)(Title);

