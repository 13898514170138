import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import amber from '@material-ui/core/colors/amber';
import lightBlue from '@material-ui/core/colors/lightBlue';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import NewIcon from '@material-ui/icons/NewReleases';
import ProcessedIcon from '@material-ui/icons/HeadsetMic';
import InWorkIcon from '@material-ui/icons/Build';
import CompletedIcon from '@material-ui/icons/CheckCircle';
import CancelledIcon from '@material-ui/icons/Cancel';



const styles = theme => ({
    avatar:{
        color: "inherit",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    },
    new:{
        backgroundColor:amber[500],
        color: theme.palette.getContrastText(amber[500]),
    },
    processed:{
        backgroundColor:lightBlue[500],
        color: theme.palette.getContrastText(lightBlue[500]),
    },
    inWork:{
        backgroundColor:blue[500],
        color: theme.palette.getContrastText(blue[500]),
    },
    completed:{
        backgroundColor:green[500],
        color: theme.palette.getContrastText(green[500]),
    },
    cancelled:{
        backgroundColor:red[500],
        color: theme.palette.getContrastText(red[500]),
    }
});


const getStatusIcon = status => {
    switch (status) {
        case "new":{
            return <NewIcon />
        }
        break;
        case "processed":{
            return <ProcessedIcon />
        }
        break;
        case "inWork":{
            return <InWorkIcon />
        }
        break;
        case "completed":{
            return <CompletedIcon />
        }
        break;

        case "cancelled":{
            return <CancelledIcon />
        }
        break;

        default:{
            return <newIcon />
        }
        break;

    }
}


const StatusChip = ({ className, classes, record, translate,}) => {
    if (!record) return null;
    
    return (
        <Chip avatar={<Avatar>{getStatusIcon(record.statusRef)}</Avatar>} label={translate(`resources.Order.statuses.${record.statusRef}`)} classes={{ avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className} ${classes[record.statusRef]}`} />
    );
}


export default compose( withStyles(styles), translate, )(StatusChip);



const stylesStatusIcon = theme => ({
    new:{ color:amber[500] },
    processed:{ color:lightBlue[500] },
    inWork:{ color:blue[500] },
    completed:{ color:green[500] },
    cancelled:{ color:red[500] }
});


export const StatusIcon = compose( withStyles(stylesStatusIcon), translate, )(({ className, classes, record, translate,}) => {
    if (!record) return null;
    
    return (
        <Tooltip title={translate(`resources.Order.statuses.${record.statusRef}`)} placement="top">
            {
                React.cloneElement(getStatusIcon(record.statusRef), {
                    className: classes[record.statusRef]
                })  
            }
        </Tooltip>
    );
});