import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Call';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import AdminIcon from '@material-ui/icons/Android';
import DispatcherIcon from '@material-ui/icons/HeadsetMic';
import VerifierIcon from '@material-ui/icons/Build';
import CustomerIcon from '@material-ui/icons/AccountCircle';

// const getRoleIcon = role => {
//     switch (role) {
//         case "SUPERADMIN":
//         case "ADMIN":
//         case "MANAGER":{
//             return <AdminIcon />
//         }
//         break;

//         case "DISPATCHER":{
//             return <DispatcherIcon />
//         }
//         break;

//         case "VERIFIER":{
//             return <VerifierIcon />
//         }
//         break;

//         case "EMPLOYEE":{
//             return <DispatcherIcon />
//         }
//         break;

//         case "CUSTOMER":{
//             return <CustomerIcon />
//         }
//         break;

//         default:{
//             return <CustomerIcon />
//         }
//         break;

//     }
// }

const styles = theme => ({
    highlight: {
        fontWeight: 600,
        color: "000"
    },
    root: {
        height: "unset",
        flexDirection: "column",
        alignItems: "start",
    },
    flex: {
        display: "flex",
    },
    PhoneIcon: {
        fontSize: "1rem",
        marginRight: "0.25rem",
        color: theme.palette.grey["500"]
    },
});


const UserSuggestionComponent = withStyles(styles)(({
    suggestion,
    query,
    isHighlighted,
    classes,
    ...props
}) => {
    const suggestionText = `${suggestion.name}`;
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);
    const {className, ...rest} = props;
 
    return(
        <div className={`${className} ${classes.root}`} {...rest}>
            <span>
                {parts.map((part, index) => {
                const className = part.highlight ? classes.highlight : null;

                return (<span className={className} key={index}>{part.text}</span>);
                })}
            </span>
            <div className={`${classes.flex}`}>
                <PhoneIcon className={`${classes.PhoneIcon}`}/>
                <Typography variant="caption" noWrap >{suggestion.phone}</Typography>
            </div>
            
            
        </div>
    )
});

export default UserSuggestionComponent;