import React from 'react';
import { List,Show,SimpleShowLayout, Datagrid, TextField, DateField, ReferenceField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { TokenFileField } from './tokenFileField';
import FileChip from '../Fields/FileChip'
import FileSizeField from './fileSizeField';
import OpenButton from './OpenButton';
import Icon from '@material-ui/icons/Folder';
import { exporter } from '../../exporter';
import { BLOB_NAME_APP } from '../../appsettings';
import UserReferenceField from '../User/UserReferenceField';
import Pagination from '../Layout/Pagination';

export const FileIcon = Icon;


const styles = theme => ({
    cellMinWidth:{
        width: '1px',
        padding: 0,
    }
});

const exportBuilder = (records, fetchRelatedRecords, translate) => { 
    exporter(records, 'File', [
        { field: "id" }, 
        { field: "catalog"},
        { field: "createdAt" , format: 'L'},
        { field: "createdBy"},
        { field: "desc"},
        { field: "ext"},  
        { field: "idBlob" },
        { field: "size" },
        { field: "url" },
        { field: "urlThumbnail" },
    ], translate) 
};


export const FileList = translate(withStyles(styles)(({ classes, translate, ...props }) => {
    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate) 
    }

    return (       
        <List 
            {...props} 
            exporter={exportBuilderWithTranslate}
            pagination={<Pagination />}
        >
            <Datagrid rowClick="show">
                <TextField source="catalog" />
                <TextField source="desc" />
                <FileSizeField source="size" />
                <UserReferenceField source="createdBy" sortable={false}/>
                <DateField source="createdAt" color="textSecondary" textAlign="right" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                <OpenButton source="url" label="" cellClassName={classes.cellMinWidth}/>
            </Datagrid>
        </List>
    )

}));

export const FileShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <FileChip canOpen source="url" title="desc"/>
            <TextField source="catalog" />
            <FileSizeField source="size" />
            <DateField source="created" showTime />
            <DateField source="createdAt" showTime />
            <ReferenceField linkType="show" source="createdBy" reference="User">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
        
    </Show>
);
