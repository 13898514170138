import buildGraphQLProvider from 'ra-data-graphql-simple';
import apolloFiasClient from './apolloFiasClient'
import decorate from './decorate';
import buildQuery from './builtQuery';





const buildCoreProvider = () => buildGraphQLProvider({
  client: apolloFiasClient,
  buildQuery: buildQuery,
});


export const knownResources = ['Location'];
export const dataProvider = (type, resource, params) => {
  return buildCoreProvider().then(async (provider) => {

    const { params: paramsD } = await decorate(type, resource, params);

    return provider(type, resource, paramsD)
  }
  )
};

export default {
  dataProvider,
  knownResources,
};