import React from 'react';
import get from 'lodash/get';
import { AUTH_TOKEN, BLOB_SETHOST } from '../../appsettings';
import { GetAccessToken } from '../../dataProviders/authProvider';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { translate } from 'ra-core';
import moment from 'moment'
import 'moment/locale/ru';
import notFoundImage from '../../files/documents_not_found.svg';
import emptyImage from '../../files/EmptyStateFiles.svg';
import docImage from '../../files/doc.svg';
import fileImage from '../../files/file.svg';
import jpgImage from '../../files/jpg.svg';
import pdfImage from '../../files/pdf.svg';
import pngImage from '../../files/png.svg';
import svgImage from '../../files/svg.svg';
import txtImage from '../../files/txt.svg';
import xlsImage from '../../files/xls.svg';
import zipImage from '../../files/zip.svg';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import ShowIcon from '@material-ui/icons/Visibility';
import EmptyState from './EmptyState';
import EditIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { ability } from '../../ability';

import { EditButton } from 'react-admin';


moment.locale('ru');

const styles = theme => ({
    emptyContainer: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    emptyContainerImage: {
        paddingTop: '1rem',

    },
    noResult: {
        paddingBottom: '1rem',
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    tileBar: {
        background: 'linear-gradient(to bottom, rgba(51,51,51,0.8) 4%, rgba(51,51,51,0.38) 47%, rgba(44,44,44,0.01) 85%)',
    },
    link: {
        color: '#fff',
    },
    GridList_root: {
        paddingTop: '1rem'
    },
    GridListTile_tile: {
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #EAEAEA',
        borderRadius: 6,
    },
    GridListTile_imgFullWidth: {
        top: 'unset',
        width: 'unset',
        position: 'relative',
        transform: 'unset',
    },
    placeholderImage: {
        cursor: 'pointer',
    },
});

const stopPropagation = e => e.stopPropagation();

const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    if (width === 'xl') return 6;
    return 7;
};

const getUrlWithToken = (url, token) => {
    return url.indexOf('blob:') < 0 ? `${url}?${token}` : url;
};

const getFileImage = (ext, url) => {

    switch (ext) {
        case 'doc':
        case 'docm':
        case 'docx':
            return docImage;
            break;

        case 'pdf':
            return pdfImage;
            break;

        case 'text':
        case 'txt':
            return txtImage;
            break;

        case 'xls':
        case 'xlsb':
        case 'xlsm':
        case 'xlsx':
            return xlsImage;
        case '7z':
        case 'rar':
        case 'zip':
        case 'zipx':
            return zipImage;
        case 'jpg':
        case 'jpeg':
        case 'png': {
            return getUrlWithToken(url, localStorage.getItem(AUTH_TOKEN));
        }
        case 'svg':
            return svgImage;
    }
    return fileImage;
};

const renameUrlImage = (url) => {
    if (BLOB_SETHOST && BLOB_SETHOST != '') {
        const parsed_url = new URL(url);
        const new_url = url.replace(parsed_url.origin, BLOB_SETHOST);
        return new_url;
    }

    return url;
}


const FilesGridList = ({ basePath, record, source, resourse, linkType, className, classes = {}, translate, width, addLabel, ...rest }) => {


    const files = get(record, source) || [];
    if (!files || files.length == 0) return <EmptyState imageUrl={emptyImage} title="resources.File.navigation.files_not_found" subtitle="resources.File.navigation.add_several_files" button={<EditButton to={`${basePath}/${record.id}/files`} variant="outlined" color="secondary" />} />




    //window.location.protocol
    return (
        <MuiGridList cellHeight={192} spacing={8} cols={getColsForWidth(width)} classes={{ root: (addLabel) ? "" : classes.GridList_root }}>{

            // createdAt: "2019-01-30T13:43:40.149Z"
            // desc: "{CB6F900A-F241-42CB-9D13-D455317055B5}.png.jpg"
            // ext: "png"
            // idDocument: "cjrj6xuoj00aa07256eij08y2"
            // url: "http://79.174.70.107:8125/Order/cjrj6xuoj00aa07256eij08y2"
            // userIdAdded: "cjrgafx7k000k0769f0topbir"

            files.map((file, index) => {

                let title = file.desc;
                let subtitle = file.created ? `Создан ${moment(file.created).format("L")}` : `Загружен ${moment(file.createdAt).format("L")}`
                let id = file.id;
                let ext = file.ext ? file.ext.replace('.', '') : '';
                let url = renameUrlImage(file.url);
                let urlThumbnail = renameUrlImage(file.urlThumbnail ? file.urlThumbnail : file.url);

                const openHandler = async () => {
                    window.open(getUrlWithToken(url, await GetAccessToken()), '_blank')
                };

                return (
                    <GridListTile key={index} onClick={openHandler} classes={{ tile: classes.GridListTile_tile, }}>

                        { (ext === 'png' || ext === 'jpg' || ext === 'jpeg') ? <img src={getFileImage(ext, urlThumbnail)} alt="" /> : <div><img src={getFileImage(ext, urlThumbnail)} alt="" /></div>}
                        <GridListTileBar
                            titlePosition='top'
                            className={classes.tileBar}
                            title={title}
                            subtitle={<span>{subtitle}</span>}
                            actionIcon={ability.can('Show', "File")
                                ? <IconButton
                                    onClick={stopPropagation}
                                    to={`/${resourse}/${id}/${linkType}`}
                                    className={classes.link}
                                    component={Link}
                                >
                                    <ShowIcon />
                                </IconButton>
                                : null
                            }
                        />
                    </GridListTile>
                )
            })
        }
        </MuiGridList>
    )
}

FilesGridList.defaultProps = {
    resourse: "File",
    linkType: "show"
};

const enhance = compose(
    withWidth(),
    withStyles(styles),
    translate
);

const EnhancedFilesGridList = enhance(FilesGridList);

EnhancedFilesGridList.defaultProps = {
    addLabel: true,
};

export default EnhancedFilesGridList;

