import React from 'react';
import { ReferenceField } from 'react-admin';

import UserField from './UserField';

const styles = {
    userField: {
        paddingTop:"0.25rem",
        paddingBottom:"0.25rem",

    },
};

const UserReferenceField = props => {
    const {record, source} = props;

    if (!record[source]) return null;
    return (
        <ReferenceField reference="User" sortBy="name" linkType="show" {...props}>
            <UserField style={styles.userField}/>
        </ReferenceField>
    )
};
UserReferenceField.defaultProps = {
    addLabel: true,
};

export default UserReferenceField;