import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { crudUpdate, startUndoable, SaveButton, translate, showNotification  } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import { SubmissionError } from 'redux-form';


const validate = (values, translate) => {
    const errors = {};
    
    if (!values.resultRef) errors.resultRef = translate('resources.Order.errors.resultRef');
    if (!values.resultDesc) errors.resultDesc = translate('resources.Order.errors.resultDesc');
    
  
    return Object.keys(errors).length !== 0 ? errors : undefined;
};

const saveWithCancel = (values, record, resource, basePath, redirectTo) =>
    crudUpdate(resource, record.id, { ...values, statusRef: "cancelled" }, record, basePath, redirectTo);

class SaveWithCancelButton extends Component {
    handleClick = () => {
        const { resource, record, basePath, handleSubmit, redirect, startUndoable, translate, showNotification } = this.props;

        return handleSubmit(values => {
            return new Promise((resolve, reject) => {
                const errors = validate(values, translate);
                if (errors) {
                    showNotification('ra.message.invalid_form', 'warning');
                    reject(new SubmissionError(errors));
                } else {
                    resolve(  
                        startUndoable(
                            saveWithCancel(values, record, resource, basePath, redirect)
                        )
                    );
                }
            });
        });
    };

    render() {
        const { handleSubmitWithRedirect, startUndoable, showNotification, translate, ...props } = this.props;


        if (this.props.disabled) {
            return ( 
            <Tooltip title={translate('resources.Order.helper.changeResult')} placement="top">
                <div>
                <SaveButton
                    handleSubmitWithRedirect={this.handleClick}
                    label="raExt.action.cancel"
                    icon={<CancelIcon />}
                    {...props}
                />
                </div>
            </Tooltip> );
        } else {
            return ( 
            <SaveButton
                    handleSubmitWithRedirect={this.handleClick}
                    label="raExt.action.cancel"
                    icon={<CancelIcon />}
                    {...props}
            /> );
        };
    }
}

const enhance = compose(
    connect( undefined, {
        startUndoable,
        showNotification,
    }),
    translate,
);


export default enhance(SaveWithCancelButton);