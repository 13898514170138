import React from 'react';
import Show from './Show';
import Versions from './Versions';
import {VERSION_APP} from '../../appsettings';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import MarkdownField from '../Fields/MarkdownField';
import CHANGELOG from '../../CHANGELOG.md'



const AboutAppShow = (props) => {

    const VERSION = 'version-app'
    localStorage.setItem(VERSION, VERSION_APP)
    
    return (
        <Show aside={<Versions />} title="resources.AboutApp.name" {...props}>
            <Card>
                <CardContent>
                    <MarkdownField path={CHANGELOG} />
                </CardContent>
            </Card> 
        </Show>
    );
};

export default AboutAppShow;