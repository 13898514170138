import React from 'react';
import { SimpleForm, DateInput, TextInput, FormDataConsumer, Edit, ReferenceArrayInput, AutocompleteArrayInput, required, email } from 'react-admin';
import EnumInput from '../Inputs/EnumInput';
import PhoneInput from '../Inputs/PhoneInput';
import GridField from '../Fields/GridField';



const validateEmail = [email()];


const CounterpartyEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" validate={required()} fullWidth />
                <TextInput source="description" multiline fullWidth />
                <TextInput source="inn" validate={required()} />
                <EnumInput source="type" Enum="CounterpartyType" validate={required()} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.type === "LEGALENTITY" || formData.type === "GOVERNMENTAGENCY") &&
                        <GridField>

                            <TextInput source="kpp" xs {...rest} />
                            <TextInput source="okpo" xs {...rest} />
                            <TextInput source="ogrn" xs {...rest} />
                        </GridField>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.type === "INDIVIDUAL" &&
                        <GridField>
                            <TextInput source="fio" xs={6} {...rest} />
                            <TextInput source="passport" xs {...rest} />
                            <DateInput source="passdateissue" xs  {...rest} />
                        </GridField>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.type === "SEPARATEDIVISION" &&
                        <>
                            <TextInput source="parentId" {...rest} />
                        </>
                    }
                </FormDataConsumer>
                <TextInput source="addressLegal" fullWidth />
                <TextInput source="addressActual" fullWidth />
                <TextInput source="addressPostal" fullWidth />
                <PhoneInput source="phone" />
                <PhoneInput source="phoneMobile" />
                <TextInput source="email" type="email" validate={validateEmail} />
                <ReferenceArrayInput source="usersIds" reference="User" filter={{ counterparty: null }} sort={{ field: 'name', order: 'ASC' }} label="resources.Counterparty.fields.users" fullWidth>
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export default CounterpartyEdit


