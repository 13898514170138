import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { union } from 'lodash/array';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { addField, translate, FieldTitle } from 'ra-core';

const sanitizeRestProps = ({
    setFilter,
    setPagination,
    setSort,
    alwaysOn,
    basePath,
    component,
    defaultValue,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    locale,
    meta,
    options,
    optionText,
    optionValue,
    record,
    resource,
    allowEmpty,
    source,
    textAlign,
    translate,
    translateChoice,
    ...rest
}) => rest;


const styles = theme => ({
    root: { 
        // display: "block",
        // flexWrap: "unset",
        // flexDirection: "unset",
    },
    label: {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
    },    
    controlLabel: {
        display: "flex",
    },
    checkbox: {
        height: 32,
    },
});


const getColsForWidth = width => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 1;
    if (width === 'md') return 2;
    if (width === 'lg') return 3;
    if (width === 'xl') return 4;
    return 1;
};

export class CheckboxGroupInput extends Component {
    handleCheckAll = (event, isChecked) => {
        const {
            choices,
            optionValue,
            optionDisabled,
            input: { value, onChange },
        } = this.props;
      
        if (isChecked) {
            onChange( union( (value || []), choices.map(choice => get(choice, optionValue)) ) );
        } else {
            typeof optionDisabled === 'function' ? onChange( union( [], choices.filter(choice => optionDisabled(choice, value)).map(choice =>  get(choice, optionValue)) ) ) : onChange([]);
        }
    };

    handleCheck = (event, isChecked) => {
        const {
            input: { value, onChange },
        } = this.props;
        let newValue;
        try {
            // try to convert string value to number, e.g. '123'
            newValue = JSON.parse(event.target.value);
        } catch (e) {
            // impossible to convert value, e.g. 'abc'
            newValue = event.target.value;
        }
        if (isChecked) {
            onChange([...(value || []), ...[newValue]]);
        } else {
            onChange(value.filter(v => v !== newValue));
        }
    };

    renderCheckAll = () => {
        const {
            source,
            choices,
            input: { value },
            options,
            translate,
            classes,
        } = this.props;

        return (
            <FormControlLabel
                className={classes.controlLabel}
                htmlFor={`${source}_checkAll`}
                key={`${source}_checkAll`}
                checked={ value ? value.length === choices.length : false }
                onChange={this.handleCheckAll}
                value={"all"}
                control={
                    <Checkbox
                        id={`${source}_checkAll`}
                        color="primary"
                        className={classes.checkbox}
                        {...options}
                    />
                }
                label={translate("raExt.action.select_all", { _: "Select_all" })}
            />
        );
    };

    renderCheckbox = choice => {
        const {
            id,
            optionDisabled,
            optionIndeterminate,
            input: { value, onChange },
            optionText,
            optionValue,
            options,
            translate,
            translateChoice,
            classes,
        } = this.props;
        const choiceName = React.isValidElement(optionText) // eslint-disable-line no-nested-ternary
            ? React.cloneElement(optionText, { record: choice })
            : typeof optionText === 'function'
            ? optionText(choice)
            : get(choice, optionText);

        const isIndeterminate = typeof optionIndeterminate === 'function' ? optionIndeterminate(choice, value) : false
        const isDisabled = typeof optionDisabled === 'function' ? optionDisabled(choice, value) : false

        return (
            <FormControlLabel
                className={classes.controlLabel}
                htmlFor={`${id}_${get(choice, optionValue)}`}
                key={get(choice, optionValue)}
                checked={
                    value
                        ? value.find(v => v === get(choice, optionValue)) !==
                          undefined
                        : false
                }
                onChange={this.handleCheck}
                value={String(get(choice, optionValue))}
                control={
                    <Checkbox
                        id={`${id}_${get(choice, optionValue)}`}
                        color="primary"
                        className={classes.checkbox}
                        disabled={isDisabled || isIndeterminate}
                        indeterminate={isIndeterminate} 
                        {...options}
                    />
                }
                label={
                    translateChoice
                        ? translate(choiceName, { _: choiceName })
                        : choiceName
                }
            />
        );
    };

    render() {
        const {
            choices,
            className,
            width,
            classes = {},
            isRequired,
            label,
            meta,
            resource,
            source,
            input,
            optionDisabled,
            optionIndeterminate,
            ...rest
        } = this.props;

        if (typeof meta === 'undefined') {
            throw new Error(
                "The CheckboxGroupInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
            );
        }

        const { touched, error, helperText = false } = meta;





        return (
            <FormControl
                className={className}
                component="fieldset"
                margin="normal"
                {...sanitizeRestProps(rest)}
            >
                <FormLabel component="legend" className={classes.label}>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </FormLabel>
                <FormGroup className={classes.root} style={{ height: choices.length * 32 / getColsForWidth(width) + 32}}>
                    {(!!choices && choices.length > 1) && this.renderCheckAll()}
                    {choices.map(this.renderCheckbox)}
                </FormGroup>
                {touched && error && (
                    <FormHelperText error>{error}</FormHelperText>
                )}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

CheckboxGroupInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
    id: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
    }),
    optionDisabled: PropTypes.func,
    optionIndeterminate: PropTypes.func,
    isRequired: PropTypes.bool,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]).isRequired,
    optionValue: PropTypes.string.isRequired,
    resource: PropTypes.string,
    translate: PropTypes.func.isRequired,
    translateChoice: PropTypes.bool.isRequired,
    meta: PropTypes.object,
};

CheckboxGroupInput.defaultProps = {
    choices: [],
    classes: {},
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
};

const EnhancedCheckboxGroupInput = compose(
    addField,
    translate,
    withWidth(),
    withStyles(styles)
)(CheckboxGroupInput);

EnhancedCheckboxGroupInput.defaultProps = {
    fullWidth: true,
};

export default EnhancedCheckboxGroupInput;
