import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import { translate } from 'ra-core';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
import 'moment/locale/ru';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { Link } from "react-admin";
import { removeNotification } from '../../redux/notifications/actions';
import { emphasize } from '@material-ui/core/styles/colorManipulator';


export const styles = theme => {
  const emphasis = theme.palette.type === 'light' ? 0.8 : 0.98;
  const backgroundColor = emphasize(theme.palette.background.default, emphasis);

  return {
    /* Styles applied to the root element. */
    root: {
       color: theme.palette.getContrastText(backgroundColor),
       backgroundColor,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '6px 16px',
      borderRadius: theme.shape.borderRadius,
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        flexGrow: 'initial',
        minWidth: 288,
      },
    },
    /* Styles applied to the message wrapper element. */
    message: {
      padding: '8px 0',
    },
    /* Styles applied to the action wrapper element if `action` is provided. */
    action: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      paddingLeft: 16,
      marginRight: -8,
    },

    card: {
        backgroundColor: "#ffc107",
        width: 356,
        
        
      },
      sizeSmall: {
        padding: 3,
        fontSize: theme.typography.pxToRem(18),
      },
      header:{
        //padding: "6px 24px",
      },
      content:{
        paddingTop: 0,
      },
      title: {
        display: 'flex',
        alignItems: 'center',
      }, 
      icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing.unit,
      },
      action: {
        marginTop: '-12px',
        marginBottom: '-12px',

      },
      
  };
};

// const SnackbarContent = ({ icon, title, timestamp, body, action, clear, classes, className, ...props}) => {

//     const handleClear = () => {
//         clear();
//     };


//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();

//     return (
//         <Card className={classes.card} elevation={6}>
//             <CardHeader
//                 classes={{root: classes.header, title: classes.title, action: classes.action }}
//                 title={
//                     (icon) 
//                     ? (<>{React.cloneElement(icon, { className: classes.icon, })}{title}</>) 
//                     : {title}
//                 }
//                 disableTypography
//                 title={
//                     <>
//                         { icon && React.cloneElement(icon, { className: classes.icon, }) }
//                         <Typography variant="subheading" noWrap>{title}</Typography>
//                         { timestamp && <Typography variant="caption" style={{marginLeft:"auto"}}>{timestamp}</Typography>}
//                     </>
//                 }
//                 action={
//                     <Tooltip title={translate("raExt.action.clear")} >
//                         <IconButton  aria-label="Clear" onClick={handleClear}>
//                             <ClearIcon />
//                         </IconButton>
//                     </Tooltip>
//                 }
                
                
//             />
//             { body &&
//             <CardContent classes={{root: classes.content}}>
//                 <Typography variant="body2" color="textSecondary" component="p">{body}</Typography>
//             </CardContent>
//             }
//             { action &&
//             <CardContent classes={{root: classes.content}}>
//                 <Typography variant="body2" color="textSecondary" component="p">{body}</Typography>
//             </CardContent>
//             }
//         </Card>
//     );
// };

// SnackbarContent.propTypes = {
//     icon: PropTypes.node,
//     title: PropTypes.string.isRequired,
//     body: PropTypes.string,
//     action: PropTypes.node,
//     classes: PropTypes.object.isRequired,
//     className: PropTypes.string,
// };



const SnackbarContent = ({notification, classes, icon, action, toolbar, translate, ...props}) => {

    return (
      <Card className={classes.card} style={{marginTop: 8}} {...props}>
        <CardHeader
          classes={{root: classes.header, content: classes.title, action: classes.action }}
          action={action}
          disableTypography
          title={<>
                  {icon && React.cloneElement(icon, { className: classes.icon })}
                  <Typography variant="subheading" noWrap>{notification.title}</Typography>
                  <Typography variant="caption" style={{marginLeft:"auto", paddingLeft: 8}}>{moment(notification.timestamp).fromNow()}</Typography>
                </>
            }
   
        />
        { notification.body &&
        <CardContent classes={{root: classes.content}}>
          <Typography variant="body2" color="textSecondary" component="p">{notification.body}</Typography>
        </CardContent>
        }
        { toolbar &&

          <CardActions classes={{root: classes.content}}>
            {toolbar}
          </CardActions>

        }


      </Card>
    );
  };

const EnhaencedSnackbarContent = compose( 
  withStyles(styles),
  translate,
)( SnackbarContent );

export default EnhaencedSnackbarContent;


export const NEWORDERSnackbarContentView = ({removeNotification, handleCloseClick, ...props}) => {
  const {notification} = props;
  

const handleClick = async (event) => {
  event.stopPropagation();       
  if (handleCloseClick) handleCloseClick();
  document.location.href = `#/Order/${notification.data.order.id}/show`;
};



  
    return (
    <EnhaencedSnackbarContent
        icon={<NewReleasesIcon />}
        toolbar={
          <Button size="small" onMouseUp={handleClick}>Открыть</Button>
      }
      {...props}
    />
  );
};


export const NEWORDERSnackbarContent = connect(
  undefined,
  { 
      removeNotification,
  },
)( NEWORDERSnackbarContentView );


