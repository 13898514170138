import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
    root: {
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036)",
        borderRadius: 8,
        flexGrow: 1,
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
    },
    content: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
});

const WidgetCard = ({className, classes, title, subtitle, children, ...props}) => {


    const renderSubtitle = subtitle => {
        return (typeof subtitle === "string")
        ? <Typography variant="body2" color="textSecondary">{subtitle}</Typography>
        : React.cloneElement(subtitle);
    }
        

    return (
        <Paper className={`${classes.root} ${className}`} >
            <CardContent className={`${classes.content}`}>
                {title && <Typography variant="headline" >{title}</Typography>}
                {subtitle && renderSubtitle(subtitle)}
                {children}
            </CardContent>
        </Paper>                              
    );
};


export default withStyles(styles)((WidgetCard));