export default (builtQuery, fetchType, resource, params) => {
    
    if (fetchType === "GET_MANY_REFERENCE") {
        return {
            ...builtQuery,
            variables: ((variables) => ({
                filter: variables.filter,
                page: params.pagination.page-1,
                perPage: params.pagination.perPage,
                sortField: params.sort.field,
                sortOrder: params.sort.order,
            }))(builtQuery.variables),    
        }
    }

    
    return builtQuery;
}