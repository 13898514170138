import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { translate } from 'react-admin';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import BannedIcon from '@material-ui/icons/Report';
import NoneIcon from '@material-ui/icons/Help';

const styles = theme => ({
    avatar:{
        color: "inherit",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    },
    ACTIVE: {
        backgroundColor: green['500'],
        color: theme.palette.getContrastText(green['500']),
    },
    BANNED: { 
        backgroundColor: red['500'],
        color: theme.palette.getContrastText(red['500']),
    },
    NONE: { 
        backgroundColor: amber['500'],
        color: theme.palette.getContrastText(amber['500']),
    },
});

const getAuthIcon = auth => {
    switch (auth) {
        case "ACTIVE":{
            return <ActiveIcon />
        }
        break;

        case "BANNED":{
            return <BannedIcon />
        }
        break;

        case "NONE":{
            return <NoneIcon />
        }
        break;

        default:{
            return <NoneIcon />
        }
        break;

    }
}

const AuthChip = ({ className, classes, translate, record, }) => {
    if (!record || !record.authStatus) return null;
    
    return (
        <Chip avatar={<Avatar>{getAuthIcon(record.authStatus)}</Avatar>} label={translate(`resources.User.authStatuses.${record.authStatus}`)} classes={{avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className} ${classes[record.authStatus]}`} />
    );
}


export default compose( withStyles(styles), translate, )(AuthChip);


