import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import gql from 'graphql-tag'
import apolloFiasClient from '../../dataProviders/Fias/apolloFiasClient';
import { AutocompleteInput, TextInput, } from 'react-admin';
import { AddressSuggestionComponent } from '../Order/AddressComponents'



const AutocompleteCityInput = ({ classes, form, change, ...props }) => {
    const [choices, setChoices] = useState([]);
    const [filter, setFilter] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [helperText, setHelperText] = useState(`Например: "Краснодар"`);

    useEffect(() => {
        async function fetchData() {
            try {
                const { data: { items, total } } = await apolloFiasClient.query({
                    query: gql`
                        query allLocations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: LocationFilter) {
                            items: allLocations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                                id
                                name
                                level
                                unrestrictedName
                                typeShort
                                typeMame
                                parentId
                                regionId
                                districtIdOrCityId
                                streetId
                                parents {
                                id
                                level
                                parentId
                                name
                                typeShort
                                }
                            }
                            total: _allLocationsMeta(page: $page, perPage: $perPage, filter: $filter) {
                                count
                            }
                        }
                    `,
                    variables: {
                        filter: { type: 'CITY', q: filter },
                        sortField: 'level',
                        sortOrder: 'ASC',
                        perPage: 300,
                    },
                    onError: (error) => {
                        alert(error)
                    }
                });

                setChoices(items);
            } catch (error) {
                console.log('error: ', error)
            }
        }
        fetchData();

        return () => {
            setChoices([]);
        };

    }, [filter])

    const handleChange = (e, value, prev, prop) => {
        const choice = choices.find(choice => choice.id === value)
        change(form, 'regionId', choice.regionId);
        change(form, 'districtId', choice.districtIdOrCityId);
        change(form, 'districtIdOrCityId', choice.districtIdOrCityId);
        change(form, 'addrManual', choice.unrestrictedName);
        setHelperText(choice.unrestrictedName.replace(`, ${choice.typeShort} ${choice.name}`, ''));
        setSelectedItem(choice);
    };

    return (
        <Fragment>
            <AutocompleteInput
                choices={selectedItem ? [selectedItem, ...choices] : choices}
                optionText={choice => (`${choice.typeShort} ${choice.name}`)}
                onChange={handleChange}
                suggestionComponent={AddressSuggestionComponent}
                shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                options={{ fullWidth: true, helperText: helperText }}
                setFilter={setFilter}
                {...props}
            />
            <TextInput source="addrManual" style={{ display: 'none' }} />
            <TextInput source="regionId" style={{ display: 'none' }} />
            <TextInput source="districtIdOrCityId" style={{ display: 'none' }} />
            <TextInput source="districtId" style={{ display: 'none' }} />
        </Fragment>
    );
};



const mapDispatchToProps = {
    change,
}



export default connect(null, mapDispatchToProps)(AutocompleteCityInput);