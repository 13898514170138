import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import { SnackbarProvider } from 'notistack';
import NotificationsStack from "../Notifications/NotificationsStack";



export const MyLayout = props => {
    return (
        <SnackbarProvider 
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <NotificationsStack />
            {/* <NotificationsDrawer /> */}
            <Layout {...props} appBar={AppBar} />
        </SnackbarProvider>

    ); 
};

