import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const rowStyle = (record, index, defaultStyle = {}) => {
    if (!record) return defaultStyle;
    if (record.isArchive)
        return {
            ...defaultStyle,
            borderLeftColor: grey[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
            backgroundColor:grey[200],
            '&:hover': {
                backgroundColor: grey[300],
            },
        };
    if (record.authStatus === "NONE")
        return {
            ...defaultStyle,
            borderLeftColor: amber[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
        };
    if (record.authStatus === "ACTIVE")
        return {
            ...defaultStyle,
            borderLeft: `6px solid ${green[500]}`,
        };
    if (record.authStatus === "BANNED")
        return {
            ...defaultStyle,
            borderLeft: `6px solid ${red[500]}`,
        };
    return defaultStyle;
};

export default rowStyle;