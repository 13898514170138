import React from 'react';
import {translate} from 'react-admin';

const PaymentField = ({ record = {}, translate, ...props }) => {
    return record.payment ? ( <span>{translate(`resources.Customer.payments.${record.payment}`)}</span> ) : null;
}

PaymentField.defaultProps = {
    addLabel: true
};

export default translate(PaymentField);




