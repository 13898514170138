import React, {useState, useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Tooltip from '@material-ui/core/Tooltip';
import { translate } from 'ra-core';


const FullScreenButton = ({ translate, ...props}) => {
    
    const [fullScreen, setFullScreen] = useState(false);
   
    const requestFullScreen = element => {
        const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;
        if (requestMethod) { // Native full screen.
            requestMethod.call(element);
        };
        setFullScreen(true);
        return false
    };

    const cancelFullScreen = element => {
        const requestMethod = element.cancelFullScreen || element.webkitCancelFullScreen || element.mozCancelFullScreen || element.exitFullscreen;
        if (requestMethod) { // Native full screen.
            requestMethod.call(element);
        };
        setFullScreen(false);
    };

    const handleFullScreen = () => {
        const element = document.documentElement;
       

        (fullScreen) ? cancelFullScreen(document) : requestFullScreen(element);
        return false;
    };


    return (
        <Tooltip title={(fullScreen) ? translate('raExt.action.exit_full_screen',{ _: 'Exit full screen' }) : translate('raExt.action.set_full_screen',{ _: 'Set full screen' }) } >
            <IconButton
                aria-label='full_screen'

                color="inherit"
                onClick={handleFullScreen}
            >
                {(fullScreen) ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
        </Tooltip>
    );
}

export default translate(FullScreenButton);