import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Chart from "react-apexcharts";
import ru from "apexcharts/dist/locales/ru.json";
import moment from 'moment'
import 'moment/locale/ru';
import { groupBy, uniq } from 'lodash';

import WidgetCard from "./WidgetCard";
import Loading from "./Loading";


import getOrderAnalytics from "../../dataProviders/Core/getOrderAnalytics";


const styles = theme => ({
    root: {
        flexGrow: 1,
        minWidth: 480,
        minHeight: 408,
    },
    container: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * -3
    },
    chip: {
        marginLeft: 8,
        zIndex: 1060,
        backgroundColor: "transparent",
        height: 24,
        opacity: 0.85,
    },
    chipActive: {
        backgroundColor: theme.palette.action.selected,
        opacity: 1,
    },
});

const DurationChips = ({ monthsDuration, setMonthsDuration, classes, className, ...props }) => {

    const [value, setValue] = useState(monthsDuration);

    const handleChange = param => e => {
        setValue(param)
        setMonthsDuration(param);
    };

    return (
        <div>
            <Chip onClick={handleChange(6)} label="6 месяцев" classes={{ root: classes.chip }} className={(value === 6) ? classes.chipActive : ""} />
            <Chip onClick={handleChange(9)} label="9 месяцев" classes={{ root: classes.chip }} className={(value === 9) ? classes.chipActive : ""} />
            <Chip onClick={handleChange(12)} label="12 месяцев" classes={{ root: classes.chip }} className={(value === 12) ? classes.chipActive : ""} />
        </div>
    );
};



const WidgetAnalitics = withStyles(styles)(({ classes, className, ...props }) => {

    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [monthsDuration, setMonthsDuration] = useState(9);

    useEffect(() => {

        async function fetchData() {

            const to = moment().endOf('month');
            const from = moment().subtract(monthsDuration, 'months').endOf('month');

            getOrderAnalytics({ from, to }).then(({ data: { OrdersAnalytics: { createdOrders = [], completedOrders = [], deadlinedOrders = [] } } }) => {

                const createdByMonths = groupBy(createdOrders, order => moment(order.createdAt).startOf('month'));
                const completedByMonths = groupBy(completedOrders, order => moment(order.completedAt).startOf('month'));
                const deadlinedByMonths = groupBy(deadlinedOrders, order => moment(order.completedDeadlineAt).startOf('month'));
                const monthsArray = uniq([...Object.keys(createdByMonths), ...Object.keys(completedByMonths), ...Object.keys(deadlinedByMonths)]);

                const options = {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        },
                        parentHeightOffset: 0,
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        locales: [ru],
                        defaultLocale: 'ru',
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '64%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        type: "datetime",
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,

                        },
                        labels: {
                            format: 'MMMM',
                            showDuplicates: false,
                        },
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        x: {
                            format: 'MMMM yyyy',
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: -5,
                    }
                };

                const series = [{
                    name: 'Создано',
                    data: monthsArray.map(month => [month, createdByMonths[month] ? createdByMonths[month].length : 0])
                }, {
                    name: 'Завершено',
                    data: monthsArray.map(month => [month, completedByMonths[month] ? completedByMonths[month].length : 0])
                }, {
                    name: 'Просрочено',
                    data: monthsArray.map(month => [month, deadlinedByMonths[month] ? deadlinedByMonths[month].length : 0])
                }];

                setOptions(options);
                setSeries(series);
                setLoading(false);
            });
        };


        fetchData();


        return () => {
            // initialNotifications([])
        };

    }, [monthsDuration]);




    return (
        <WidgetCard title="Заявки" subtitle={`последние ${monthsDuration} месяцев`} className={`${classes.root} ${className}`} >
            {/* <WidgetCard title="Заявки" subtitle={<DurationChips classes={classes} monthsDuration={monthsDuration} setMonthsDuration={setMonthsDuration} />} className={`${classes.root} ${className}`} > */}
            {(loading)
                ? <Loading />
                : <div className={classes.container} >
                    <Chart options={options} series={series} type="bar" height="100%" />
                </div>

            }

        </WidgetCard>

    )

});


export default WidgetAnalitics;


