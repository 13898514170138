import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import { addField, translate, FieldTitle } from 'ra-core';



const CheckBoxConditionsInput = ( { checked, invert, children, onChange, translate, label,  ...props}) => {
   
    const [isChecked, setChecked] = useState(checked);
    useEffect(() => {
        setChecked(checked)
      }, [checked]);
  
    if (React.Children.count(children) !== 1) {
        throw new Error('<CheckBoxConditionsInput> only accepts a single child');
    }

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={(e) => {
                            setChecked(e.target.checked)
                            if (onChange) onChange(e);
                        }}
                        value={"check"}
                        color="primary"
                    />
                }
                label={ translate(label, { _: label })}
            />
            { (!!(isChecked^invert)) && React.cloneElement(children, { ...props }) }

        </div>
        
    );
}

CheckBoxConditionsInput.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    source: PropTypes.string,
    translate: PropTypes.func.isRequired,
    onChange: PropTypes.func,
};


CheckBoxConditionsInput.defaultProps = {
    checked: false,
    invert: false,
    label: 'conditions is done?',
};

export default compose( translate )(CheckBoxConditionsInput);