// load .env
require('dotenv').config();

const global_env = (process.env.NODE_ENV === 'development') ? process.env : window.ENV;

export const AUTH_TOKEN = 'auth-token'
export const AUTH_REFRESH_TOKEN = 'auth-refresh-token'

// App names
export const VERSION_APP = "1.0.9-sp42"
export const NAME_APP = global_env.REACT_APP_NAME_APP || 'POVERKA'
export const BLOB_NAME_APP = global_env.REACT_APP_BLOB_NAME_APP || 'POVERKA'

export const BLOB_SETHOST = global_env.REACT_APP_BLOB_SETHOST || 'blob.поверка.su/get';

// SERVICE URI's
//export const URI_AUTH = 'http://195.209.35.28:4777'
//export const URI_DATA = 'http://195.209.35.28:4000'
//export const URI_DATA = 'http://195.209.35.28:4000'
export const URI_AUTH = global_env.REACT_APP_URI_AUTH || 'http://localhost:4777'

export const URI_FIAS = global_env.REACT_APP_URI_FIAS || 'http://localhost:4888'

export const URI_CASL = global_env.REACT_APP_URI_CASL || 'http://localhost:4555'

export const URI_BLOB = global_env.REACT_APP_URI_BLOB || 'http://localhost:4999'

export const URI_DATA = global_env.REACT_APP_URI_CORE || 'http://localhost:4000'
export const WS_DATA = global_env.REACT_APP_WS_CORE || 'ws://localhost:4000'

export const URI_NOTIFICATIONS = global_env.REACT_APP_URI_NOTIFICATIONS || 'http://localhost:3330'
export const WS_NOTIFICATIONS = global_env.REACT_APP_WS_NOTIFICATIONS || 'ws://localhost:3330'

export const URI_FGIS = global_env.REACT_APP_URI_FGIS || 'http://localhost:4855'
export const URI_ORDERS_TO_XML = global_env.REACT_APP_URI_ORDERS_TO_XML || 'http://localhost:8363'
