import gql from 'graphql-tag'

export const SUBSCRIBE_ONLINE_USERS = gql`
    subscription onlineUsers{
        onlineUsers {
            node {            
                id
                authId
                online
            }
        }
    }
    
`