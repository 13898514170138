import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { translate, CreateButton, List, ListView, ListController, Responsive, BulkDeleteButton, Filter, AutocompleteInput, DateField, DateInput, Datagrid, ReferenceField, ReferenceInput, SelectInput, CardActions, ExportButton, RefreshButton, TextField } from 'react-admin';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import red from '@material-ui/core/colors/red';
import moment from 'moment'
import { ability } from '../../ability';
import { exporter } from '../../exporter';
import LabelsField from '../Fields/LabelsField';
import FileAttachedField from '../Fields/FileAttachedField';
import { DeadlineIcon } from '../Fields/DeadlineField';
import LinkField from '../Fields/linkField';
import { MeterWithLocationField } from '../Meter/MeterField';

import UserField from '../User/UserField';
import { OptionMeter } from '../Meter/MeterField';
import MoreButton from '../Buttons/MoreButton';
import BulkDownloadFilesButton from '../Buttons/BulkDownloadFilesButton';
import BulkArchiveButton from '../Buttons/BulkArchiveButton';
import BulkFavoriteButton from '../Buttons/BulkFavoriteButton';
import OrdersToXmlBulkActionsButton from './OrdersToXmlBulkActionsButton';

import BulkExportButton from '../Buttons/BulkExportButton';
import UserSuggestionComponent from '../User/UserSuggestionComponent';
import ResultField from './ResultField';
import AddressField from './AddressField';
import FavoriteButton from '../Buttons/FavoriteButton';
import Pagination from '../Layout/Pagination';
import TitleWithSearch from '../Layout/TitleWithSearch';


import { AddressSuggestionComponent } from './AddressComponents';
import rowStyle from './rowStyle';

const styles = theme => ({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 6,
        borderLeftStyle: 'solid',
    },
    ml2: { marginLeft: ".5rem" },
    deleteButton: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    cellMinWidth: {
        width: '1px',
        padding: 0,
    },
    favoriteIcon: {
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    unfavoriteIcon: {
        color: theme.palette.divider,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    }
});
const exportBuilder = (records, fetchRelatedRecords, translate, filterValues) => {

    exporter(records, 'Order', [
        { field: "humanId" },
        { field: "createdAt", format: 'L' },
        { field: "completedAt", format: 'L' },
        { field: "completedDeadlineAt", format: 'L' },
        { field: "addrManual" },
        { field: "customer.name" },
        { field: "customer.phone" },
        { field: "verifier.name" },
        { field: "verifier.phone" },
        { field: "dispatcher.name" },
        { field: "dispatcher.phone" },
        { field: "meter.type", translateValues: "resources.Meter.enums.type" },
        { field: "meter.name" },
        { field: "meterLocation", translateValues: "resources.Order.enums.meterLocation" },
        { field: "meterUninstallAt", format: 'L' },
        { field: "statusRef", translateValues: "resources.Order.statuses" },
        { field: "resultRef", translateValues: "resources.Order.results" },
        { field: "resultDesc" },
        { field: "personalAccount" },
        { field: "invoice" },
        { field: "isSealNotInstalled", translateValues: "ra.boolean" },
        { field: "comment" },
    ], translate, filterValues)
};

const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

const customerTypes = [
    { type: 'PHIS', name: 'Физическое лицо' },
    { type: 'JURE', name: 'Юридическое лицо' },
];


const OrderFilter = (props) => {
    const regionIds = localStorage.getItem('regionIds').split(',');
    const districtIdOrCityIds = localStorage.getItem('districtIdOrCityIds').split(',');
    return (
        <Filter {...props}>
            {/* <SearchInput source="q" alwaysOn /> */}
            <ReferenceInput source="statusRef" reference="Status" resettable alwaysOn >
                <SelectInput optionText="description" />
            </ReferenceInput>
            <ReferenceInput source="regionId" reference="Location" filter={{ type: 'REGION', ids: regionIds }} allowEmpty>
                <AutocompleteInput suggestionComponent={AddressSuggestionComponent} optionText={record => (`${record.typeShort}. ${record.name}`)} />
            </ReferenceInput>
            <ReferenceInput source="districtIdOrCityId" reference="Location" filter={{ type: 'DISTRICT', ids: districtIdOrCityIds }} allowEmpty>
                <AutocompleteInput suggestionComponent={AddressSuggestionComponent} optionText={record => (`${record.typeShort}. ${record.name}`)} />
            </ReferenceInput>
            <ReferenceInput source="districtId" reference="Location" filter={{ type: 'DISTRICT', ids: districtIdOrCityIds }} allowEmpty >
                <AutocompleteInput suggestionComponent={AddressSuggestionComponent} optionText={record => (`${record.typeShort}. ${record.name}`)} />
            </ReferenceInput>
            <ReferenceInput source="creator" reference="User" sort={{ field: 'name', order: 'ASC' }} >
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" inputValueMatcher={() => null} />
            </ReferenceInput>
            <ReferenceInput source="dispatcher" reference="User" filter={{ role: "DISPATCHER" }} sort={{ field: 'name', order: 'ASC' }} >
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" inputValueMatcher={() => null} />
            </ReferenceInput>
            <ReferenceInput source="verifier" reference="User" filter={{ role: "VERIFIER" }} sort={{ field: 'name', order: 'ASC' }} >
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" inputValueMatcher={() => null} />
            </ReferenceInput>
            <ReferenceInput source="customer" reference="User" filter={{ role: "CUSTOMER" }} sort={{ field: 'name', order: 'ASC' }} >
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" inputValueMatcher={() => null} />
            </ReferenceInput>
            <SelectInput source="customerType" choices={customerTypes} optionText="name" optionValue="type" />
            <ReferenceInput source="oneOfUser" reference="User" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="resultRef" reference="Result" resettable >
                <SelectInput optionText="description" />
            </ReferenceInput>
            <DateInput source="createdAt_gte" defaultValue={moment().startOf('year').format()} />
            <DateInput source="createdAt_lte" defaultValue={moment().endOf('year').format()} />
            <DateInput source="completedAt_gte" />
            <DateInput source="completedAt_lte" />
            <DateInput source="completedDeadlineAt_gte" />
            <DateInput source="completedDeadlineAt_lte" />
            <ReferenceInput source="meter" reference="Meter" >
                <SelectInput optionText={<OptionMeter />} />
            </ReferenceInput>
        </Filter>
    );

};

const OrderActions = ({
    bulkActions,
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    total,
    ...rest
}) => (
    <CardActions >
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
            <ExportButton
                disabled={total === 0}
                maxResults={5000}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
            />
        )}
    </CardActions>
);

const OrderTabs = translate(({ filterValues, setFilters, translate, ...props }) => {
    const handleChange = (event, value) => {
        setFilters({ ...filterValues, tab: value });
    };

    return (
        <Fragment>
            <Tabs value={filterValues.tab} onChange={handleChange} centered >
                {/* РАБОТАЕТ ТАМ И ТАМ */}
                {ability.can("List", { constructor: { name: "Order" }, tab: "favorite" }) && <Tab label={translate('resources.Order.tabs.favorite')} value="favorite" />}
                {ability.can("List", { constructor: { name: "Order" }, tab: "current" }) && <Tab label={translate('resources.Order.tabs.current')} value="current" />}
                {ability.can("List", { constructor: { name: "Order" }, tab: "cancelled" }) && <Tab label={translate('resources.Order.tabs.cancelled')} value="cancelled" />}
                {ability.can("List", { constructor: { name: "Order" }, tab: "completed" }) && <Tab label={translate('resources.Order.tabs.completed')} value="completed" />}
                {ability.can("List", { constructor: { name: "Order" }, tab: "archived" }) && <Tab label={translate('resources.Order.tabs.archived')} value="archived" />}

                {/* НЕ РАБОТАЕТ В ПРОДКШЕНЕ!!! 
            { ability.can("List", new Order({tab:"favorite"})) && <Tab label={translate('resources.Order.tabs.favorite')} value="favorite" /> } 
            { ability.can("List", new Order({tab:"current"})) && <Tab label={translate('resources.Order.tabs.current')} value="current" /> }
            { ability.can("List", new Order({tab:"cancelled"})) && <Tab label={translate('resources.Order.tabs.cancelled')} value="cancelled" /> }
            { ability.can("List", new Order({tab:"completed"})) && <Tab label={translate('resources.Order.tabs.completed')} value="completed" /> }
            { ability.can("List", new Order({tab:"archived"})) && <Tab label={translate('resources.Order.tabs.archived')} value="archived" /> }  */}
            </Tabs>
            <Divider />
            <Responsive
                small={
                    <Fragment>
                        {filterValues.tab === 'favorite' && <DatadridSimple {...props} />}
                        {filterValues.tab === 'current' && <DatadridSimple {...props} />}
                        {filterValues.tab === 'cancelled' && <DatadridSimple2 {...props} />}
                        {filterValues.tab === 'completed' && <DatadridSimple2 {...props} />}
                        {filterValues.tab === 'archived' && <DatadridSimple2 {...props} />}
                    </Fragment>

                }
            />
        </Fragment>
    )
});

const DatadridSimple = withStyles(styles)(({ classes, ...props }) => (
    <Datagrid rowClick="show" rowStyle={rowStyle} classes={{ headerRow: classes.headerRow }} {...props}>
        <FavoriteButton classes={{ favoriteIcon: classes.favoriteIcon, unfavoriteIcon: classes.unfavoriteIcon }} cellClassName={classes.cellMinWidth} />
        <ReferenceField source="id" reference="Order" label="resources.Order.fields.humanId" linkType="show">
            <TextField source="humanId" />
        </ReferenceField>
        <LinkField source="dispatcher" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <LinkField source="customer" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <LinkField source="verifier" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <AddressField source="addrManual" />
        <LinkField source="meter" reference="Meter" linkType={false} sortable={false} >
            <MeterWithLocationField />
        </LinkField>
        <TextField source="comment" />
        <DateField source="createdAt" textAlign="right" color="textSecondary" showTime />
        <DateField source="completedDeadlineAt" textAlign="right" color="textSecondary" />
        <LabelsField cellClassName={classes.cellMinWidth}>
            <DeadlineIcon />
            <FileAttachedField />
        </LabelsField>
        <MoreButton />
    </Datagrid>
));

const DatadridSimple2 = withStyles(styles)(({ classes, ...props }) => (
    <Datagrid rowClick="show" rowStyle={rowStyle} classes={{ headerRow: classes.headerRow }} {...props}>
        <FavoriteButton classes={{ favoriteIcon: classes.favoriteIcon, unfavoriteIcon: classes.unfavoriteIcon }} cellClassName={classes.cellMinWidth} />
        <ReferenceField source="id" reference="Order" label="resources.Order.fields.humanId" linkType="show">
            <TextField source="humanId" />
        </ReferenceField>
        <LinkField source="dispatcher" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <LinkField source="customer" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <LinkField source="verifier" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <AddressField source="addrManual" />
        <LinkField source="meter" reference="Meter" linkType={false} sortable={false} >
            <MeterWithLocationField />
        </LinkField>
        <TextField source="comment" />
        <ResultField source="resultRef" />
        <DateField source="createdAt" textAlign="right" color="textSecondary" />
        <DateField source="completedAt" textAlign="right" color="textSecondary" />
        <LabelsField cellClassName={classes.cellMinWidth}>
            <DeadlineIcon />
            <FileAttachedField />
        </LabelsField>
        <MoreButton />
    </Datagrid>
));

export const DatagridUser = withStyles(styles)(({ classes, ...props }) => (
    <Datagrid rowClick="show" rowStyle={rowStyle} classes={{ headerRow: classes.headerRow }} {...props}>
        <TextField source="humanId" />
        <LinkField source="dispatcher" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <LinkField source="customer" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <LinkField source="verifier" reference="User" linkType={false} sortable={false} >
            <UserField />
        </LinkField>
        <AddressField source="addrManual" />
        <LinkField source="meter" reference="Meter" linkType={false} sortable={false} >
            <MeterWithLocationField />
        </LinkField>
        <TextField source="comment" />
        <DateField source="createdAt" textAlign="right" color="textSecondary" showTime />
        <DateField source="completedAt" textAlign="right" color="textSecondary" showTime />
    </Datagrid>
));


const BulkActionToolbarView = ({ classes, translate, ...props }) => {

    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate,)
    }

    return (
        <Fragment>
            <BulkFavoriteButton {...props} />
            <BulkDownloadFilesButton variant="outlined" className={`${classes.ml2}`} {...props} />
            <BulkExportButton variant="outlined" exporter={exportBuilderWithTranslate} className={`${classes.ml2}`} {...props} />
            { ability.can('exportfgis', 'Order') && <OrdersToXmlBulkActionsButton variant="outlined" className={`${classes.ml2}`} {...props} />}
            { ability.can('archive', 'Order') && <BulkArchiveButton className={`${classes.ml2}`} {...props} />}
            { ability.can('delete', 'Order') && <BulkDeleteButton className={`${classes.deleteButton} ${classes.ml2}`} {...props} />}

        </Fragment>
    );
};

export const BulkActionToolbar = compose(withStyles(styles), translate)(BulkActionToolbarView);

const getDefaultTab = () => {
    if (ability.can("List", { constructor: { name: "Order" }, tab: "current" })) return "current";
    if (ability.can("List", { constructor: { name: "Order" }, tab: "cancelled" })) return "cancelled";
    if (ability.can("List", { constructor: { name: "Order" }, tab: "completed" })) return "completed";
    return "current";
}

const OrderListView = ({ translate, filterValues, dispatch, ...props }) => {

    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate, filterValues)
    }

    return (
        <ListController sort={{ field: 'createdAt', order: 'ASC' }} filterDefaultValues={{ tab: getDefaultTab() }} {...props}>
            {controllerProps => (
                <ListView
                    actions={<OrderActions />}
                    bulkActionButtons={<BulkActionToolbar />}
                    exporter={exportBuilderWithTranslate}
                    filters={<OrderFilter />}
                    pagination={<Pagination />}
                    title={<TitleWithSearch {...controllerProps} />}
                    {...props}
                    {...controllerProps}
                >
                    <OrderTabs />
                </ListView>
            )
            }
        </ListController>
    );


    // return (
    //     <List {...props}
    //         bulkActionButtons={<BulkActionToolbar />}
    //         sort={{field: 'createdAt', order: 'ASC'}}
    //         filters={<OrderFilter />}
    //         filterDefaultValues={{ tab: getDefaultTab()}}
    //         exporter={exportBuilderWithTranslate}
    //     >
    //         <OrderTabs />   
    //     </List>
    // );
};



const mapStateToProps = (state) => ({ filterValues: (!state.form.filterForm) ? undefined : state.form.filterForm.values });

const enhance = compose(
    translate,
    connect(mapStateToProps)
);


const OrderList = enhance(OrderListView);



export default OrderList;