import Icon from '@material-ui/icons/Work';
import CounterpartyList from './CounterpartyList';
import CounterpartyCreate from './CounterpartyCreate';
import CounterpartyEdit from './CounterpartyEdit';
import CounterpartyShow from './CounterpartyShow';

const CounterpartyIcon = Icon;

export {
    CounterpartyIcon,
    CounterpartyList,
    CounterpartyShow,
    CounterpartyCreate,
    CounterpartyEdit,
};