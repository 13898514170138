import gql from 'graphql-tag'
import { apolloNotificationsClient } from './graphqlProvider';

const CLEAR_ALL_NOTIFICATIONS = gql`
    mutation disconnectAllNotifications {
        data: disconnectAllNotifications {
            count
        }
    }
`;


export default (args) => {
    return apolloNotificationsClient.mutate({
        mutation: CLEAR_ALL_NOTIFICATIONS,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    });
};
