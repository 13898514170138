
import { ability } from '../../ability';


const arrRoles = [
    { role: 'ADMIN' },
    { role: 'MANAGER' },
    { role: 'SENIORDISPATCHER' },
    { role: 'DISPATCHER' },
    { role: 'VERIFIER' },
    { role: 'INSPECTOR' },
    { role: 'SUPERVISOR' },
    { role: 'CUSTOMER' },
    { role: 'DEMO' }
];


// SUPERADMIN
// ADMIN
// MANAGER
// SENIORDISPATCHER
// DISPATCHER
// SENIORVERIFIER
// VERIFIER
// INSPECTOR
// CUSTOMER
// GUEST
// DEMO
// class User {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }


export const getListRoles = () => arrRoles.filter(role => ability.can("read", { constructor: { name: "User" }, ...role }));

export const getCreateRoles = () => arrRoles.filter(role => ability.can("create", { constructor: { name: "User" }, ...role }));

export const getEditRoles = () => arrRoles.filter(role => ability.can("update", { constructor: { name: "User" }, ...role }));