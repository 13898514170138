import React, { useEffect, useState } from 'react';
import { Admin, Resource /* Loading */ } from 'react-admin';
import { authProvider } from './dataProviders/authProvider';
import { dataProviderFactory } from './dataProviders/dataProviderFactory';
import i18nProvider from './i18nProvider';
import sagas from './sagas';
import user from './redux/user/reducer';
import onlineUsers from './redux/onlineUsers/reducer';
import notifications from './redux/notifications/reducer';
import customRoutes from './customRoutes';
import Menu from './components/Layout/Menu';
import { ability, AbilityContext } from './ability'
import CircularProgress from '@material-ui/core/CircularProgress';
import { MyLayout } from './components/Layout';
import MyLoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import { OrderList, OrderShow, OrderEdit, OrderIcon, OrderCreate } from './components/Order';
import { CounterpartyIcon, CounterpartyList, CounterpartyShow, CounterpartyEdit, CounterpartyCreate } from './components/Counterparty';
import { UserList, UserShow, UserEdit, UserIcon, UserCreate } from './components/User';
import { MeterList, MeterShow, MeterEdit, MeterIcon, MeterCreate } from './components/Meter';
import { OrderHistoryList, OrderHistoryShow, OrderHistoryIcon } from './components/OrderHistory';
import { StatisticList, StatisticIcon } from './components/Statistic';
import { LocationList, LocationShow, LocationIcon } from './components/Fias';
import { FileList, FileShow, FileIcon } from './components/File';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        //type: "dark",
        secondary: {
            light: '#6ec6ff',
            main: '#2196f3',
            dark: '#0069c0',
            contrastText: '#fff',
        },
    },
    typography: {
        title: {
            fontWeight: 400,
        },
    },
});
const App = () => {

    const [dataProvider, setDataProvider] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const onMount = () => {
            dataProviderFactory().then(dataProvider => {
                setDataProvider(() => dataProvider);
                setLoading(false);
            });
        }

        onMount();

        return () => {
            setDataProvider(null);
        };

    }, []);

    if (loading) {
        return (
            <div style={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ textAlign: 'center', fontFamily: 'Roboto, sans-serif', margin: '0 1em' }}>
                    <CircularProgress color="primary" />
                    <h1>Загрузка</h1>
                    <div >Страница загружается</div>
                </div>
            </div>
            /*  <Loading loadingPrimary='Загрузка' loadingSecondary ='Страница загружается' /> */
        );
    }


    return (
        <AbilityContext.Provider value={ability}>
            <Admin
                theme={theme}
                locale="ru"
                menu={Menu}
                dashboard={Dashboard}
                customRoutes={customRoutes}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider}
                authProvider={authProvider}
                loginPage={MyLoginPage}
                appLayout={MyLayout}
                customSagas={sagas}
                customReducers={{ user, onlineUsers, notifications }}
            >
                {
                    (permissions) => {

                        return [
                            <Resource name="Statistic"
                                list={ability.can('List', 'Statistic') ? StatisticList : null}
                                icon={StatisticIcon}
                            />,
                            <Resource name="Order"
                                list={ability.can('List', 'Order') ? OrderList : null}
                                show={ability.can('Show', 'Order') ? OrderShow : null}
                                create={ability.can('Create', 'Order') ? OrderCreate : null}
                                edit={ability.can('Edit', 'Order') ? OrderEdit : null}
                                icon={OrderIcon}
                            />,
                            <Resource name="Counterparty"
                                list={ability.can('List', 'Counterparty') ? CounterpartyList : null}
                                show={ability.can('Show', 'Counterparty') ? CounterpartyShow : null}
                                edit={ability.can('Edit', 'Counterparty') ? CounterpartyEdit : null}
                                create={ability.can('Create', 'Counterparty') ? CounterpartyCreate : null}
                                icon={CounterpartyIcon}
                            />,
                            <Resource name="User"
                                list={ability.can('List', 'User') ? UserList : null}
                                show={ability.can('Show', 'User') ? UserShow : null}
                                edit={ability.can('Edit', 'User') ? UserEdit : null}
                                create={ability.can('Create', 'User') ? UserCreate : null}
                                icon={UserIcon}
                            />,
                            <Resource name="Meter"
                                list={ability.can('List', 'Meter') ? MeterList : null}
                                show={ability.can('Show', 'Meter') ? MeterShow : null}
                                edit={ability.can('Edit', 'Meter') ? MeterEdit : null}
                                create={ability.can('Create', 'Meter') ? MeterCreate : null}
                                icon={MeterIcon}
                            />,
                            <Resource name="Status" />,
                            <Resource name="Result" />,
                            <Resource name="OrderHistory"
                                list={ability.can('List', 'OrderHistory') ? OrderHistoryList : null}
                                show={ability.can('Show', 'OrderHistory') ? OrderHistoryShow : null}
                                icon={OrderHistoryIcon}
                            />,
                            <Resource name="File"
                                list={ability.can('List', 'File') ? FileList : null}
                                show={ability.can('Show', 'File') ? FileShow : null}
                                icon={FileIcon}
                            />,
                            <Resource name="Location"
                                list={ability.can('List', 'Location') ? LocationList : null}
                                show={ability.can('Show', 'Location') ? LocationShow : null}
                                icon={LocationIcon}
                            />,
                            <Resource name="Profile" />,
                            <Resource name="SIType" />,
                            <Resource name="CCSVType" />,
                            <Resource name="SUType" />,
                            <Resource name="GSIType" />,

                        ];
                    }
                }

            </Admin>
        </AbilityContext.Provider>
    );
};

export default App;
