import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { GetAccessToken} from '../authProvider';
import { URI_AUTH } from '../../appsettings';

const httpLink = createHttpLink({
    uri: URI_AUTH
})

const authLink = setContext(async (_, { headers }) => {           
    const token = await GetAccessToken(); 
    //console.log('++++> ['+URI_DATA+'] header get TOKEN from localStorage ('+token+')');
   
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
});

export const apolloAuthClient = new ApolloClient({
    link: authLink.concat(httpLink), 
    cache: new InMemoryCache()
})

export async function GetUsers() {  
    var response = await apolloAuthClient.query({
        query: gql`
        {
            users {
                id,
                name,
                role,
                email,
                phone
            }
        }`,
        onError: (error) => {
            alert(error)
        },
    });

    return response;
} 

export async function createUser(user) {      

    var response = await apolloAuthClient.mutate({
        mutation: gql`
            mutation createUser($email: String, $phone: String!, $password: String!, $name: String!, $role: String) {
                createUser(email: $email, password:$password, name:$name, phone:$phone, role:$role) {       
                    id
                    name
                    email
                    phone
                    role
                }
            }
        `,
        variables: {
            email:user.email,
            password:user.password,   
            name:user.name,
            phone: user.phone, 
            role: user.role,        
        }
    });

    return response.data.createUser;
} 

export async function updateUser(user) {      
      
    var response = await apolloAuthClient.mutate({
        mutation: gql`
            mutation updateUser($id: ID! $email: String, $password: String, $name: String, $phone: String) {
                updateUser(id:$id, email: $email, password:$password, name:$name, phone:$phone) {       
                    id    
                    email
                    name
                    phone
                    role
                }
            }
        `,
        variables: {
            id: user.authId,
            email:user.email,
            password:user.password,   
            name:user.name,
            phone: user.phone, 
            role: user.role,        
        }
    });

    return response.data.updateUser;
}