import { GetAccessToken } from '../authProvider';
import { URI_ORDERS_TO_XML } from '../../appsettings';

const exportOrdersToXml = async ({ ids }) => {

    const url = `${URI_ORDERS_TO_XML}/convert`;
    const token = await GetAccessToken();
    const raw = JSON.stringify({ ids });


    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
        },
        body: raw,
    };

    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) throw response;
            const contentType = response.headers.get("content-type");
            if (contentType === "application/json") {
                return response.json()
            } else {
                const сontentDisposition = response.headers.get("Content-disposition");
                const filenameRegexp = /filename="(.*)"/
                const filename = сontentDisposition.match(filenameRegexp)[1] || "orders.zip"

                return response.blob().then(blob => {
                    return {
                        blob,
                        filename
                    }
                });
            }

        })
        .catch(error => {
            throw Error(error)
        });
}

export default exportOrdersToXml