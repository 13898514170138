import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, crudUpdateMany, startUndoable } from 'react-admin';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

const styles = theme => ({
    button: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    archiveButton: {
        color: theme.palette.error.main,
        borderColor: fade(theme.palette.error.main, 0.5),
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            borderColor: theme.palette.error.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
       
    },
    unarchiveButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            borderColor: theme.palette.primary.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },   
    },
});
class BulkArchiveButton extends Component {
    handleArchive = () => {
        const {  basePath, resource, selectedIds, startUndoable } = this.props;
        startUndoable(
            crudUpdateMany(resource, selectedIds, { isArchive: true }, basePath)
        );
    };

    handleUnarchive = () => {
        const {  basePath, resource, selectedIds, startUndoable } = this.props;
        startUndoable(
            crudUpdateMany(resource, selectedIds, { isArchive: false }, basePath)
        );
    };

    render() {
        const { className, classes, filterValues } = this.props;
        return filterValues.tab ==="archived" ? (
            <Button 
                size="small"
                variant="outlined"
                label="raExt.action.unarchive"
                onClick={this.handleUnarchive}
                className={`${classes.button} ${classes.unarchiveButton} ${className}`}
            >
                <UnarchiveIcon />
            </Button>
        ) : (
            <Button 
                size="small"
                variant="outlined"
                label="raExt.action.archive"
                onClick={this.handleArchive}
                className={`${classes.button} ${classes.archiveButton} ${className}`}
            >
                <ArchiveIcon />
            </Button>
        );
    }
}

const enhance = compose(
    withStyles(styles),
    connect(
        null,
        {
            startUndoable
        }
    )
);

export default enhance(BulkArchiveButton);