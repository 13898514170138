import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { ARCHIVE_FAILURE, UNARCHIVE_FAILURE } from './ArchiveAction';

export default function* archiveSaga() {
    yield all([
        takeEvery(ARCHIVE_FAILURE, function*({ error }) {
            yield put(
                showNotification(
                    'raExt.notification.archive_failure',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(UNARCHIVE_FAILURE, function*({ error }) {
            yield put(
                showNotification(
                    'raExt.notification.unarchive_failure',
                    'warning'
                )
            );
            console.error(error);
        }),
    ]);
}