import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, crudUpdate } from 'react-admin';
import BanIcon from '@material-ui/icons/Report';
import UnbanIcon from '@material-ui/icons/ReportOff';
import { startUndoable } from 'ra-core';

const styles = theme => ({
    button: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
      },
    banButton: {
        color: theme.palette.error.main,
        borderColor: fade(theme.palette.error.main, 0.5),
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            borderColor: theme.palette.error.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
       
    },
    unbanButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            borderColor: theme.palette.primary.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
       
    },
});

class BanButton extends Component {


    handleBan = () => {
        const { startUndoable, resource, record, basePath, redirect } = this.props;
        startUndoable(
            crudUpdate(resource, record.id, { id: record.id, authStatus: "BANNED" }, record, basePath, redirect)
        );
    };

    handleUnban = () => {
        const { startUndoable, resource, record, basePath, redirect } = this.props;
        startUndoable(
            crudUpdate(resource, record.id, { id: record.id, authStatus: "ACTIVE" }, record, basePath, redirect)
        );
        
    };

    render() {
        const { record, className, classes } = this.props;
        if (!record || record.authStatus === "NONE" || record.isArchive) return null;

        
        return record.authStatus === "ACTIVE"
        ? (<Button 
            size="small"
            variant="outlined"
            label="raExt.action.ban"
            onClick={this.handleBan}
            className={`${classes.button} ${classes.banButton} ${className}`}
        >
            <BanIcon />
        </Button>)
        : (<Button 
            size="small"
            variant="outlined"
            label="raExt.action.unban"
            onClick={this.handleUnban} 
            className={`${classes.button} ${classes.unbanButton} ${className}`}
        >
            <UnbanIcon />
        </Button>);
   
    }
}

BanButton.propTypes = {
    record: PropTypes.object,
    startUndoable: PropTypes.func,

};

const enhance = compose(
    withStyles(styles),
    connect(
        null,
        {
            startUndoable,
        }
    )
);

export default enhance(BanButton);