import russianMessages from 'ra-language-russian'

export default {
    ...russianMessages,
    raExt: {
        action: {
            create_and_continue: "Coздать и продолжить",
            create_and_process: "Coздать и обработать",
            save_and_continue: "Сохранить и продолжить",
            create_related_order: "Coздатьзаявку",
            findDuplicate: "Найти дубликаты",
            process: "Обработать",
            in_work: "Вработу",
            complete: "Выполнить",
            cancel: "Отменить",
            more: "Дополнительно",
            download_files: "Скачать файлы",
            archive: "Вархив",
            unarchive: "Изархива",
            ban: "Заблокировать",
            unban: "Разблокировать",
            favorite: "Визбранное",
            unfavorite: "Изизбранного",
            select_all: "Выбрать все",
            set_full_screen: "Развернуть",
            exit_full_screen: "Свернуть",
            clear: "Очистить",
            clear_all: "Очистить все",
            close: "Закрыть",
            get_more: "Получить еще",
            export_orders_to_xml: "Экспортировать ФГИС \"АРШИН\" .xml",
        },
        auth: {
            phoneOrEmail: 'Телефон или email',
        },
        page: {
            createOrderTitle: "Coздать заявку",
        },
        validation: {
            ruPhone: 'Неправильно введен номер. Доступен формат: 8 (XXX) XXX-XX-XX.',
        },
        notification: {
            duplicates_not_found: 'Дубликаты не найдены',
            duplicates_found: 'Найдена заявка с таким же адресом |||| Найдены заявки с таким же адресом',
            admin_created: 'Cоздан новый администратор',
            manager_created: 'Cоздан новый руководитель',
            employee_created: 'Cоздан новый сотрудник',
            customer_created: 'Cоздан новый заказчик',
            verifier_created: 'Cоздан новый поверитель',
            dispatcher_created: 'Cоздан новый диспетчер',
            failed_to_create_user: 'Не удалось создать пользователя',
            is_archive: "В архиве",
            archive_success: "Элемент добавлен в архив",
            archive_failure: "Ошибка добавления в архив",
            unarchive_success: "Элемент восстановлен из архива",
            unarchive_failure: "Ошибка восстановления из архива",
            orderOverdue: "Заявка просрочена",
            order_user_empty_data: "Не назначен",
            order_meter_empty_data: "Не определен",
            noData: "Нет данных",
            empty: "Пусто",
            empty_data: "Данные отсутствуют",
            noFiles: "Нет файлов",
            download_files_empty: 'В заявке отсутствуют файлы |||| В %{smart_count} заявках отсутствуют файлы',
        },
    },
    resources: {
        Order: {
            name: 'Заявка |||| Заявки',
            fields: {
                humanId: 'Номер',
                statusRef: 'Статус',
                meter: 'Модификация',
                ["meter.id"]: 'Модификация',
                ["meter.type"]: 'Тип прибора',
                ["meter.name"]: 'Модификация',
                meterLocation: "Месторасположение",
                meterUninstallAt: "Дата снятия прибора",
                resultRef: 'Результат',
                resultDesc: 'Комментарий к результату',
                comment: 'Комментарий',
                oneOfUser: 'Пользователь',
                createdAt: 'Создана',
                createdAt_gte: 'Создана с',
                createdAt_lte: 'Создана по',
                completedAt: 'Выполнена',
                completedAt_gte: 'Выполнена с',
                completedAt_lte: 'Выполнена по',
                completedDeadlineAt: 'Срок',
                completedDeadlineAt_gte: 'Срок с',
                completedDeadlineAt_lte: 'Срок по',
                updatedAt: 'Обновлена',
                mailIndex: 'Индекс',
                regionId: 'Регион',
                districtIdOrCityId: 'Район / Город',
                districtId: '• Район / Город',
                cityId: 'Населенный пункт',
                streetId: 'Улица',
                houseNum: 'Дом',
                houseCorp: 'Корпус',
                houseFlat: 'Квартира',
                addrManual: 'Адрес',
                invoice: 'Квитанция',
                personalAccount: 'Лицевой счет',
                isSealNotInstalled: 'Пломба не устанавливалась',
                customer: 'Заказчик',
                ["customer.id"]: 'Заказчик',
                ["customer.name"]: 'Заказчик',
                ["customer.phone"]: 'Телефон заказчика',
                customerType: 'Тип заказчика',
                verifier: 'Поверитель',
                ["verifier.id"]: 'Поверитель',
                ["verifier.name"]: 'Поверитель',
                ["verifier.phone"]: 'Телефон поверителя',
                dispatcher: 'Диспетчер',
                ["dispatcher.id"]: 'Диспетчер',
                ["dispatcher.name"]: 'Диспетчер',
                ["dispatcher.phone"]: 'Телефон диспетчера',
                creator: 'Создатель',
                ["creator.id"]: 'Создатель',
                created: 'Создал',
                nextCompletedDeadlineAt: 'Следующая поверка',
                files: 'Файлы',
                tab: 'Вкладка',

                manufactureYear: 'Год выпуска',
                manufactureNum: 'Заводской номер',

                siTypeId: 'Номер в госреестре',
                siTypeName: 'Наименование',
                siTypeNumber: 'Номер в госреестре',
                siTypeMPIYears: 'МПИ, Лет',
                siTypeMPIMonths: 'МПИ, Месяцев',
                siTypeManufacturerTotal: 'Изготовитель',

                ccsvTypeId: 'Условный шифр знака поверки',
                ccsvTypeCCSV: 'Условный шифр знака поверки',

                suTypeId: 'Номер эталона по реестру',
                suTypeNumber: 'Номер эталона',
                suTypeName: 'Наименование эталона',

                gsiTypeId: 'Номер документа',
                gsiTypeType: 'Вид документа, на основании которого выполнена поверка',
                gsiTypeNumber: 'Номер документа, на основании которого выполнена поверка',
                gsiTypeName: 'Наименование документа, на основании которого выполнена поверка',

                isSIOwnedByVerifier: 'Поверитель является владельцем СИ',
                signMi: 'Наличие знака поверки на СИ',
                signPass: 'Наличие знака поверки в паспорте',
                validAt: 'Действительна до',
                certificateNumber: 'Номер свидетельства',
                stickerNumber: 'Номер наклейки',

            },
            helper: {
                resultDesc: 'Этот комментарий будет виден заказчику',
                comment: 'Этот комментарий не виден заказчику',
                completedDeadlineAt: 'Если текущая дата или дата выполнения превысит срок, то заявка считается просроченной',
                nextCompletedDeadlineAt: 'Если поле заполнено, то заявка на следующую поверку будет создана автоматически',
                filesAttached: 'Файлы прикреплены',
                isDeadlineAt: 'Просрочена',
                addressMatches: 'Адрес заявки совпадает с адресом наблюдателя',
                changeResult: 'Необходимо выбрать результат',

            },
            tabs: {
                current: 'Текущие',
                cancelled: 'Отмененные',
                completed: 'Выполненные',
                archived: 'Архив',
                general: 'Основные',
                measuringInstrument: 'Средство измерения',
                history: 'История',
                result: 'Результат',
                files: 'Файлы',
                favorite: 'Избранные',
            },
            statuses: {
                new: 'Новая',
                processed: 'Обработана',
                inWork: 'В работе',
                completed: 'Выполнена',
                cancelled: 'Отменена',
            },
            results: {
                verified: 'Прошел поверку',
                notVerified: 'Не прошел поверку',
                swap: 'Замена счетчика',
                refused: 'Отказ от обслуживания',
                noneSeal: "Отсутствует пломба",
                other: "Другой..."
            },
            actions: {
                createOrderTitle: "Coздать заявку",
                create: "Coздать",
                create_and_add: "Coздать и продолжить",
            },
            enums: {
                meterLocation: {
                    INDOORS: 'В помещении',
                    OUTDOORS: 'На улице',
                    UNKNOWN: 'Неизвестно'
                },
            },
            errors: {
                resultRef: 'Необходимо установить результат',
                resultRef_full: 'Результат указывается для Выполнить или Отменить',
                resultDesc: 'Необходимо добавить комментарий к результату',
                invoice: 'Необходимо включить "Пломба не устанавливалась", либо ввести номер квитанции',
                files: 'Необходимо прикрепить файлы',
            }
        },
        Customer: {
            name: 'Заказчик |||| Заказчики',
            fields: {
                id: 'ID',
                name: 'ФИО',
                type: "Тип заказчика",
                organizationName: "Организация",
                organizationInn: "ИНН",
                payment: "Тип платежа",
            },
            types: {
                PHIS: 'Физическое лицо',
                JURE: 'Юридическое лицо',
            },
            payments: {
                CASH: 'Наличный',
                CASHLESS: 'Безналичный',
            },
        },
        Verifier: {
            name: 'Поверитель |||| Поверители',
            fields: {
                id: 'ИД',
                regionId: 'Регион',
                districtIdOrCityIds: 'Район / Город',

            },
        },
        Location: {
            name: 'Адрес |||| Адреса',
            fields: {
                id: 'ID',
                typeShort: 'Тип',
                name: 'Наименование',
            },
        },
        Counterparty: {
            name: 'Контрагент |||| Контрагенты',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                type: 'Тип',
                inn: 'ИНН',
                kpp: 'КПП',
                okpo: 'ОКПО',
                ogrn: 'ОГРН',
                fio: 'ФИО',
                passport: 'Паспорт',
                passdateissue: 'Дата выдачи',
                parentId: 'Родитель',
                addressLegal: 'Юридический адрес',
                addressActual: 'Фактический адрес',
                addressPostal: 'Почтовый адрес',
                phone: 'Телефон',
                phoneMobile: 'Мобильный',
                email: 'Email',
                users: 'Пользователи',
                accessResource: 'Ресурс',
                access: 'Доступ',
                filterGasRegion: 'Фильтр по Регионам',
                filterGasDistrict: 'Фильтр по Участкам',
                filterGasConsumer: 'Фильтр по Потребителям',
                filterGasMeterPad: 'Фильтр по Площадкам',
                accessGasRegion: 'Доступ по Регионам',
                accessGasDistrict: 'Доступ по Участкам',
                accessGasConsumer: 'Доступ по Потребителям',
                accessGasMeterPad: 'Доступ по Площадкам',
                q: 'Поиск',
            },
            enums: {
                type: {
                    LEGALENTITY: 'Юридическое лицо',
                    INDIVIDUAL: 'Физическое лицо',
                    SEPARATEDIVISION: 'Обособленное подразделение',
                    GOVERNMENTAGENCY: 'Гос.орган',
                },
            },
        },
        User: {
            name: 'Пользователь |||| Пользователи',
            fields: {
                id: 'ID',
                name: 'ФИО',
                role: 'Роль',
                email: 'Email',
                password: 'Пароль',
                phone: 'Телефон',
                createdAt: 'Создан',
                updatedAt: 'Обновлен',
                regionIds: 'Регион',
                regionId: 'Регион',
                districtIdOrCityIds: 'Район / Город',
                notificationTags: 'Теги',
                customer: {
                    type: "Тип заказчика",
                    organizationName: "Организация",
                    organizationInn: "ИНН",
                    payment: "Тип платежа",
                },
                counterparty: "Контрагент",
            },
            roles: {
                SUPERADMIN: 'Суперадмин',
                ADMIN: 'Администратор',
                SENIORDISPATCHER: 'Старший диспетчер',
                DISPATCHER: 'Диспетчер',
                CUSTOMER: 'Заказчик',
                SENIORVERIFIER: 'Старший поверитель',
                VERIFIER: 'Поверитель',
                MANAGER: 'Руководитель',
                INSPECTOR: 'Инспектор',
                SUPERVISOR: 'Наблюдатель',
                DEMO: 'Демо'
            },
            authStatuses: {
                ACTIVE: 'Активен',
                BANNED: 'Заблокирован',
                NONE: 'Не зарегистрирован',
            },
            tabs: {
                all: 'Все',
                customers: 'Заказчики',
                employees: 'Сотрудники',
                admins: 'Администраторы',
                archived: 'Архив',
                general: 'Основные',
                accessLocation: 'Доступ',
                more: 'Дополнительные',
                orders: 'Заявки',
                history: 'История',
                notifications: 'Уведомления',
            },
            dialogTitle: {
                create: {
                    CUSTOMER: 'Создание нового заказчика',
                    DISPATCHER: 'Создание нового диспечера',
                    VERIFIER: 'Создание нового поверителя',
                    MANAGER: 'Создание нового руководителя',
                    EMPLOYEE: 'Создание нового сотрудника'
                }
            },
            online: 'Сейчас в сети',
            offline: 'Был в сети %{timeAgo}',
        },
        Profile: {
            name: 'Профиль',
            fields: {
                id: 'ID',
                name: 'ФИО',
                role: 'Роль',
                email: 'Email',
                password: 'Пароль',
                phone: 'Телефон',
                createdAt: 'Создан',
                updatedAt: 'Обновлен',
                lastUpdatedAt: 'Был в сети',
                regionIds: 'Регион',
                regionId: 'Регион',
                districtIdOrCityIds: 'Район / Город',
                notificationTags: 'Теги',
                customer: {
                    type: "Тип заказчика",
                    organizationName: "Организация",
                    organizationInn: "ИНН",
                    payment: "Тип платежа",
                }
            },
        },
        Meter: {
            name: 'Прибор |||| Приборы',
            fields: {
                id: 'ID',
                type: 'Тип прибора',
                name: 'Прибор',
                description: 'Описание',
                documentation: 'Документация',
                verifyInterval: 'Поверочный интервал',
            },
            enums: {
                type: {
                    GAS: 'Счетчик газовый',
                },
            },
            empty: 'Не определен',
        },
        OrderHistory: {
            name: 'История',
            fields: {
                id: 'ID',
                action: 'Действие',
                order: 'Заявка',
                orderHumanId: 'Номер заявки*',
                ["order.humanId"]: 'Номер заявки',
                user: 'Пользователь',
                userName: 'Пользователь*',
                ["user.name"]: 'Пользователь',
                changes: 'Изменения',
                createdAt: 'Дата',
                updated: 'Дата'
            },
            actions: {
                CREATED: 'Создана',
                UPDATED: 'Обновлена',
                DELETED: 'Удалена',
                PROCESSED: 'Обработана',
                INWORK: 'В работе',
                COMPLETED: 'Выполнена',
                CANCELLED: 'Отменена',
                SYNCHRONIZED: 'Синхронизирована',
                SYNCHRONIZEDERROR: 'Не синхронизирована',
                ARCHIVED: "В архивe",
                UNARCHIVED: "Из архива",
            },
            helper: {
                CREATED: 'Заявка создана.',
                CREATED_FIELD: 'Заполнены поля:',
                UPDATED: 'Заявка обновлена.',
                UPDATED_FIELD: 'Обновлены поля:',
                DELETED: 'Заявка удалена.',
                PROCESSED: 'Заявка обработана.',
                INWORK: 'Заявка в работе.',
                COMPLETED: 'Заявка выполнена.',
                CANCELLED: 'Заявка отменена.',
                ARCHIVED: "Заявка перемещена в архив.",
                UNARCHIVED: "Заявка восстановлена из архива.",
                SYNCHRONIZED: 'Заявка синхронизирована',
                SYNCHRONIZEDERROR: 'Ошибка при синхронизации',
            },
        },
        File: {
            name: 'Файл |||| Файлы',
            fields: {
                id: 'ID',
                desc: 'Описание',
                ext: 'Формат',
                size: 'Размер',
                created: 'Создан',
                createdAt: 'Добавлен',
                createdBy: 'Добавил',
                createdByAuth: 'Добавил',
                catalog: 'Каталог',
                idBlob: 'idBlob',
                url: 'url',
                urlThumbnail: 'urlThumbnail',
            },
            navigation: {
                files_not_found: 'Файлы не найдены',
                add_several_files: 'Добавьте несколько файлов к заявке',
            },
        },
        Statistic: {
            name: 'Статистика',
            fields: {
                id: "ID",
                typeShort: "Тип",
                name: "Район / Город",
                unrestrictedName: "Полное наименование",
                deadlined: "Просроченные",
                incompleted: "Незавершенные",
                completed: 'Выполнено',
                verified: "Прошел поверку",
                notVerified: "Не прошел поверку",
                noneSeal: "Отсутствует пломба",
                swap: "Замена",
                refused: "Отказ от обслуживания",
                createdAt: 'Создана',
                createdAt_gte: 'Создана с',
                createdAt_lte: 'Создана по',
                completedAt: 'Выполнена',
                completedAt_gte: 'Выполнена с',
                completedAt_lte: 'Выполнена по',
                completedDeadlineAt: 'Срок',
                completedDeadlineAt_gte: 'Срок с',
                completedDeadlineAt_lte: 'Срок по',
                oneOfUser: 'Пользователь',
                customerType: 'Тип заказчика',
                verifier: 'Поверитель',
                dispatcher: 'Диспетчер',
                regionId: 'Регион',
                totalDistrictOrCity: 'Всего',
                total: 'Итого'
            },
            statuses: {
                created: 'Создано',
                deadlined: 'Просрочено',
                incompleted: 'Не завершено',
                completed: 'Завершено',
                cancelled: 'Отменено',
            }
        },
        AboutApp: {
            name: 'О приложении',
            modules: {
                Core: {
                    name: 'Ядро',
                },
                Auth: {
                    name: 'Аутентификация',
                },
                Blob: {
                    name: 'Хранилище',
                },
                Casl: {
                    name: 'Идентификация',
                },
                Fias: {
                    name: 'Федеральная информационная адресная система (ФИАС)',
                },

            },
            errrors: {
                not_available: 'Сервис недоступен',
            }
        },
        Notification: {
            name: 'Уведомление |||| Уведомления',
            tags: {
                NEWORDER: 'Новая заявка',
                EXPIRED: 'Заявка просрочена',
            },
            no_notifications: 'Уведомлений нет',
            you_have_unconfirmed_notifications: 'Есть неподтвержденные уведомления',
            show_notifications: 'Просмотреть уведомления',
        },
    },
    Error: {
        ["User is banned"]: 'Пользователь заблокирован. Обратитесь к администратору.',
        ['User with such login does not exist.']: 'Пользователь с таким логином не существует.',
        ['User with such login and password does not exist.']: 'Пользователь с таким логином и паролем не существует.',
        ['Аuthorization error!']: 'Ошибка авторизации!',
    },
}