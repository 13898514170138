import React from 'react';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';

const ResultField = ({ record = {},translate, ...props }) => (
    <div>
        {(!!record.resultRef) && (<Typography color="inherit" noWrap>{translate(`resources.Order.results.${record.resultRef}`)}</Typography>)}
        {(!!record.resultDesc) && (<Typography variant="caption" >{record.resultDesc}</Typography>)}
    </div>
);

ResultField.defaultProps = {
    source: 'resultRef',
};


export default translate(ResultField);