import React from 'react';
import { AutocompleteInput, Create, SimpleForm, LongTextInput, ReferenceInput, DateInput, Toolbar, SaveButton, translate, required, } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AddressInput from './AddressInput';
import UserReferenceInput from '../User/UserReferenceInput';
import CreateWithProcessButton from '../Buttons/CreateWithProcessButton';
import { ability } from '../../ability';
import EnumInput from '../Inputs/EnumInput';
import { optionMeter } from '../Meter/MeterField';

import { requiredCustom } from '../../utils';


const styles = theme => ({
    mr2: { marginRight: ".5rem" },
});


const OrderCreate = translate(({ permissions, translate, ...props }) => {


    return (
        <Create {...props} title="raExt.page.createOrderTitle" >
            <SimpleForm toolbar={<CreateToolbar permissions={permissions} />} redirect="list" >
                <UserReferenceInput source="customer.id" reference="User" allowEmpty filter={{ role: "CUSTOMER" }} validate={requiredCustom()} />
                <AddressInput />
                <ReferenceInput source="meter.id" reference="Meter" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                    <AutocompleteInput optionText={optionMeter(translate)} />
                </ReferenceInput>
                <EnumInput source="meterLocation" Enum="MeterLocation" allowEmpty emptyValue={null} />
                <LongTextInput source="comment" helperText={translate("resources.Order.helper.comment")} />
                <DateInput source="meterUninstallAt" />
                <DateInput source="completedDeadlineAt" validate={required()} helperText={translate("resources.Order.helper.completedDeadlineAt")} />
            </SimpleForm>
        </Create>
    );
});

// class Order {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }

const CreateToolbar = withStyles(styles)(({ classes, ...props }) => {
    const { permissions, record } = props;

    return (
        <Toolbar {...props} >
            { ability.can("process", { constructor: { name: "Order" }, status: record.statusRef }) && (
                <CreateWithProcessButton className={`${classes.mr2}`} label="raExt.action.create_and_process" redirect="list" submitOnEnter={false} />
            )}
            { ability.can("create", { constructor: { name: "Order" }, status: record.statusRef }) && (
                <SaveButton className={`${classes.mr2}`} label="ra.action.create" redirect="list" submitOnEnter={true} />
            )}
            { ability.can("create", { constructor: { name: "Order" }, status: record.statusRef }) && (
                <SaveButton label="raExt.action.create_and_continue" redirect={false} submitOnEnter={false} variant="outlined" />
            )}

        </Toolbar>
    );
});

export default OrderCreate;