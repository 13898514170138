import React from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { translate } from 'react-admin';
import FavoriteButton from '../Buttons/FavoriteButton';

const styles = theme => ({
    title:{
        display: "flex",
        alignItems: "center"
    },
    titleSecondary:{
        color: fade(theme.palette.primary.contrastText, 0.87),
    },
    ['ml-2']:{
        marginLeft: "1rem"
    },
});



const Title = ({ record, translate, className, classes, ...props }) => {
    const lastUpdated = moment(record.updatedAt).fromNow()
    return (
        <div className={classes.title} >
            <span>{record ?  `${record.name}` : `${translate('resources.User.name'), { smart_count: 1 }} ${record.id}` }</span>
            <Typography className={`${classes.titleSecondary} ${classes['ml-2']}`} >{(record.online) ? translate('resources.User.online') : translate('resources.User.offline', { timeAgo: lastUpdated })}</Typography>
        </div>
    );
};

export default compose( withStyles(styles), translate, )( Title );

