import React, { cloneElement, Children, isValidElement } from 'react';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import compose from 'recompose/compose';


const sanitizeGridItemProps = ({
  lg,
  md,
  sm,
  xs,
  zeroMinWidth,
  ...props
}) => props;


const styles = theme => ({
  root: {
    minWidth: 256,
  }
});

const NestedGridField = ({ children, classes: classesOverride, record, source, className, label, classes, translate, ...props }) => {

  return (
    <Grid container spacing={2}>
      {Children.map(children, (field, index) => {

        const {
          lg,
          md,
          sm,
          xs,
          zeroMinWidth,
          className,
          ...inputProps
        } = field.props;

        const gridItemProps = {
          lg,
          md,
          sm,
          xs,
          zeroMinWidth
        };

        return isValidElement(field) ? (
          <Grid item key={field.props.source || index} {...gridItemProps} >
            {React.cloneElement(field, {
              ...inputProps,
              className: classes.root,
              fullWidth: true,
            })}
          </Grid>
        ) : null

      }

      )}
    </Grid>
  );
};


export default compose(withStyles(styles), translate,)(NestedGridField);