import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Button, Link, linkToRecord, GET_LIST, withDataProvider, showNotification, translate } from 'react-admin';
import CompareIcon from '@material-ui/icons/Compare';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import findDuplicateOrders from '../../dataProviders/Core/findDuplicateOrders';
import PhoneField from '../Fields/PhoneField';
import { StatusIcon } from '../Fields/StatusChip';



const styles = theme => ({
    link: {
        color: "inherit"
    },
    container: {
        minWidth: 400,
        marginTop: "1rem",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    title: {
        color: theme.palette.grey['400'],
    },
    body: {
        display: "flex",
        alignItems: "center",
    },
    PhoneIcon: {
        fontSize: "1rem",
        marginRight: "0.25rem",
        color: theme.palette.grey["500"]
    },
    caption: { color: theme.palette.grey['500'] },
    ml2: { marginLeft: "0.5rem" },
    ml3: { marginLeft: "1rem" },
    mt2: { marginTop: "0.5rem" },
});

const DuplicateOrder = withStyles(styles)(({ record, classes, ...props }) => {
    return (
        <Link to={`${linkToRecord("/Order", record.id, "show")}`} className={classes.link}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="body1" className={classes.title}>{record.humanId}</Typography>
                    <Typography variant="caption" className={classes.caption} >{moment(record.updatedAt).format('L')}</Typography>
                </div>
                <div className={classes.body}>
                    <StatusIcon record={record} />
                    <Typography variant="body1" color="inherit" className={classes.ml2} >{record.customer.name}</Typography>
                    <PhoneField source="phone" variant="caption" className={`${classes.caption} ${classes.ml2}`} record={record.customer} />
                </div>
            </div>
        </Link>
    );
});


const FindDuplicateButton = ({ address, dataProvider, dispatch, classes, translate, ...props }) => {

    const handleFindDuplicate = () => {
        findDuplicateOrders({
            page: 0,
            perPage: 10,
            sortField: 'updatedAt',
            sortOrder: 'DESC',
            filter: {
                addrManual: address,
                statusRefs: ["new", "processed", "inWork", "cancelled"],
                updatedAt_gte: moment().subtract(6, 'months').format(),
            },
        })
            .then(({ data }) => {

                const orders = data.findOrders
                const total = orders.length

                if (total === 0) return dispatch(showNotification('raExt.notification.duplicates_not_found'));

                dispatch(showNotification(
                    <Fragment>
                        <Typography variant="body1" color="inherit" gutterBottom>{translate('raExt.notification.duplicates_found', { smart_count: total })}</Typography>
                        {orders.map(order => {
                            return (
                                <DuplicateOrder record={order} key={order.id} />
                            );

                        })}
                    </Fragment>,
                    'info',
                    { autoHideDuration: 7000 }
                ));

            })
            .catch((e) => {
                dispatch(showNotification(e.message, 'warning'));
            });
    };

    return (
        <Button
            variant="outlined"
            label="raExt.action.findDuplicate"
            onClick={handleFindDuplicate}
            disabled={!address}
            {...props}
        >
            <CompareIcon />
        </Button>


    );
}

export default compose(
    withDataProvider,
    translate,
    withStyles(styles)
)(FindDuplicateButton);
