import React from 'react';
import compose from 'recompose/compose';
import { Button, withDataProvider, GET_MANY, showNotification } from 'react-admin';
import FileDownloadIcon from '../Icons/FileDownload';
import getUrlArchive from '../../providers/Blob/archiveFiles';
import { GetAccessToken } from '../../providers/authProvider';
import { BLOB_SETHOST } from '../../appsettings';

const getUrlWithToken = (url, token) => {
    return url.indexOf('blob:') < 0 ? `${url}?${token}` : url;
};

const renameUrl = (url) => {
    if (BLOB_SETHOST && BLOB_SETHOST !== '') {
        const parsed_url = new URL(url);
        const new_url = url.replace(parsed_url.origin, BLOB_SETHOST);
        return new_url;
    }

    return url;
}

const BulkDownloadFilesButton = ({ resource, basePath, filterValues, selectedIds, dataProvider, dispatch, ...props }) => {

    const handleBulkDownloadFiles = () => {

        dataProvider(GET_MANY, resource, { ids: selectedIds })
            .then(async ({ data, total }) => {

                let orders = data.map(order => ({
                    humanId: order.humanId,
                    addrManual: order.addrManual,
                    idsBlob: order.files.map(file => (file.idBlob))
                }));


                orders = orders.filter(order => order.idsBlob.length > 0);

                if (total - orders.length > 0) {
                    dispatch(showNotification('raExt.notification.download_files_empty', 'info', { messageArgs: { smart_count: total - orders.length } }));
                };

                if (orders.length > 0) {
                    /////////////////////// тут будет вызов клиента /////////////////
                    var responce = await getUrlArchive(orders);
                    const token = await GetAccessToken();
                    const url = renameUrl(responce.data.getUrlArchiveFiles.url);
                    const urlWithToken = getUrlWithToken(url, token)

                    window.open(urlWithToken, '_blank');

                };
            })
            .catch((e) => {
                dispatch(showNotification(e.message, 'warning'));
            });
    };

    return (
        <Button label="raExt.action.download_files" onClick={handleBulkDownloadFiles} {...props} >
            <FileDownloadIcon />
        </Button>
    );
}

export default compose(
    withDataProvider,
)(BulkDownloadFilesButton);

