import russianhMessages from './ru'
import englishMessages from 'ra-language-english';

const messages = {
    ru: russianhMessages,
    en: englishMessages,
};


const i18nProvider = locale => messages[locale];

export default i18nProvider;