import radatagraphqlsimple from '../customdatagraphql'
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GetAccessToken} from '../authProvider';
import { URI_FIAS } from '../../appsettings';
import fiasBuiltQuery from './builtQuery';

const httpLink = createHttpLink({
    uri: URI_FIAS
})

const fiasLink = setContext(async (_, { headers }) => {           
    const token = await GetAccessToken(); 
    //console.log('++++> ['+URI_FIAS+'] header get TOKEN from localStorage ('+token+')');
   
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
});

export const apolloFiasClient = new ApolloClient({
    link: fiasLink.concat(httpLink), 
    cache: new InMemoryCache()
})

export const resourcebindingFias = ['Location']
export default () => radatagraphqlsimple({ 
    client : apolloFiasClient, 
    buildQuery : fiasBuiltQuery(apolloFiasClient)
})