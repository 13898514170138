import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import CloseIcon from '@material-ui/icons/Close';
import { Button, SimpleShowLayout, TextField} from 'react-admin';

import RoleField from './RoleField';
import PhoneField from '../Fields/PhoneField';


const styles = theme => ({
    paper: {
        minWidth:"16rem",
    },
    mlAuto:{ marginLeft:"auto" }
});

const UserPreviewView = ({ classes, ...props }) => (
    <SimpleShowLayout {...props}>
        <TextField source="name" className={classes.field} />
        <RoleField source="role" />
        <PhoneField source="phone"/>
        <TextField source="email" />
    </SimpleShowLayout>
);

const mapStateToProps = (state, props) => ({
    // Get the record by its id from the react-admin state.
    record: state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    version: state.admin.ui.viewVersion
});

const UserPreview = connect(mapStateToProps, {})(
    withStyles(styles)(UserPreviewView)
);

class UserQuickPreviewButton extends Component {
    state = { showPanel: false };

    
    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        const { id, classes } = this.props;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="ra.action.show">
                    <IconImageEye />
                </Button>
                <Drawer anchor="right" open={showPanel} onClose={this.handleCloseClick} classes={{paper: classes.paper}}>
                    <CardActions>
                        <IconButton onClick={this.handleCloseClick} aria-label="Close" className={classes.mlAuto}>
                            <CloseIcon />
                        </IconButton>
                    </CardActions>
                    <UserPreview id={id} basePath="/User" resource="User" />
                </Drawer>
            </Fragment>
        );
    }
}

export default connect()(withStyles(styles)(UserQuickPreviewButton));