import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';

import { connect } from 'react-redux';

const styles = theme => ({
    container:{
        display: "flex",
        alignItems: "center",
    },
    name:{
        marginLeft: theme.spacing.unit*2,
    },
    avatar: {
        fontSize: "1rem",
        height: 32,
        width: 32,
    },
    onlineBadge:{
        top: "unset",
        bottom: "-2px",
        right: "-2px",
        width: "10px",
        height: "10px",
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: green[500],
    },
    offlineBadge:{
        top: "unset",
        bottom: "-2px",
        right: "-2px",
        width: "10px",
        height: "10px",
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: grey[500],
    },

});

const getInitials = name => {
    var initials = name.split(" ") || [];
    var first = initials.shift()
    var second = initials.pop()
    initials = (first.charAt(0) + ( second ? second.charAt(0) : first.charAt(1) )).toUpperCase();
    return initials;
}

const UserNameWithAvatarField = ({ record, classes, className, onlineUsers,  ...props }) => {
    
    if (!record) return null
    const online = onlineUsers.some(id => id === record.id)
    
    if (record.authStatus == "NONE") {
        return (
            <div className={classes.container}>   
                <Avatar className={classes.avatar} >{getInitials(record.name)}</Avatar>
                <Typography variant="body1" className={classes.name}>{record.name}</Typography>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <Badge badgeContent={false} classes={{ badge: (online) ? classes.onlineBadge: classes.offlineBadge }}>
                <Avatar className={classes.avatar} >{getInitials(record.name)}</Avatar>
            </Badge>
            <Typography variant="body1" className={classes.name}>{record.name}</Typography>
        </div>
    );
    
} 


UserNameWithAvatarField.defaultProps = {
    source: 'name',
};

const mapStateToProps = state => {
    return { 
        onlineUsers: state.onlineUsers,
    }
};

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps),
   
);

export default enhance(UserNameWithAvatarField);

