import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css'

const MarkdownField = ({ path, ...props }) => {
    const [markdownText, setMarkdownText] = useState("");
    useEffect(() => {
        fetch(path)
        .then(res => res.text())
        .then(text => {
            setMarkdownText(text)
        })
    }, []);

    return (
        <div className='markdown-body'>
            <ReactMarkdown source={markdownText} escapeHtml={false} />
        </div>
    );

};

MarkdownField.propTypes = {
    path: PropTypes.string.isRequired,
};

export default MarkdownField;