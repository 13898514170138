import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import RegionIcon from '@material-ui/icons/Layers';
import DistrictIcon from '@material-ui/icons/Texture';
import CityIcon from '@material-ui/icons/LocationCity';
import TownIcon from '@material-ui/icons/Home';
import StreetIcon from '@material-ui/icons/Place';
import LocationIcon from '@material-ui/icons/Directions';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';


const getLocationIcon = level => {
    switch( level ){
        case 1:
        case 2:
            return (<RegionIcon />)
        break;
        case 3:
            return (<DistrictIcon />)
        break;
        case 4:
            return (<CityIcon />)
        break;
        case 5:
        case 6:
            return (<TownIcon />)
        break;
        case 7:
            return (<StreetIcon />)
        break;
        default:
            return (<LocationIcon />)
        break;
    }
}

const styles = theme => ({
    highlight: {
        fontWeight: 600,
        color: "000"
    },
    root: {
        height: "unset",
        flexDirection: "column",
        alignItems: "start",
    },
    flex: {
        display: "flex",
    },
    locationIcon: {
        marginRight: "0.25rem",
        color: theme.palette.grey["500"]
    },
    chip: { 
        margin: 4
    },
    avatar:{
        color: theme.palette.grey["500"],
        marginLeft:"4px",
        height: 24,
        width: 24,
    
    },
    checkBoxContainer: {
        display: "flex",
        alignItems: "flex-end",
    },
    ml2: {
        marginLeft: "0.5rem",
    },
});

const getParentsDescription = (parents=null) => { 
    if (!parents) return false;

    var description =  parents.reduceRight(function(previousValue, currentValue, index, array) {
        return previousValue + `${(index === array.lenght - 1) ? `${currentValue.name} ${currentValue.typeShort}.` : `${currentValue.typeShort}. ${currentValue.name}`}${(index === 0) ? "" : ", "}`;
    },"");
      
    return description;
};

export const AddressSuggestionComponent = withStyles(styles)(({ suggestion, query, isHighlighted, classes, ...props }) => {
    const suggestionText = `${suggestion.name}`;
    const matches = match(suggestionText.toLocaleLowerCase(), query.toLocaleLowerCase());
    const parts = parse(suggestionText, matches);
    const {className, ...rest} = props;
    
    return suggestion.level > -1 ? (     
        <div className={`${className} ${classes.root}`} {...rest}>  
            <div className={`${classes.flex}`}>
                {React.cloneElement(getLocationIcon(suggestion.level), { className:`${classes.locationIcon}`} )}
                <span>
                    <span>{`${suggestion.typeShort}. `}</span>
                    {parts.map((part, index) => {
                    const className = part.highlight ? classes.highlight : null;

                    return (<span className={className} key={index}>{part.text}</span>);
                    })}
                </span>
            </div>
            <Typography variant="caption" noWrap >{getParentsDescription(suggestion.parents)}</Typography>
        </div>
    ) : <div></div>;
});

const AddressCheckComponentView = withStyles(styles)(({choice, className, classes, ...props}) => {

    return (
        <span className={`${classes.checkBoxContainer}`}>
            {React.cloneElement(getLocationIcon(choice.level), { className:`${classes.locationIcon}`} )}
            <span className={`${classes.nowrap}`} ></span>
            <Typography variant="body1" noWrap  className={`${classes.ml2}`} >{`${choice.typeShort}. ${choice.name}`}</Typography>
            <Typography variant="caption" noWrap  className={`${classes.ml2}`} >{getParentsDescription(choice.parents)}</Typography>
        </span>
    )
});

export const AddressCheckComponent = (choice) => <AddressCheckComponentView choice={choice} />