import apolloFgisClient from './apolloFgisClient'
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';
import { GET_LIST_SITYPE } from './typeDefs';
import { GET_LIST_CCSVTYPE } from './typeDefs';
import { GET_LIST_SUTYPE } from './typeDefs';
import { GET_LIST_GSITYPE } from './typeDefs';


export const knownResources = ['SIType', 'CCSVType', 'SUType', 'GSIType'];
export const dataProvider = (type, resource, params) => {

  if (resource === 'SIType') {
    switch (type) {
      case GET_LIST: {
        return apolloFgisClient.query({
          query: GET_LIST_SITYPE,
          variables: {
            page: params.pagination.page,
            perPage: params.pagination.perPage,
            // sort: $sort, 
            filter: params.filter
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      case GET_MANY: {
        return apolloFgisClient.query({
          query: GET_LIST_SITYPE,
          variables: {
            filter: {
              id: params.ids[0]
            }
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    };
  }

  if (resource === 'CCSVType') {
    switch (type) {
      case GET_LIST: {
        return apolloFgisClient.query({
          query: GET_LIST_CCSVTYPE,
          variables: {
            page: params.pagination.page,
            perPage: params.pagination.perPage,
            // sort: $sort, 
            filter: params.filter
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      case GET_MANY: {
        return apolloFgisClient.query({
          query: GET_LIST_CCSVTYPE,
          variables: {
            filter: {
              id: params.ids[0]
            }
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    };
  }

  if (resource === 'SUType') {
    switch (type) {
      case GET_LIST: {
        return apolloFgisClient.query({
          query: GET_LIST_SUTYPE,
          variables: {
            page: params.pagination.page,
            perPage: params.pagination.perPage,
            // sort: $sort, 
            filter: params.filter
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      case GET_MANY: {
        return apolloFgisClient.query({
          query: GET_LIST_SUTYPE,
          variables: {
            filter: {
              id: params.ids[0]
            }
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    };
  }

  if (resource === 'GSIType') {
    switch (type) {
      case GET_LIST: {
        return apolloFgisClient.query({
          query: GET_LIST_GSITYPE,
          variables: {
            page: params.pagination.page,
            perPage: params.pagination.perPage,
            // sort: $sort, 
            filter: params.filter
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      case GET_MANY: {
        return apolloFgisClient.query({
          query: GET_LIST_GSITYPE,
          variables: {
            filter: {
              id: params.ids[0]
            }
          },
          fetchPolicy: 'network-only',
          onError: (error) => {
            alert(error)
          }
        }).then(({ data }) => {

          return data.data
        });
        break;
      }
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    };
  }

};

export default {
  dataProvider,
  knownResources,
};