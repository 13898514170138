import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import get from 'lodash/get';
import { getFormValues, FormName, change } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    flex: { display: "flex" },
    spacer: { width: "1em" },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});



export const GSITypeInput = ({ form, formData, change, className, classes, ...props }) => {
    const { gsiType } = formData;
    const gsiTypeId = get(formData, ["gsiTypeId"])
    const [manual, setManual] = useState(false);
    const blocked = !manual || !!gsiTypeId;

    useEffect(() => {
        if (!gsiType) return;

        change(form, 'gsiTypeNumber', gsiType.number);
        change(form, 'gsiTypeName', gsiType.name);
        change(form, 'gsiTypeType', gsiType.type);
    }, [gsiType])

    const handleManual = (event) => {
        if (!manual) {
            change(form, 'gsiTypeId', null);
        }
        setManual(!manual);
    };

    return (
        <div className={classes.root}>
            <div className={classes.flex}>
                <ReferenceInput {...props} className={className} source="gsiTypeId" reference="GSIType" filterToQuery={searchText => ({ number: searchText })} allowEmpty options={{ disabled: !blocked }} >
                    <AutocompleteInput optionText="number" />
                </ReferenceInput>
                <Typography className={classes.message} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                    <span>Это поле получает данные из госреестра ФГИС "АРШИН" СТАНДАРТЫ ГОСУДАРСТВЕННОЙ СИСТЕМЫ ОБЕСПЕЧЕНИЯ ЕДИНСТВА ИЗМЕРЕНИЙ. Если список номеров пуст, введите данные вручную. Дополнительная информация на <a href="https://fgis.gost.ru/fundmetrology/registry/3">https://fgis.gost.ru/fundmetrology/registry/3</a></span>
                </Typography>
            </div>

            <FormControlLabel control={<Checkbox checked={manual} onChange={handleManual} value="manual" color="primary" />} label="Ввести номер документа вручную" />

            <Grid container spacing={16}>
                <Grid item xs={12} sm={4}>
                    <TextInput {...props} disabled={blocked} source="gsiTypeNumber" options={{ fullWidth: true }} />
                    <TextInput {...props} disabled={blocked} source="gsiTypeName" options={{ fullWidth: true }} />
                    <TextInput {...props} disabled={blocked} source="gsiTypeType" options={{ fullWidth: true }} />
                </Grid>
            </Grid>
        </div>


    )
};

const mapStateToProps = (state, { form, record }) => {

    const formData = getFormValues(form)(state) || record;

    const gsiTypeId = get(formData, ["gsiTypeId"])
    const gsiType = gsiTypeId ? get(state, ["admin", "resources", "GSIType", "data", gsiTypeId]) : undefined;

    return {
        formData: {
            ...formData,
            gsiType,
        },
    }
};

const mapDispatchToProps = {
    change,
}






const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
);

const ConnectedFormDataConsumerView = enhance(GSITypeInput);

const FormDataConsumer = (props) => (
    <FormName>
        {({ form }) => <ConnectedFormDataConsumerView form={form} {...props} />}
    </FormName>
);




export default FormDataConsumer;
