import React, { Fragment } from 'react';
import { BulkDeleteButton, Datagrid, DateField, TextField, Filter, List, ListView, ListController, Responsive, SearchInput, SelectInput, translate, } from 'react-admin';
import { Tabs, Tab, Divider, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import compose from 'recompose/compose';
import { exporter } from '../../exporter';
import { ability } from '../../ability';
import BulkArchiveButton from '../Buttons/BulkArchiveButton';
import BulkExportButton from '../Buttons/BulkExportButton';
import MoreButton from '../Buttons/MoreButton';
import PhoneField from '../Fields/PhoneField';
import UserNameWithAvatarField from './UserNameWithAvatarField';
import RoleField from './RoleField';
import rowStyle from './rowStyle';
import { getListRoles } from './roles';
import TitleWithSearch from '../Layout/TitleWithSearch';
import Pagination from '../Layout/Pagination';

// class User {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }


const styles = theme => ({
    textSecondary: {
        color: "rgba(0, 0, 0, 0.54)",
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 6,
        borderLeftStyle: 'solid',
    },
    ml2:{ marginLeft: ".5rem"},
    deleteButton: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
    },
    cellMinWidth:{
        width: '1px',
    }
});



const exportBuilder = (records, fetchRelatedRecords, translate) => { 

    exporter(records, 'User', [
        { field: "id" }, 
        { field: "name" }, 
        { field: "role", translateValues:"resources.User.roles"}, 
        { field: "email"}, 
        { field: "phone"},
        { field: "createdAt", format: 'L'}, 
        { field: "customer.type" , translateValues:"resources.Customer.types"}, 
        { field: "customer.organizationName" }, 
        { field: "customer.organizationInn"}, 
        {field: "customer.payment", translateValues:"resources.Customer.payments"},
    ], translate) 
};

const UserFilter = (props) => (
    <Filter {...props}>
        {/* <SearchInput source="q" alwaysOn /> */}
        <SelectInput source="role" choices={getListRoles()} optionValue="role" optionText={<RoleField />}/>  
    </Filter>
);

const UserTabsView = ({ filterValues, setFilters, translate, classes, className, ...props }) => {
    const handleChange = (event, value) => {
        setFilters({ ...filterValues, tab: value });
    };

    return ( 
        <Fragment>
            <Tabs value={ filterValues.tab } onChange={ handleChange } centered >
            { ability.can('List', { constructor:{name:"User"},tab:"all"}) && <Tab label={translate('resources.User.tabs.all')} value="all" /> }
            { ability.can('List', { constructor:{name:"User"},tab:"customers"}) && <Tab label={translate('resources.User.tabs.customers')} value="customers" /> }
            { ability.can('List', { constructor:{name:"User"},tab:"employees"}) && <Tab label={translate('resources.User.tabs.employees')} value="employees" /> }
            { ability.can('List', { constructor:{name:"User"},tab:"admins"}) && <Tab label={translate('resources.User.tabs.admins')} value="admins" /> }
            { ability.can('List', { constructor:{name:"User"},tab:"archived"}) && <Tab label={translate('resources.User.tabs.archived')} value="archived" /> }
            </Tabs>
            <Divider />
            <Responsive
                small={

                <Datagrid rowClick="show" rowStyle={rowStyle} classes={{ headerRow: classes.headerRow }} {...props}>
                    <UserNameWithAvatarField source="name" />
                    <PhoneField source="phone" noWrap/>
                    <TextField source="email" />
                    <RoleField source="role" />
                    <DateField source="createdAt" textAlign="right" className={classes.textSecondary} showTime  /> 
                    <MoreButton label="" cellClassName={classes.cellMinWidth}/>
                </Datagrid>
                
                }
            />
        </Fragment>
    )
};

const UserTabs = compose(
    translate,
    withStyles(styles)
)(UserTabsView);





const BulkActionToolbarView = withStyles(styles)(({classes, translate, ...props}) => {

    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate) 
    }

    return (
        <Fragment>
            <BulkExportButton variant="outlined" exporter={exportBuilderWithTranslate} {...props}/>
            { ability.can('archive', 'User') && <BulkArchiveButton className={`${classes.ml2}`} {...props} />}
            { ability.can('delete', 'User') && <BulkDeleteButton className={`${classes.deleteButton} ${classes.ml2}`} {...props} />}  
        </Fragment>
    );
});

const BulkActionToolbar = compose(withStyles(styles), translate)(BulkActionToolbarView);


export const UserListView = ({translate, ...props}) => {

    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate) 
    }

    return (
        // <List {...props}
        //     bulkActionButtons={<BulkActionToolbar />}
        //     sort={{field: 'name', order: 'DESC'}}
        //     filters={<UserFilter />}
        //     filterDefaultValues={{ tab: 'customers' }}
        //     exporter={exportBuilderWithTranslate}
        // >
        //     <UserTabs />
        // </List>


        <ListController sort={{field: 'name', order: 'DESC'}} filterDefaultValues={{ tab: 'customers' }} {...props}>
            {controllerProps => (
                    <ListView 
                        title={<TitleWithSearch {...controllerProps} />}
                        bulkActionButtons={<BulkActionToolbar />} 
                        filters={<UserFilter />}
                        pagination={<Pagination />}
                        exporter={exportBuilderWithTranslate} 
                        {...props} 
                        {...controllerProps}
                    >
                        <UserTabs />   
                    </ListView>
                )
            }
        </ListController>

    );
}; 

const UserList = compose(translate)(UserListView);

export default UserList;


