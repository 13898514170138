import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudUpdate, SaveButton, Toolbar } from 'react-admin';
import ProcessIcon from '@material-ui/icons/HeadsetMic';

// A custom action creator which modifies the values before calling the default crudCreate action creator
const saveWithProcess = (values, record, resource, basePath, redirectTo) =>
    crudUpdate(resource, record.id, { ...values, statusRef: "processed" }, record, basePath, redirectTo);

class SaveWithProcessButtonView extends Component {
    handleClick = () => {
        const { resource, record, basePath, handleSubmit, redirect, saveWithProcess } = this.props;

        return handleSubmit(values => {
            saveWithProcess(values, record, resource, basePath, redirect);
        });
    };

    render() {
        const { handleSubmitWithRedirect, saveWithProcess, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                label="raExt.action.process"
                icon={<ProcessIcon />}
                {...props}
            />
        );
    }
}

const SaveWithProcessButton = connect(
    undefined,
    { saveWithProcess }
)(SaveWithProcessButtonView);


export default SaveWithProcessButton;