import React from 'react';
import { ExportButton } from 'react-admin';


const BulkExportButton = ({ selectedIds, filterValues, ...props }) => {
    const filter = {
        ...filterValues,
        ids: selectedIds,
    }
    return ( <ExportButton  {...props } sort={{field: "createdAt", order: "ASC"}} filter={filter} />);
}



export default BulkExportButton;