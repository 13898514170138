import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { crudUpdate, startUndoable, SaveButton, translate, showNotification } from 'react-admin';
import CompleteIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import { SubmissionError } from 'redux-form';

const validate = (values, translate) => {
    const errors = {};

    if (!values.resultRef) errors.resultRef = translate('resources.Order.errors.resultRef');
    if (!values.invoice && values.isSealNotInstalled === false) errors.invoice = translate('resources.Order.errors.invoice');
    if (values.files < 1) errors.files = 'resources.Order.errors.files';
    
  
    return Object.keys(errors).length !== 0 ? errors : undefined;
};


const saveWithComplete = (values, record, resource, basePath, redirectTo) =>
    crudUpdate(resource, record.id, { ...values, statusRef: "completed" }, record, basePath, redirectTo);

class SaveWithCompleteButton extends Component {
    handleClick = () => {
        const { resource, record, basePath, handleSubmit, redirect, startUndoable, showNotification, translate } = this.props;
       
        return handleSubmit(values => {
            
            return new Promise((resolve, reject) => {
                const errors = validate(values, translate);
                if (errors) {
                    showNotification('ra.message.invalid_form', 'warning');
                    reject(new SubmissionError(errors));
                } else {
                    resolve(
                        startUndoable(
                            saveWithComplete(values, record, resource, basePath, redirect)
                        )
                    );
                }
            });

            
        });
    };

    render() {
        const { handleSubmitWithRedirect, startUndoable, showNotification, translate, ...props } = this.props;
        
        if (this.props.disabled) {
            return ( 
            <Tooltip title={translate('resources.Order.helper.changeResult')} placement="top">
                <div>
                <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                label="raExt.action.complete"
                icon={<CompleteIcon />}
                {...props}
            />
                </div>
            </Tooltip> );
        } else {
            return ( 
                <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                label="raExt.action.complete"
                icon={<CompleteIcon />}
                {...props}
            /> );
        };
    }
}






const mapDispatchToProps = {
    startUndoable,
    showNotification,
}

const enhance = compose(
    connect( undefined, mapDispatchToProps),
    translate,
);


export default enhance(SaveWithCompleteButton);

