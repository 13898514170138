import React from 'react';
import {
    translate,
    required,
    List, ListView, ListController,
    Create,
    Show,
    SelectInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    Edit,
    SearchInput,
    SimpleShowLayout,
    SimpleForm,
    Datagrid,
    TextField,
    TextInput,
    LongTextInput,
    Filter
} from 'react-admin';
import { ability } from '../../ability'
import Icon from '@material-ui/icons/DevicesOther';
import { exporter } from '../../exporter';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, FileField, SaveButton, DeleteButton } from 'react-admin';
import MeterField from './MeterField'
import { OptionMeter } from './MeterField'

import EnumInput from '../Inputs/EnumInput';
import ArrayMapField from '../Fields/ArrayMapField';
import FileChip from '../Fields/FileChip';
import VerifyIntervalField from './VerifyIntervalField';
import FileInput from '../Inputs/FileInput';
import TitleWithSearch from '../Layout/TitleWithSearch';

import VerifyIntervalInput from './VerifyIntervalInput';
import Pagination from '../Layout/Pagination';



export const MeterIcon = Icon;

const styles = theme => ({
    mr2: { marginRight: ".5rem" },
    mlAuto: { marginLeft: "auto" },
    removeButton: {
        display: "flex",
        alignItems: "center"
    }
});

const exportBuilder = (records, fetchRelatedRecords, translate) => {
    exporter(records, 'Meter', [
        { field: "id" },
        { field: "type", translateValues: "resources.Meter.enums.type" },
        { field: "name" },
        { field: "description" },
        { field: "verifyInterval" },
    ], translate)
};

export const MeterList = translate(({ translate, ...props }) => {
    let isbulkActionView = ability.can('delete', 'Meter') ? null : { bulkActionButtons: false };

    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate)
    }

    return (
        // <List {...props}
        //     {...isbulkActionView}
        //     filters={<MeterFilter />}
        //     exporter={exportBuilderWithTranslate}>
        //     <Datagrid rowClick="show">
        //         <MeterField source="name" />
        //         <TextField source="description" />
        //         <VerifyIntervalField source="verifyInterval" />
        //         <ArrayMapField source="documentation">
        //             <FileChip title="desc" />
        //         </ArrayMapField>
        //     </Datagrid>
        // </List>


        <ListController {...props}>
            {controllerProps => (
                <ListView
                    title={<TitleWithSearch {...controllerProps} />}
                    {...isbulkActionView}
                    filters={<MeterFilter />}
                    exporter={exportBuilderWithTranslate}
                    pagination={<Pagination />}
                    {...props}
                    {...controllerProps}
                >
                    <Datagrid rowClick="show">
                        <MeterField source="name" />
                        <TextField source="description" />
                        <VerifyIntervalField source="verifyInterval" />
                        <ArrayMapField source="documentation">
                            <FileChip title="desc" />
                        </ArrayMapField>
                    </Datagrid>
                </ListView>
            )
            }
        </ListController>
    );
});

export const MeterShow = (props) => (
    <Show {...props} title={<OptionMeter />}>
        <SimpleShowLayout>
            <MeterField source="name" />
            <TextField source="description" />
            <VerifyIntervalField source="verifyInterval" />
            <ArrayMapField source="documentation" empty="raExt.notification.empty">
                <FileChip canOpen source="url" title="desc" />
            </ArrayMapField>
        </SimpleShowLayout>
    </Show>
);

export const MeterEdit = props => (
    <Edit {...props} title={<OptionMeter />}>
        <SimpleForm toolbar={<EditToolbar />}  >
            <EnumInput source="type" Enum="MeterType" validate={required()} />
            <TextInput source="name" validate={required()} />
            <VerifyIntervalInput source="verifyInterval" allowEmpty emptyValue={null} />
            <LongTextInput source="description" />
            <FileInput source="documentation" multiple={true}>
                <FileChip canOpen source="url" title="desc" />
            </FileInput>
        </SimpleForm>
    </Edit>
);

export const MeterCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CreateToolbar />}>
            <EnumInput source="type" Enum="MeterType" validate={required()} />
            <TextInput source="name" validate={required()} />
            <VerifyIntervalInput source="verifyInterval" allowEmpty emptyValue={null} />
            <LongTextInput source="description" />
            <FileInput source="documentation" multiple={true}>
                <FileChip canOpen source="url" title="desc" />
            </FileInput>
        </SimpleForm>
    </Create>
);

const MeterFilter = (props) => (
    <Filter {...props}>
        {/* <SearchInput source="q" alwaysOn /> */}
    </Filter>
);

export const EditToolbar = withStyles(styles)(({ classes, ...props }) => {

    return (
        <Toolbar {...props} >
            { ability.can("create", "Meter") && (
                <SaveButton
                    className={`${classes.mr2}`}
                    label="ra.action.save"
                    redirect="list"
                    submitOnEnter={true}
                />
            )}
            { ability.can("delete", "Meter") && (
                <DeleteButton
                    basePath={props.basePath}
                    record={props.record}
                    resource={props.resource}
                    className={`${classes.deleteButton} ${classes.mr2}`}
                />
            )}
        </Toolbar>
    )
});



const CreateToolbar = withStyles(styles)(({ classes, ...props }) => {
    return (
        <Toolbar {...props} >
            { ability.can("create", "Meter") && (
                <SaveButton className={`${classes.mr2}`} label="ra.action.create" redirect="list" submitOnEnter={true} />
            )}
            { ability.can("create", "Meter") && (
                <SaveButton label="raExt.action.create_and_continue" redirect={false} submitOnEnter={false} variant="outlined" />
            )}
        </Toolbar>
    );
});
