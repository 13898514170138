import gql from 'graphql-tag'
import { apolloNotificationsClient } from './graphqlProvider';

const REGISTER_SUBSCRIBER = gql`
    mutation registerSubscriber($filter: NotificationFilter) {
        data: registerSubscriber(filter: $filter) {
            id
            clientId
            role
            filter
            lastVizitAt
            createdAt
            updatedAt
        }
    }
`;


export default (args) => {
    return apolloNotificationsClient.mutate({
        mutation: REGISTER_SUBSCRIBER,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
