import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FavoriteIcon from '@material-ui/icons/Star';
import Loading from "./Loading";
import { Scrollbars } from 'react-custom-scrollbars';
import { withDataProvider, GET_LIST, showNotification } from 'react-admin';
import InfoBanner from '../Layout/InfoBanner';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const styles = theme => ({
    root:{
        flexGrow: 1,
        minWidth: 480,
        minHeight: 408,
        overflow: "hidden", 
    },
    list: {
    
    },
    favoriteIcon: {
        color: theme.palette.secondary.main,
    },
});

const WidgetAnalitics = withStyles(styles)(({ dataProvider, dispatch, classes, className, ...props }) => {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        dataProvider( GET_LIST, "Order", {
            pagination: {page: 1, perPage: 10},
            sort:{field: 'updatedAt', order: 'DESC'},
            filter: {
                tab: "favorite",
            },
        }).then(async ({data, total}) => {
            setOrders(data);
            setLoading(false);
        })
        .catch((e) => {
            dispatch(showNotification(e.message, 'warning'));
        });

        return () => {
           setOrders([]);
        };

    },[]);

    return (
        <Paper className={`${classes.root} ${className}`} >
        {(loading)
        ? <Loading />
        : (!orders.length) 
            ? <InfoBanner
                title="Заявок нет"
                subtitle="Добавьте заявки в Избранное и они появятся тут"
            >
                <StarBorderIcon color="disabled" style={{fontSize:48}}/>
            </InfoBanner>
            : (<Scrollbars autoHide>
                <List >
                {orders.map(order => (
                    <ListItem key={order.id} button component="a" href={`#/Order/${order.id}/show`}>
                    <ListItemIcon>
                        <FavoriteIcon className={classes.favoriteIcon}/>
                    </ListItemIcon>
                    <ListItemText primary={order.addrManual} primaryTypographyProps={{variant: "body1"}} /*secondary={order.humanId}*/ />
                    </ListItem>
                ))}
                </List>
            </Scrollbars>)
        }
        </Paper>
    );

});


export default withDataProvider(WidgetAnalitics);


