import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate } from 'ra-core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsDrawer from './NotificationsDrawer';
import { closeNotification } from '../../redux/notifications/actions';
import { ability } from '../../ability'

const NotificationsButton = ({ notifications, closeNotification, translate, classes, ...props}) => {

    if (!ability.can('read', "Notification")) { return null };
    
    const [open, setOpen] = useState(false);
    
    const handleClick = () => {
        closeNotification();
        setOpen(true);
    };
 
    const getIcon = (notifications) => {
        
        const count = notifications.length;
        const haveUnconfirmed = notifications.some(notification => {

            return !notification.dismissed
        });
        
        if (count > 0) {
            if (haveUnconfirmed) {
                return {
                    title: translate('resources.Notification.you_have_unconfirmed_notifications', { _: 'you_have_unconfirmed_notifications' }),
                    icon: <NotificationsActiveIcon />,
                };
            } else {
                return {
                    title: translate('resources.Notification.show_notifications', { _: 'show_notifications' }),
                    icon: <NotificationsIcon />,
                };
            };
        } else {
            return {
                title: translate('resources.Notification.no_notifications', { _: 'no_notifications' }),
                icon: <NotificationsNoneIcon />,
            };
        };
    }

    const  {icon, title} = getIcon(notifications);
    
    return (
        <>
            <Tooltip title={title} disableFocusListener={true}>
                <IconButton aria-label='notifications' color="inherit" onClick={handleClick} >
                    {icon}
                </IconButton>
            </Tooltip>
            <NotificationsDrawer open={open} setOpen={setOpen} />
        </>
    );
}

const mapStateToProps = state => {
    return { 
        notifications: state.notifications.items,
    }
};

export default compose(
    translate,
    connect(
        mapStateToProps,
        {
            closeNotification,
        },
    )
)( NotificationsButton );
