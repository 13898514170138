import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
//import { Link } from "react-admin";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import EditIcon from '@material-ui/icons/Create';
import CloneIcon from '@material-ui/icons/Queue';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DeleteIcon from '@material-ui/icons/Delete';


import { ability } from '../../ability'

import { linkToRecord, translate, crudDelete, startUndoable } from 'ra-core';
import { archive, unarchive } from '../Actions/ArchiveAction';

const omitId = ({ id, statusRef, ...rest }) => rest;

const styles = theme => ({
    menuItem: {
        color: theme.palette.grey[700],
       
    },
    deleteItem: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    menuText: {
        flex: '1 1 auto',
        paddingLeft: '0.5rem',
        minWidth: '0'
    },
    menuIcon: {
        display: 'inline-flex',
        flexShrink: '0',
    },
});



class MoreButton extends React.Component {
    state = {
      anchorEl: null,
    };
  
    handleClick = event => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClose = event => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };
  
    handleDelete = event => {
        event.stopPropagation();
        event.preventDefault();     
        this.setState({ anchorEl: null });
        const { startUndoable, resource, record, basePath, redirect = false,} = this.props;

        startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
    };

    handleArchive = event => {
        event.stopPropagation();
        event.preventDefault();     
        this.setState({ anchorEl: null });
        const { startUndoable, resource, record, basePath, redirect = false, } = this.props;

        startUndoable(archive(resource, record.id, record, basePath, redirect));
    };

    handleUnarchive = event => {
        event.stopPropagation();
        event.preventDefault();     
        this.setState({ anchorEl: null });
        const { startUndoable, resource, record, basePath, redirect = false, } = this.props;

        startUndoable(unarchive(resource, record.id, record, basePath, redirect));
    };
    

    render() {
        const { anchorEl } = this.state;
        const { basePath, resource, record, translate, classes = {} } = this.props;

        return (
            <div>
                <Tooltip placement="top" title={translate('raExt.action.more')} >
                    <IconButton
                        aria-owns={anchorEl ? 'more-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            
                <Menu
                    id="more-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >         
                { ability.can('update', resource) && (
                    <MenuItem component={Link} to={`${linkToRecord(basePath, record.id)}`} onClick={this.handleClose} className={classes.menuItem} >
                        <EditIcon className={classes.menuIcon}/><span className={classes.menuText}>{translate('ra.action.edit')}</span>
                    </MenuItem>
                )}
                { ability.can('create', resource) && (
                    <MenuItem component={Link} to={{ pathname: `${basePath}/create`, state: { record: omitId(record) } }} onClick={this.handleClose} className={classes.menuItem} >
                        <CloneIcon className={classes.menuIcon}/><span className={classes.menuText}>{translate('ra.action.clone')}</span>
                    </MenuItem>
                )}
                { ability.can('archive', resource) && !record.isArchive && (
                    <MenuItem className={classes.deleteItem} onClick={this.handleArchive} >
                        <ArchiveIcon className={classes.menuIcon}/><span className={classes.menuText}>{translate("raExt.action.archive")}</span>
                    </MenuItem>
                )}
                { ability.can('archive', resource) && record.isArchive && (
                    <MenuItem className={classes.menuItem} onClick={this.handleUnarchive} >
                        <UnarchiveIcon className={classes.menuIcon}/><span className={classes.menuText}>{translate("raExt.action.unarchive")}</span>
                    </MenuItem>
                )}
                { ability.can('delete', resource) && (
                    <MenuItem className={classes.deleteItem} onClick={this.handleDelete} >
                        <DeleteIcon className={classes.menuIcon}/><span className={classes.menuText}>{translate('ra.action.delete')}</span>
                    </MenuItem>
                )}
                </Menu>
            </div>
        ) 
    }
}
  

export default compose(
    connect(
        null,
        { startUndoable, dispatchCrudDelete: crudDelete }
    ),
    translate,
    withStyles(styles)
)(MoreButton);
