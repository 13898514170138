import React, { useEffect } from 'react';
import decodeJwt from 'jwt-decode'
import { useSubscription } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import blue from '@material-ui/core/colors/blue';
import { UserMenu, MenuItemLink, } from 'react-admin';
import apolloCoreClient from '../../dataProviders/Core/apolloCoreClient';
import { SUBSCRIBE_ONLINE_USERS } from '../../dataProviders/Core/onlineUsersSubscription';
import { AUTH_TOKEN } from '../../appsettings';
import { setUser } from '../../redux/user/actions';
import { setOnlineUsers } from '../../redux/onlineUsers/actions';
import getUserByAuth from '../../dataProviders/Core/getUserByAuth';
import getOnlineUsers from '../../dataProviders/Core/getOnlineUsers';
import RoleField from '../User/RoleField';



const styles = theme => ({
    root: {
        fontSize: "1rem",
        height: 32,
        width: 32,
    },
    colorDefault: {
        color: theme.palette.common.white,
        backgroundColor: blue[400],
    }
});

const getInitials = name => {
    var initials = name.split(" ") || [];
    var first = initials.shift()
    var second = initials.pop()
    initials = (first.charAt(0) + (second ? second.charAt(0) : first.charAt(1))).toUpperCase();
    return initials;
}


const Ava = withStyles(styles)(({ user, classes, ...props }) => {

    if (!user) return <Avatar classes={{ root: classes.root, colorDefault: classes.colorDefault }} ><AccountCircle /></Avatar>

    if (!!user.avatar) return <Avatar classes={{ root: classes.root, colorDefault: classes.colorDefault }} >{getInitials(user.name)}</Avatar>

    return <Avatar classes={{ root: classes.root, colorDefault: classes.colorDefault }} >{getInitials(user.name)}</Avatar>

});



const MyUserMenu = ({ profile, user, setUser, onlineUsers, setOnlineUsers, ...props }) => {

    useEffect(() => {

        async function fetchData() {
            try {

                const _onlineUsers = await getOnlineUsers();
                const onlineUsersIds = _onlineUsers.data.onlineUsers.map(onlineUser => onlineUser.id)

                setOnlineUsers(onlineUsersIds)


            } catch (error) {
                console.log('error: ', error)
            }
        }
        fetchData();

        return () => {
            setOnlineUsers([]);
        };

    }, []);

    useEffect(() => {

        async function fetchData() {
            try {
                const decodedToken = decodeJwt(localStorage.getItem(AUTH_TOKEN))
                const userByAuthId = await getUserByAuth({ authId: decodedToken.userId });

                setUser(userByAuthId.data.UserByAuthId)

            } catch (error) {
                console.log('error: ', error)
            }
        }
        fetchData();

        return () => {
            setUser(null)
        };

    }, []);

    useEffect(() => {
        //const a = profile === user

        if (profile) {
            setUser(profile)
        }
    }, [profile]);

    const onSubscriptionData = ({ client, subscriptionData: { data: { onlineUsers: { node } } } }) => {

        if (node.online) {

            if (!onlineUsers.some(id => id === node.id)) {
                setOnlineUsers([...onlineUsers, node.id]);
            }
        } else {

            const filterArray = onlineUsers.filter(id => id !== node.id);
            setOnlineUsers(filterArray);
        };
    };

    const onlineUserSubscription = useSubscription(SUBSCRIBE_ONLINE_USERS, {
        client: apolloCoreClient,
        onSubscriptionData: onSubscriptionData,
    }
    );

    return (
        <UserMenu icon={<Ava user={user} />} {...props}>
            {!!user && (
                <MenuItemLink
                    style={{ height: "unset" }}
                    to={`/Profile`}
                    primaryText={(
                        <div>
                            <Typography variant="subheading" >{user.name}</Typography>
                            <Typography variant="caption" ><RoleField variant="caption" record={user} /></Typography>

                        </div>
                    )}
                    leftIcon={<AccountCircle />
                    }
                />
            )}
        </UserMenu>
    );
}


const mapStateToProps = state => {
    return {
        user: state.user,
        onlineUsers: state.onlineUsers,
        profile: (state.admin.resources.Profile && state.user) ? state.admin.resources.Profile.data[state.user.id] : undefined,
    }
};

export default connect(mapStateToProps, {
    setUser,
    setOnlineUsers,
})(MyUserMenu);


