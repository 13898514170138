import React, { Fragment } from 'react';
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    DeleteButton,
    Edit,
    ShowButton,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    ReferenceArrayInput,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    required,
    minLength,
    email,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ability } from '../../ability'
import { AddressSuggestionComponent, AddressCheckComponent } from '../Order/AddressComponents';
import CheckBoxGroupInputWithCheckAll from '../Inputs/CheckBoxGroupInputWithCheckAll';
import TypeField from '../Customer/TypeField';
import PaymentField from '../Customer/PaymentField';
import PhoneInput from '../Inputs/PhoneInput';
import ArchiveButton from '../Buttons/ArchiveButton';
import BanButton from '../Buttons/BanButton';
import CreateRelatedOrderButton from '../Buttons/CreateRelatedOrderButton';
import FastView from './FastView';
import UserTitle from './Title';


const arrTypes = [
    { type: 'PHIS' },
    { type: 'JURE' },
];

const arrPayments = [
    { payment: 'CASH' },
    { payment: 'CASHLESS' },
];

// class User {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }

const styles = theme => ({
    mr2: { marginRight: ".5rem" },
    mlAuto: { marginLeft: "auto" },
    deleteButton: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
    contaiter: {
        zIndex: 2,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
});


const validateName = [required(), minLength(2)];
const validateEmail = [email()];


export const EditLayoutView = ({ classes, ...props }) => {
    const { record } = props;


    return (
        <Fragment>
            <FastView {...props} />
            <TabbedForm toolbar={<EditToolbar />} {...props} >
                <FormTab label="resources.User.tabs.general" >
                    <TextInput source="name" fullWidth validate={validateName} />
                    {/* <TextInput source="phone" validate={validatePhone} inputProps={{ autocomplete: "new-password"}} /> */}
                    <PhoneInput source="phone" inputProps={{ autoComplete: "new-password" }} />
                    <TextInput source="email" type="email" validate={validateEmail} inputProps={{ autoComplete: "new-password" }} />
                    <TextInput source="password" type="password" inputProps={{ autoComplete: "new-password" }} />
                </FormTab>
                {(record.role === 'CUSTOMER') && (
                    <FormTab label="resources.User.roles.CUSTOMER" path="customer">
                        <SelectInput key="type" source="customer.type" choices={arrTypes} optionValue="type" optionText={<TypeField />} validate={required()} />
                        <FormDataConsumer style={{ display: "flex", flexDirection: "column" }}>{
                            ({ formData, ...rest }) => {
                                if (!!formData.customer && (formData.customer.type === undefined || formData.customer.type === "JURE")) {
                                    return [
                                        <TextInput key="organizationName" source="customer.organizationName" {...rest} />,
                                        <TextInput key="organizationInn" source="customer.organizationInn" {...rest} />,
                                        <SelectInput key="payment" source="customer.payment" choices={arrPayments} optionValue="payment" optionText={<PaymentField />}  {...rest} />,
                                    ];
                                }
                            }}
                        </FormDataConsumer>
                    </FormTab>)}
                <FormTab label="resources.User.tabs.accessLocation" path="accessLocation">
                    {ability.can('update', 'User', 'counterparty') && <ReferenceInput source="counterparty.id" reference="Counterparty" sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ name: searchText })} label="resources.User.fields.counterparty" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>}
                    {ability.can('update', "User", "regionIds") && (
                        <ReferenceArrayInput source="regionIds" reference="Location" filter={{ type: 'REGION' }} options={{ fullWidth: true }}>
                            <AutocompleteArrayInput suggestionComponent={AddressSuggestionComponent} optionText={choice => `${choice.name} ${choice.typeShort}`} />
                        </ReferenceArrayInput>
                    )}
                    {ability.can('update', "User", "districtIdOrCityIds") && (
                        <FormDataConsumer>{
                            ({ formData, className, ...rest }) =>
                                <ReferenceArrayInput key={formData.regionIds} source="districtIdOrCityIds" reference="Location" filter={{ type: 'DISTRICT', parentIds: !!formData.regionIds ? formData.regionIds : [] }} perPage={1000} {...rest} >
                                    <CheckBoxGroupInputWithCheckAll optionText={AddressCheckComponent} />
                                </ReferenceArrayInput>
                        }
                        </FormDataConsumer>
                    )}
                </FormTab>
            </TabbedForm>
        </Fragment>
    )
};

const UserEditActions = withStyles(styles)(({ classes, className, basePath, data, resource }) => {
    if (!data) return null;
    return (
        <CardActions className={`${classes.contaiter}`}>
            { ability.can("Create", "Order") && <CreateRelatedOrderButton basePath={basePath} record={data} className={`${classes.mr2}`} />}
            { ability.can("Edit", { constructor: { name: "User" }, role: data.role }) && <ShowButton basePath={basePath} record={data} />}
        </CardActions >
    )
});

const UserEdit = props => {
    return (
        <Edit actions={<UserEditActions />} title={<UserTitle />} {...props} >
            <EditLayoutView />
        </Edit>
    );
}

export const EditToolbar = withStyles(styles)(({ classes, submitOnEnter, ...props }) => {
    const { record } = props;

    return (
        <Toolbar {...props} >
            { ability.can("update", { constructor: { name: "User" }, role: record.role }) && !record.isArchive && (
                <SaveButton className={`${classes.mr2}`} redirect="show" submitOnEnter={true} />
            )}
            { ability.can("archive", { constructor: { name: "User" }, role: record.role }) && (
                <ArchiveButton record={props.record} resource={props.resource} className={`${classes.mlAuto} ${classes.mr2}`} />
            )}
            { ability.can("delete", { constructor: { name: "User" }, role: record.role }) && (
                <BanButton record={props.record} resource={props.resource} className={`${classes.mr2}`} />
            )}
            { ability.can("delete", { constructor: { name: "User" }, role: record.role }) && (
                <WrappedDeleteButton className={classes.deleteButton} />
            )}
        </Toolbar>
    )
});

const WrappedDeleteButton = ({ submitOnEnter, ...props }) => (<DeleteButton {...props} />)

export default UserEdit;