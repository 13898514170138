import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import get from 'lodash/get';
import { getFormValues, FormName, change } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    flex: { display: "flex" },
    spacer: { width: "1em" },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});



export const SUTypeInput = ({ form, formData, change, className, classes, ...props }) => {
    const { suType } = formData;
    const suTypeId = get(formData, ["suTypeId"])
    const counterpartyName = get(formData, ["verifier", "counterparty", "name"])
    const [manual, setManual] = useState(false);
    const blocked = !manual || !!suTypeId;



    useEffect(() => {
        if (!suType) return;

        change(form, 'suTypeName', suType.name);
        change(form, 'suTypeNumber', suType.number);
    }, [suType])

    const handleManual = (event) => {
        if (!manual) {
            change(form, 'suTypeId', null);
        }
        setManual(!manual);
    };

    return (
        <div className={classes.root}>
            <div className={classes.flex}>
                <ReferenceInput {...props} className={className} source="suTypeId" reference="SUType" filter={{ nameOrg: counterpartyName }} filterToQuery={searchText => ({ number: searchText })} allowEmpty options={{ disabled: !blocked }} >
                    <AutocompleteInput optionText="number" />
                </ReferenceInput>
                <Typography className={classes.message} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                    <span>Это поле получает данные из госреестра ФГИС "АРШИН" ЭТАЛОНЫ ЕДИНИЦ ВЕЛИЧИН, согласно наименованию контрагента Поверителя. Если список номеров пуст, проверьте наименование контрагента Поверителя или введите данные вручную. Дополнительная информация на <a href="https://fgis.gost.ru/fundmetrology/registry/11">https://fgis.gost.ru/fundmetrology/registry/11</a></span>
                </Typography>
            </div>

            <FormControlLabel control={<Checkbox checked={manual} onChange={handleManual} value="manual" color="primary" />} label="Ввести номер эталона по реестру вручную" />

            <Grid container spacing={16}>
                <Grid item xs={12} sm={4}>
                    <TextInput {...props} disabled={blocked} source="suTypeNumber" options={{ fullWidth: true }} />
                    <TextInput {...props} disabled={blocked} source="suTypeName" options={{ fullWidth: true }} />
                </Grid>
            </Grid>
        </div>


    )
};

const mapStateToProps = (state, { form, record }) => {

    const formData = getFormValues(form)(state) || record;

    const suTypeId = get(formData, ["suTypeId"])
    const suType = suTypeId ? get(state, ["admin", "resources", "SUType", "data", suTypeId]) : undefined;

    return {
        formData: {
            ...formData,
            suType,
        },
    }
};

const mapDispatchToProps = {
    change,
}






const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
);

const ConnectedFormDataConsumerView = enhance(SUTypeInput);

const FormDataConsumer = (props) => (
    <FormName>
        {({ form }) => <ConnectedFormDataConsumerView form={form} {...props} />}
    </FormName>
);




export default FormDataConsumer;
