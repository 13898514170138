import React from 'react';
import { ReferenceField, FunctionField } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const AddressField = ({ record, ...props }) => (
    <div>
        <Typography>{`${(!!record)? record.addrManual:""}`}</Typography>
        {(record.districtIdOrCityId !== record.districtId) && (
        <ReferenceField  {...props} record={record} source="districtId" reference="Location" linkType={false}>
            <FunctionField render={record => (<Typography variant="caption" >{`• ${record.unrestrictedName}`}</Typography>)} />          
        </ReferenceField >
        )}
    </div>
);


AddressField.defaultProps = {
    addLabel: true,
};


export default AddressField;
