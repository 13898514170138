import buildGraphQLProvider from 'ra-data-graphql-simple';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities'
import { GetAccessToken } from '../authProvider';
import { URI_DATA, WS_DATA } from '../../appsettings';
import coreBuiltQuery from './builtQuery';

const httpLink = createHttpLink({
  uri: URI_DATA
})

const authLink = setContext(async (_, { headers }) => {
  const token = await GetAccessToken();
  //console.log('++++> ['+URI_DATA+'] header get TOKEN from localStorage ('+token+')');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const wsLink = new WebSocketLink({
  uri: WS_DATA,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await GetAccessToken();

      return {
        authorization: token ? `Bearer ${token}` : "",
      }
    },
  }
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink),
)

export const apolloCoreClient = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export const resourcebindingCore = ['Counterparty', 'User', 'Order', 'File', 'Meter', 'MeterLocation', 'Statistic', 'Status', 'Result', 'OrderHistory', 'Profile', 'VersionsInfo']
export default () => buildGraphQLProvider({
  client: apolloCoreClient,
  buildQuery: coreBuiltQuery(apolloCoreClient)
})

