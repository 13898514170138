import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { GetAccessToken } from '../../dataProviders/authProvider';

const sanitizeRestProps = ({
    addLabel,
    allowEmpty,
    basePath,
    cellClassName,
    className,
    formClassName,
    headerClassName,
    label,
    linkType,
    locale,
    record,
    resource,
    sortable,
    sortBy,
    source,
    textAlign,
    translateChoice,
    ...rest
}) => rest;

const styles = {
    root: { display: 'inline-block' },
};

export const TokenFileField = ({
    classes = {},
    className,
    record,
    source,
    title,
    src,
    target,
    ...rest
}) => {
    const sourceValue = get(record, source);

    if (!sourceValue) {
        return (
            <div style={{ display: 'inline-block' }}
                className={classnames(classes.root, className)}
                {...sanitizeRestProps(rest)}
            />
        );
    }



    if (Array.isArray(sourceValue)) {
        return (
            <ul style={{ display: 'inline-block' }}
                className={classnames(classes.root, className)}
                {...sanitizeRestProps(rest)}
            >
                {sourceValue.map((file, index) => {
                    const titleValue = get(file, title) || title;
                    const srcValue = get(file, src) || title;

                    return (
                        <li key={index}>
                            <a
                                //href={srcValue}
                                href="javascript:void(0);"
                                title={titleValue}
                                target={target}
                                onClick={async (event) => {
                                    const TOKEN = await GetAccessToken();
                                    window.open(srcValue.indexOf('blob:') < 0 ? `${srcValue}?${TOKEN}` : srcValue, '_blank');
                                }}
                            >
                                {titleValue}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    const titleValue = get(record, title) || title;

    return (
        <div style={{ display: 'inline-block' }}
            className={classnames(classes.root, className)}
            {...sanitizeRestProps(rest)}>

            <a href="javascript:void(0);" title={titleValue} onClick={async (event) => {
                const TOKEN = await GetAccessToken();
                window.open(sourceValue.indexOf('blob:') < 0 ? `${sourceValue}?${TOKEN}` : sourceValue, '_blank');
            }} >
                {titleValue}
            </a>
        </div>
    );
};

TokenFileField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    src: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
};

export default withStyles(styles)(TokenFileField);