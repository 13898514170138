import React from 'react';
import { Route } from 'react-router-dom';
import Profile from './components/Profile/ProfileEdit';
//import GuestOrderCreate from './components/GuestPage/GuestOrderCreate';
import AboutApp from './components/AboutApp';


export default [
    <Route key="Profile" path="/Profile" component={Profile.edit} />,
    // <Route key="GuestOrderCreate" path="/order_create" noLayout component={GuestOrderCreate} />,
    <Route key="AboutApp" path="/About" component={AboutApp} />
];