import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { AUTH_TOKEN } from '../../appsettings'
import TextIcon from '../Icons/DocumentBox';
import WordIcon from '../Icons/WordBox';
import ExcelIcon from '../Icons/ExcelBox';
import PdfIcon from '../Icons/PdfBox';
import ImageIcon from '@material-ui/icons/Image';
import ZipIcon from '../Icons/ZipBox';
import get from 'lodash/get';
import { GetAccessToken } from '../../dataProviders/authProvider';

const styles = theme => ({
    root: {
        maxWidth: theme.spacing.unit * 32,
        margin: ".125rem",
        backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    label: {
        display: "block",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    avatar: {
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",

    },
    avatarChildren: {
        width: '1em',
        height: '1em',
    }
});


const getIcon = ext => {

    switch (ext) {
        case 'doc':
        case 'docm':
        case 'docx':
            return (<WordIcon style={{ color: "#366DBC" }} />)
            break;

        case 'pdf':
            return (<PdfIcon style={{ color: "#E21E00" }} />)
            break;

        case 'text':
        case 'txt':
            return (<TextIcon style={{ color: "#576D7E" }} />)
            break;

        case 'xls':
        case 'xlsb':
        case 'xlsm':
        case 'xlsx':
            return (<ExcelIcon style={{ color: "#1D7E44" }} />)
            break;

        case '7z':
        case 'rar':
        case 'zip':
        case 'zipx':
            return (<ZipIcon style={{ color: "#FDCC77" }} />)
            break;

        case 'jpg':
        case 'svg':
        case 'png':
            return (<ImageIcon style={{ color: "#EB5757" }} />)
            break;

        default:
            return (<TextIcon />)
            break;

    }
}



const FileChip = withStyles(styles)(({ className, classes, record, source, title, canOpen, onDelete, ...props }) => {
    if (!record) return null;
    const label = get(record, title) || title;
    const url = get(record, source) || source;

    const handleClick = async (event) => {
        event.stopPropagation();
        const TOKEN = await GetAccessToken();
        window.open(url.indexOf('blob:') < 0 ? `${url}?${TOKEN}` : url)
    };

    const openProps = (canOpen) ? { clickable: true, onClick: handleClick } : {}



    return (
        <Tooltip title={label} placement="top" enterDelay={800}>
            <Chip {...openProps} onDelete={onDelete} avatar={<Avatar>{getIcon(record.ext)}</Avatar>} label={label} classes={{ root: classes.root, avatar: classes.avatar, avatarChildren: classes.avatarChildren, label: classes.label }} className={className} />
        </Tooltip>
    );
});


FileChip.defaultProps = {
    canOpen: false,
    addLabel: true,
};



export default FileChip;