import React, { Fragment  } from 'react';
import compose from 'recompose/compose';
import { ReferenceInput,  AutocompleteInput, translate } from 'react-admin';

import { ability } from '../../ability';

import CheckBoxGroupInputWithCheckAll from '../Inputs/CheckBoxGroupInputWithCheckAll';


// const abilityq = ability
// let tags = [];
// const rulesWithConditions =  ability.rulesFor("read", "Notification").filter(rule => rule.conditions && rule.conditions.tag_in)

// rulesWithConditions.forEach(( rule ) => {
//     tags = [...tags, ...rule.conditions.tag_in]
// });

const NotificationTagsInput = ({ translate,  ...props}) => {

    let tags = [];
    const rulesWithConditions =  ability.rulesFor("read", "Notification").filter(rule => rule.conditions && rule.conditions.tag_in)
    
    rulesWithConditions.forEach(( rule ) => {
        tags = [...tags, ...rule.conditions.tag_in]
    });


    const choices = tags.map(tag => ({id: tag, name: translate(`resources.Notification.tags.${tag}`, { _: tag })}))


    const optionIndeterminate = (choice, value) => {

        return choice.id === "EXPIRED";
    }
    // (choice.id === permissions.user.department.id && !!value && value.some(v => v === permissions.user.department.id));

    return <CheckBoxGroupInputWithCheckAll choices={choices} /*optionIndeterminate={optionIndeterminate}*/ { ...props}/>
};

export default  compose(
  
    translate,
 

)(NotificationTagsInput);

