import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

export default (args) => {
    return apolloCoreClient.mutate({
        mutation: gql`
            mutation createOrderForGuest($regionId: String!, $districtIdOrCityId: String!, $districtId: String, $cityId: String, $addrManual: String, $name: String!, $phone: String!) {
                createOrderForGuest(regionId: $regionId, districtIdOrCityId: $districtIdOrCityId, districtId: $districtId, cityId: $cityId, addrManual: $addrManual, name: $name, phone: $phone) {
                    id
                    humanId
                    customer{
                        name
                        phone
                    }
                    addrManual
                }
            }
        `,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    });
}