import React, { Fragment  } from 'react';
import { ReferenceInput,  AutocompleteInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form';
import UserQuickCreateButton from './UserQuickCreateButton';
import UserQuickPreviewButton from './UserQuickPreviewButton';
import UserSuggestionComponent from './UserSuggestionComponent';
import { ability } from '../../ability';



const styles = theme => ({
    container: {
        display:"inline-block"
    },
    root: {
        height: "unset",
        flexDirection: "column",
        alignItems: "start",
    },
});

const UserReferenceInput = ({classes, ...props}) => {

    return (
        <Fragment >
            {/* <ReferenceInput {...props} reference="User" sort={{ field: 'createdAt', order: 'DESC' }} allowEmpty >
                <SelectInput optionText={<UserOption />} classes={{root: classes.root}}/>       
            </ReferenceInput> */}
                    
            <ReferenceInput {...props} reference="User" sort={{ field: 'name', order: 'ASC' }} allowEmpty classes={{container: classes.container}} >
                <AutocompleteInput optionText="name" suggestionComponent={UserSuggestionComponent} />       
            </ReferenceInput>

            { ability.can("create", { constructor:{name:"User"},role: props.source.substring(0, props.source.indexOf('.')).toUpperCase()}) && (
                <UserQuickCreateButton source={props.source} />
            )}
            { ability.can("read", { constructor:{name:"User"},role: props.source.substring(0, props.source.indexOf('.')).toUpperCase()}) && (
                <Field name={props.source} component={({ input }) => input.value && <UserQuickPreviewButton id={input.value} />}/>
            )}
            
        </Fragment>
    );
} 

export default withStyles(styles)(UserReferenceInput);

