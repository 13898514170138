import React from 'react';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { translate } from 'react-admin';
import FavoriteButton from '../Buttons/FavoriteButton';

const styles = theme => ({
    title:{
        display: "flex",
        alignItems: "center"
    },
    titleSecondary:{
        fontSize: "0.875rem",
        color: fade(theme.palette.primary.contrastText, 0.87) 
    },
    ml2:{ marginLeft: ".5rem" },
    mlAuto:{ marginLeft: "auto" },
    favoriteIcon: {
        color: theme.palette.primary.contrastText,
        '&:hover': {
        backgroundColor: fade(theme.palette.primary.contrastText, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: 'transparent',
            },
        },
    },
    unfavoriteIcon: {
        color: theme.palette.primary.contrastText,
        '&:hover': {
        backgroundColor: fade(theme.palette.primary.contrastText, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: 'transparent',
            },
        },
    }
});



const Title = ({ record, translate, className, classes,locales,options={}, ...props }) => {


    let helperText = `${(!!record.resultRef) ? `${translate(`resources.Order.results.${record.resultRef}`)}` : `${translate(`resources.Order.statuses.${record.statusRef}`)}`}`
   
    
    
    return (
        <div className={classes.title} >
            <FavoriteButton {...props} classes={{favoriteIcon: classes.favoriteIcon, unfavoriteIcon: classes.unfavoriteIcon}} record={record}/>
            <Typography variant="title" color="inherit" noWrap>
                <span>{`${translate('resources.Order.name', { smart_count: 1 })} ${record.humanId}`}</span>
                <span className={`${classes.titleSecondary} ${classes.ml2}`}>{`${helperText}`}</span>
            </Typography>
        </div>
    );
};

export default compose( withStyles(styles), translate, )( Title );