import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment'
import { List, AutocompleteInput, DatagridBody, Filter, SelectInput, DateInput, ReferenceInput, Datagrid, TextField, FunctionField, translate, } from 'react-admin';
import { exporter } from '../../exporter';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/icons/Poll';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {AddressSuggestionComponent} from '../Order/AddressComponents';
import UserSuggestionComponent from '../User/UserSuggestionComponent';
import ButtonLink from './ButtonLink';

import { ability } from '../../ability';

const styles = theme => ({
    minWidth:{
        width: 100,
        textAlign: "center",
    }
});


export const StatisticIcon = Icon;

const customerTypes = [
    { type: 'PHIS', name:'Физическое лицо'},
    { type: 'JURE', name:'Юридическое лицо'},
];

const StatisticFilter = (props) => {
    const regionIds = localStorage.getItem('regionIds');  
    return (
        <Filter {...props}>
            <ReferenceInput source="regionId" reference="Location" filter={{ type: 'REGION', ids: regionIds != null ? regionIds.split(',') : undefined }} alwaysOn >
                <AutocompleteInput suggestionComponent={AddressSuggestionComponent} optionText={record => (`${record.typeShort}. ${record.name}`)} />       
            </ReferenceInput>
            <ReferenceInput source="dispatcher" reference="User" filter={{ role: "DISPATCHER" }} sort={{ field: 'name', order: 'ASC' }} alwaysOn >
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" inputValueMatcher={() => null} />     
            </ReferenceInput>
            <ReferenceInput source="verifier" reference="User" filter={{ role: "VERIFIER" }} sort={{ field: 'name', order: 'ASC' }} alwaysOn >
                <AutocompleteInput suggestionComponent={UserSuggestionComponent} optionText="name" inputValueMatcher={() => null} />     
            </ReferenceInput>
            <SelectInput source="customerType" choices={customerTypes} optionText="name" optionValue="type" />
            <DateInput source="completedDeadlineAt_gte" />
            <DateInput source="completedDeadlineAt_lte" />
            <DateInput source="createdAt_gte" defaultValue={moment().startOf('year').format()} />
            <DateInput source="createdAt_lte" defaultValue={moment().endOf('year').format()} />
            <DateInput source="completedAt_gte" alwaysOn />
            <DateInput source="completedAt_lte" alwaysOn />
        </Filter>
    ); 
};



const CastomDatagridRowView = ({ record, resource, id, onToggleItem, children, selected, basePath, translate, classes, className, hover, ...props }) => {
    if ( id === 'Total' ) {
        return (
            <TableRow className={`${className}`} key={id}>
            {React.Children.map(children, field => {
                if (!field) return null;
                if (field.props.source === 'name') return <TableCell className={`${classes.rowCell} ${field.props.cellClassName}`} key={`${id}-${field.props.source}`} ><Typography variant="body1" color="textSecondary">{`${translate('resources.Statistic.fields.total')}`}</Typography></TableCell>
                
                // return <TableCell className={`${classes.rowCell}`} style={{ textAlign: "center", }} key={`${id}-${field.props.source}`} ><Typography variant="body1" color="textSecondary">{record[field.props.source]}</Typography></TableCell> 
                return (
                    <TableCell className={`${classes.rowCell} ${field.props.cellClassName} ${(field.props.source !== 'id') ? classes.textCenter: ""}`} key={`${id}-${field.props.source}`}>                                               
                    { React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}                       
                    </TableCell>
                )
            })}
            </TableRow>
        )
    }
    
    return (
        <TableRow className={`${className}`} hover={hover} key={id}>
        {React.Children.map(children, field => {
            if (!field) return null;
            return (
                <TableCell className={`${classes.rowCell} ${field.props.cellClassName} ${(field.props.source !== 'id') ? classes.textCenter: ""}`} key={`${id}-${field.props.source}`}>                                               
                    { React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}                       
                </TableCell>  
            );            
            
        })}
        </TableRow>
    );
};

const CastomDatagridRow = translate(CastomDatagridRowView);

const CastomDatagridBody = props => <DatagridBody {...props} row={<CastomDatagridRow />} />;

const renderLocation = record => (`${record.typeShort}.${record.name}`);

const CastomDatagrid = withStyles(styles)(({classes, ...props}) => {
    return (
        <Datagrid {...props} hover body={<CastomDatagridBody />}>
            <FunctionField key="name" source="name" render={renderLocation} />
            { ability.can("List", {constructor:{name:"Order"},tab:"cancelled"}) && <ButtonLink key="refused" source="refused" to="/Order" filter={{tab:"cancelled", resultRef:"refused", ...props.filterValues}} />}
            { ability.can("List", {constructor:{name:"Order"},tab:"current"}) && <ButtonLink key="incompleted" source="incompleted" to="/Order" filter={{tab:"current", ...props.filterValues}} />}
            { ability.can("List", {constructor:{name:"Order"},tab:"completed"}) && <ButtonLink key="verified" source="verified" to="/Order" filter={{tab:"completed", resultRef:"verified", ...props.filterValues}} />}
            { ability.can("List", {constructor:{name:"Order"},tab:"completed"}) && <ButtonLink key="notVerified" source="notVerified" to="/Order" filter={{tab:"completed", resultRef:"notVerified", ...props.filterValues}} />}
            { ability.can("List", {constructor:{name:"Order"},tab:"completed"}) && <ButtonLink key="noneSeal" source="noneSeal" to="/Order" filter={{tab:"completed", resultRef:"noneSeal", ...props.filterValues}} />}   
            { ability.can("List", {constructor:{name:"Order"},tab:"completed"}) && <ButtonLink key="swap" source="swap" to="/Order" filter={{tab:"completed", resultRef:"swap", ...props.filterValues}}/>}
            { ability.can("List", {constructor:{name:"Order"},tab:"completed"}) && <TextField key="completed" source="completed" style={{ textAlign: "center" }} />}
            <TextField key="deadlined" source="deadlined" style={{ textAlign: "center" }} cellClassName={classes.minWidth} />
        </Datagrid>
    );
});

const StatisticPagination = () => null;



const exportBuilder = (records, fetchRelatedRecords, translate, filterValues) => { 

    exporter(records, 'Statistic', [
        { field: "id" }, 
        { field: "typeShort" },
        { field: "name"},
        { field: "unrestrictedName"},
        { field: "refused"}, 
        { field: "incompleted"}, 
        { field: "verified"}, 
        { field: "notVerified"}, 
        { field: "noneSeal"}, 
        { field: "swap"}, 
        { field: "completed"},
        { field: "totalDistrictOrCity"}, 
    ], translate, filterValues) 
};

export const StatisticListView = ({ permissions, translate, filterValues, dispatch, ...props }) => {

  
    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate, filterValues) 
    }

    return (
        <List {...props}
            pagination={<StatisticPagination />}
            bulkActionButtons = {false}
            filters={<StatisticFilter />}
            sort={{ field: 'name', order: 'ASC' }}
            exporter={exportBuilderWithTranslate}>
            <CastomDatagrid /> 
        </List> 
    );

};


const mapStateToProps = (state) => {

    return { filterValues: (!state.form.filterForm) ? undefined : state.form.filterForm.values };
};

const enhance = compose(
    translate,
    connect( mapStateToProps )
);


export const StatisticList = enhance(StatisticListView);