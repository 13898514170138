import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { initialNotifications, enqueueNotification, removeNotification, displayedNotification, closeNotification } from '../../redux/notifications/actions';
import registerSubscriber from '../../dataProviders/Notifications/registerSubscriber';
import getListNotifications from '../../dataProviders/Notifications/getListNotifications';
import useNotificationSubscription from '../../dataProviders/Notifications/notificationSubscription';

import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationDefault, { NEWORDERSnackbarContent } from './NotificationDefault';
import Notification from './Notification';
import { ability } from '../../ability'
import { withStyles } from '@material-ui/core/styles';

import { NAME_APP } from '../../appsettings';



const getChildren = (tag) => {
    switch (tag) {
        case "NEWORDER":
            return <Notification />;

        default:
            return null;
    }
};



const NotificationsStack = ({
    user,
    notifications,
    initialNotifications,
    enqueueNotification,
    displayedNotification,
    closeNotification,
    removeNotification,
    classes,
    ...props
}) => {

    if (!ability.can('read', "Notification")) { return null };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (user) {
            registerSubscriber({
                filter: {
                    tag_in: user.notificationTags
                }
            });
        }
    }, [user]);

    useEffect(() => {

        notifications.forEach(notification => {

            const { id, displayed, dissmissed, requireInteraction, title, tag } = notification;

            const handleDismiss = (e) => {
                e.stopPropagation();
                closeNotification(id);
                closeSnackbar(id);
            };

            if (displayed) return;

            const children = getChildren(tag)

            enqueueSnackbar(title, {
                key: id,
                persist: requireInteraction,
                children: React.cloneElement(children, {
                    handleDismiss,
                    notification,
                    elevation: 6,
                }),
            });

            displayedNotification(id);
        });
    }, [notifications]);

    const onSubscriptionData = ({ client, subscriptionData: { data: { notification: { mutation, node } } } }) => {
        const districtIdOrCityIds = localStorage.getItem('districtIdOrCityIds').split(',');

        const isMyNotification = districtIdOrCityIds.some(id => id === node.data.order.districtIdOrCityId);

        if (isMyNotification) { enqueueNotification(node) };
    };

    const notificationSubscription = useNotificationSubscription({
        onSubscriptionData: onSubscriptionData,
        shouldResubscribe: true,
        variables: (user && user.notificationTags)
            ? {
                filter: {
                    app: NAME_APP,
                    tag_in: user.notificationTags
                }
            }
            : {}
    });

    return null;

};

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications.items,
    }
};

export default connect(
    mapStateToProps,
    {
        initialNotifications,
        enqueueNotification,
        displayedNotification,
        closeNotification,
        removeNotification,
    },
)(NotificationsStack);