import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
});

const Loading = ({ classes, ...props }) => {

    return (
        <div className={classes.root} >
            <CircularProgress color="secondary" />
        </div>
    )

};


export default withStyles(styles)(Loading);


