import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import moment from 'moment'
import 'moment/locale/ru';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { amber, green, blue } from '@material-ui/core/colors';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import ClearIcon from '@material-ui/icons/Clear';
import DismissIcon from '@material-ui/icons/ChevronRight';
import { translate } from 'ra-core';

import clearNotification from '../../dataProviders/Notifications/clearNotification';
import { removeNotification, closeNotification } from '../../redux/notifications/actions';

const getTypeClasses = type => {
  switch (type) {
    case 'INFO':
      return {
        bg: 'bgInfo',
        text: 'text-white',
        textSecondary: 'text-white'
      };
      break;

    case 'SUCCESS':
      return {
        bg: 'bgSuccess',
        text: 'text-white',
        textSecondary: 'text-white'
      };
      break;

    case 'WARNING':
      return {
        bg: 'bgWarning',
        text: 'text-white',
        textSecondary: 'text-white'
      };
      break;

    case 'ERROR':
      return {
        bg: 'bgError',
        text: 'text-white',
        textSecondary: 'text-white'
      };
      break;

    default:
      return {
        bg: 'bgDefault',
        text: 'text-white',
        textSecondary: 'text-muted'
      };
      break;
  }
};

const getTypeIcon = type => {
  switch (type) {
    case 'INFO':
      return <InfoIcon />;
      break;

    case 'SUCCESS':
      return <CheckCircleIcon />;
      break;

    case 'WARNING':
      return <WarningIcon />;
      break;

    case 'ERROR':
      return <ErrorIcon />;
      break;

    default:
      return <NotificationsIcon />;
      break;
  }
};

const styles = theme => {

  return {
    notification: {
      width: 356,
      marginBottom: 8,
      color: "#fff"
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: "12px 16px",
    },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 auto',

    },
    headerIcon: {
      marginRight: 8,
    },
    headerExtra: {
      marginLeft: "auto",
      paddingLeft: 8,
    },
    headerAction: {
      flex: '0 0 auto',
      alignSelf: 'flex-start',
      marginTop: -8,
      marginBottom: -8,
      marginRight: -12,
    },
    iconButton: {
      width: 44,
      height: 44,
      fontSize: 20,
    },
    body: {
      paddingLeft: 16,
      paddingRight: 16,
      '&:last-child': {
        paddingBottom: 16,
      },
    },
    textClamp: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 2,
      boxOrient: "vertical",
    },
    actions: {
      display: "flex",
      alignItems: "center",
      padding: 8,
      '& > * + *': {
        marginLeft: 8,
      },
    },

    bgDefault: {},
    bgInfo: { backgroundColor: blue[700] },
    bgSuccess: { backgroundColor: green[600] },
    bgWarning: { backgroundColor: amber[700] },
    bgError: { backgroundColor: theme.palette.error.dark },

  };
};

const Notification = ({ notification, classes, translate, handleDismiss, dismissNotificationRedux, clearNotificationRedux, ...props }) => {

  const { id, title, body, actions, type, timestamp, dismissed } = notification;

  const [clamped, setClamped] = useState(true);

  const toggleClamped = () => {
    setClamped(!clamped);
  };

  const handleClear = () => {

    clearNotification({ id }).then(({ data: { disconnectNotification: { id } } }) => {
      clearNotificationRedux(id);
    });
  };

  // const handleDismiss = () => {
  //   // if (typeof clearNotification === "function") {clearNotification(id)}

  //   dismissNotificationRedux(id);
  // };

  const typeClasses = getTypeClasses(type);
  const typeIcon = getTypeIcon(type)
  const extra = moment(timestamp).fromNow()

  return (
    <Paper className={`${classes.notification} ${classes[typeClasses.bg]}`} {...props}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          {React.cloneElement(typeIcon, { className: classes.headerIcon })}
          <Typography variant="body2" color="inherit" noWrap>{title}</Typography>
          <Typography variant="caption" color="inherit" className={classes.headerExtra}>{extra === "несколько секунд назад" ? "только что" : extra}</Typography>
        </div>
        <div className={classes.headerAction}>
          {dismissed
            ? (<Tooltip title={translate("raExt.action.clear")} enterDelay={700}>
              <IconButton className={classes.iconButton} color="inherit" aria-label="Clear" >
                <ClearIcon fontSize="inherit" onClick={handleClear} />
              </IconButton>
            </Tooltip>)
            : (<Tooltip title={translate("raExt.action.close")} enterDelay={700}>
              <IconButton className={classes.iconButton} color="inherit" aria-label="Dismiss" >
                <DismissIcon fontSize="inherit" onClick={handleDismiss} />
              </IconButton>
            </Tooltip>)
          }

        </div>

      </div>
      {body ? <div className={classes.body} onClick={toggleClamped} ><Typography variant="body1" color="inherit" className={clamped ? classes.textClamp : ""}>{body}</Typography></div> : null}
      {actions.length ? <div className={classes.actions}>
        {actions.map(action => (<Button key={action.title} href={action.action} color="inherit" size="small" >{translate(`ra.action.${action.title.toLowerCase()}`, { _: action.title })}</Button>))}
      </div> : null}
    </Paper>
  );
};



export default compose(
  withStyles(styles),
  translate,
  connect(
    undefined,
    {
      clearNotificationRedux: removeNotification,
      dismissNotificationRedux: closeNotification,
    },
  )
)(Notification);


