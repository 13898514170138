import React from 'react';
import { List, ListView, ListController, Datagrid, TextField, DateField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import TitleWithSearch from '../Layout/TitleWithSearch';

import { exporter } from '../../exporter';

import PhoneField from '../Fields/PhoneField';
import Pagination from '../Layout/Pagination';
import { ability } from '../../ability';



const styles = theme => ({
    cellMinWidth: {
        width: '1px',
        padding: 0,
    }
});

const exportBuilder = (records, fetchRelatedRecords, translate) => {
    exporter(records, 'File', [
        { field: "id" },
        { field: "catalog" },
        { field: "createdAt", format: 'L' },
        { field: "createdBy" },
        { field: "desc" },
        { field: "ext" },
        { field: "idBlob" },
        { field: "size" },
        { field: "url" },
        { field: "urlThumbnail" },
    ], translate)
};



const CounterpartyList = translate(withStyles(styles)(({ classes, translate, ...props }) => {
    // const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
    //     exportBuilder(records, fetchRelatedRecords, translate) 
    // }

    return (
        // <List
        //     exporter={false}
        //     pagination={<Pagination />}
        //     title={<TitleWithSearch />}
        //     {...props}
        // >
        //     <Datagrid rowClick="show">
        //         {ability.can('List', 'Counterparty', 'name') && <TextField source="name" />}
        //         {ability.can('List', 'Counterparty', 'description') && <TextField source="description" />}
        //         {ability.can('List', 'Counterparty', 'phone') && <PhoneField source="phone" />}
        //         {ability.can('List', 'Counterparty', 'email') && <TextField source="email" />}
        //         <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
        //         <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
        //         {/* <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
        //             {ability.can('getOne', 'Counterparty') && <ShowBlankItem />}
        //             {ability.can('update', 'Counterparty') && <EditMenuItem />}
        //             {ability.can('remove', 'Counterparty') && <RemoveMenuItem />}
        //         </MoreButton> */}
        //     </Datagrid>
        // </List>
        <ListController  {...props}>
            {controllerProps => (
                <ListView
                    title={<TitleWithSearch {...controllerProps} />}
                    exporter={false}
                    pagination={<Pagination />}
                    {...props}
                    {...controllerProps}
                >
                    <Datagrid rowClick="show">
                        {ability.can('List', 'Counterparty', 'name') && <TextField source="name" />}
                        {ability.can('List', 'Counterparty', 'description') && <TextField source="description" />}
                        {ability.can('List', 'Counterparty', 'phone') && <PhoneField source="phone" />}
                        {ability.can('List', 'Counterparty', 'email') && <TextField source="email" />}
                        <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                        <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    </Datagrid>
                </ListView>
            )
            }
        </ListController>
    )

}));


export default CounterpartyList;