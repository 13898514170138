import { NAME_APP, URI_CASL } from '../appsettings'
import { ApolloClient } from 'apollo-client';
import { GetAccessToken } from './authProvider';
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import gql from 'graphql-tag';

const CASL_QUERY_GETABILITY = gql`
  query getAbility {
    rules: getAbility {
      actions
      subject
      conditions
      fields
      inverted
      reason
    }
  }
`

const httpLink = createHttpLink({
  uri: URI_CASL
})

const caslLink = setContext(async (_, { headers }) => {
  const token = await GetAccessToken();
  //console.log('++++> ['+URI_CASL+'] header get TOKEN from localStorage ('+token+')');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      appName: NAME_APP
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

export const apolloCaslClient = new ApolloClient({
  link: caslLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
})

export async function getAbility() {

  const { rules } = await apolloCaslClient.query({
    query: CASL_QUERY_GETABILITY
  }).then(responce => responce.data);

  if (!rules) throw new Error("Ability rules not found");

  return rules;
}