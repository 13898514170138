import React from 'react';
//import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import bgImage from '../../files/LoginPageImage.svg';
import Login from './Login';
import LoginForm from './LoginForm';

const styles = () => ({
 
});


export default withStyles(styles)(({classes}) => <Login backgroundImage={bgImage} loginForm={<LoginForm />} />);