import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { SelectInput } from "react-admin";
import useEnum from '../../dataProviders/Core/useEnum'

const EnumInput = ({ Enum, filter, record, resource, source, translate, useEnum, option, ...props }) => {

    const enums = useEnum(Enum);

    const selected = enums.filter(choice => choice.name === get(record, source));

    const choices = (filter) ? [...new Set([...selected, ...enums.filter(filter)])] : enums

    const getOption = choice => option ? `${option}.${choice.name}` : `resources.${resource}.enums.${source}.${choice.name}`;


    return (
        <SelectInput source={source} resource={resource} choices={choices} optionValue="name" optionText={getOption} {...props} />
    );

}

EnumInput.defaultProps = {
    useEnum
};

EnumInput.propTypes = {
    source: PropTypes.string,
    Enum: PropTypes.string.isRequired,
};


export default EnumInput;