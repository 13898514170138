import React from 'react';
import { List, Datagrid, Filter, TextInput, TextField, Show, SimpleShowLayout } from 'react-admin';
import Icon from '@material-ui/icons/LocationOn';
import { exporter } from '../../exporter';
import Pagination from '../Layout/Pagination';


export const LocationIcon = Icon;

const LocationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />      
    </Filter>
);

const TagsField = ({ record }) => {   
    let prefix = ''
    if(record.parents != null && record.parents.length > 0) {
        for(let i=record.parents.length-1; i>=0; i--) {     
            prefix+= record.parents[i].typeShort + '. ' + record.parents[i].name + ', ';
        }
    }

    var result = prefix + `${record.typeShort}. ${record.name}`;
    return result;
}

export const LocationList = ({ permissions, ...props }) => (  
    <List 
        {...props}
        filters={<LocationFilter />}
        filterDefaultValues={{type: 'REGION'}}
        exporter={(dataraws) => { exporter(dataraws, 'Locations') }}
        pagination={<Pagination />}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="typeShort" />
            <TextField source="name" />
            <TagsField></TagsField>
        </Datagrid>
    </List>
);



export const LocationShow = ({ permissions, ...props }) => (
    <Show  {...props}>
     <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="typeShort" />
        <TextField source="name" />
        <TagsField></TagsField>
     </SimpleShowLayout>     
    </Show>
);