import React from 'react';
import { Button } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import exportOrdersToXml from '../../dataProviders/OrdersToXml/exportOrdersToXml';
import downloadFile from '../../dataProviders/OrdersToXml/downloadFile';


const OrdersToXmlButton = ({ record, ...props }) => {

    if (!record) return null;

    const handleExport = async () => {


        exportOrdersToXml({
            ids: [record.id]
        }).then(({ blob, filename }) => {
            if (blob instanceof Blob) {
                downloadFile(blob, filename)
            }
        })

    };

    return (
        <Button label="raExt.action.export_orders_to_xml" onClick={handleExport} {...props} >
            <GetAppIcon />
        </Button>
    );
}

export default OrdersToXmlButton;
