
import { unparse as convertToCSV } from 'papaparse/papaparse';
import get from 'lodash/get';
import moment from 'moment'

const downloadCSV = (csv, fileName) => {
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `${fileName}.csv`);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${fileName}.csv`);
        fakeLink.click();
    }
};

export const exporter = (dataraws, resource, fields, translate, filterValues) => {

    const filterHeader = {}
    const filterValue = {}
    const dataHeader = {};

    if (!!filterValues && Object.keys(filterValues).length > 0) {
        Object.keys(filterValues).map(filter => {
            filterHeader[filter] = translate(`resources.${resource}.fields.${filter}`);
            filterValue[filter] = moment(filterValues[filter]).isValid() ? moment(filterValues[filter]).format("L") : filterValues[filter];
        });
    };


    if (!!fields) {
        fields.map(field => {
            if (typeof field === 'object') {
                dataHeader[field.field] = translate(`resources.${resource}.fields.${field.field}`);
            } else {
                dataHeader[field] = translate(`resources.${resource}.fields.${field}`);
            };
        });
    };


    let dataArr = dataraws.map(row => {
        if (!fields) return row;
        let item = {};
        fields.map(field => {
            if (typeof field === 'object') {
                let key = field.field;
                let value = get(row, key, null);
                if (!!field.format && value) value = moment(value).format(field.format);
                if (!!field.translateValues) value = (value === null || value === '') ? null : translate(`${field.translateValues}.${value}`);
                item[key] = value;

            } else {
                item[field] = get(row, field, null);
            };

        });
        return item;
    });

    dataArr = (!fields) ? dataArr : [dataHeader, ...dataArr];


    const BOM = "\uFEFF"
    const filters = (!!filterValues && Object.keys(filterValues).length > 0) ? BOM + convertToCSV([filterHeader, filterValue], {
        delimiter: ";",
        encoding: "UTF-8",
        header: false,
    }) + "\r\n" : "";

    const data = BOM + convertToCSV(dataArr, {
        delimiter: ";",
        encoding: "UTF-8",
        header: false,
    });

    const csv = `${filters}${data}`;

    downloadCSV(csv, translate(`resources.${resource}.name`, { smart_count: 2 })); // download as 'posts.csv` file
}