import React from 'react';
import Grid from '@material-ui/core/Grid';
//import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { change,getFormValues  } from 'redux-form';
import {
    EditButton,
    AutocompleteInput,
    ReferenceInput,
    TextInput,
    DisabledInput,
    required,
    REDUX_FORM_NAME
} from 'react-admin';
//import AutocompleteInput2 from '../Inputs/AutocompleteInput'
import { AddressSuggestionComponent } from './AddressComponents'
import CheckBoxConditionsInput from '../Inputs/CheckBoxConditionsInput';
import FindDuplicateButton from '../Buttons/FindDuplicateButton';


let _arrCities = [];
let _arrStreets = [];
let _cityId = null

export class AddressInput extends React.Component {

    changeAddress = (e, value, prev, prop) => {

        if (value === prev) return false;

        let {formData, change} = this.props;
        let {cityId, streetId, houseNum, houseCorp, houseFlat} = formData;

        switch( prop ){
            case 'cityId':
                const cityChoise = _arrCities.find(choice => choice.id === value);
                if (!cityChoise) return false;
                change(REDUX_FORM_NAME, 'streetId', null);
                change(REDUX_FORM_NAME, 'houseNum', null);
                change(REDUX_FORM_NAME, 'houseCorp', null);
                change(REDUX_FORM_NAME, 'houseFlat', null);
                change(REDUX_FORM_NAME, 'regionId', cityChoise.regionId);
                change(REDUX_FORM_NAME, 'districtId', cityChoise.districtIdOrCityId);
                change(REDUX_FORM_NAME, 'districtIdOrCityId', cityChoise.districtIdOrCityId);
                change(REDUX_FORM_NAME, 'addrManual', addressGenerate(value, null, null, null, null));
            break;

            case 'streetId':
                change(REDUX_FORM_NAME, 'houseNum', null);
                change(REDUX_FORM_NAME, 'houseCorp', null);
                change(REDUX_FORM_NAME, 'houseFlat', null);
                change(REDUX_FORM_NAME, 'addrManual', addressGenerate(cityId, value, null, null, null));
            break;

            case 'houseNum':
                change(REDUX_FORM_NAME, 'addrManual', addressGenerate(cityId, streetId, value, houseCorp, houseFlat));
            break;

            case 'houseCorp':
                change(REDUX_FORM_NAME, 'addrManual', addressGenerate(cityId, streetId, houseNum, value, houseFlat));
            break;

            case 'houseFlat':
                change(REDUX_FORM_NAME, 'addrManual', addressGenerate(cityId, streetId, houseNum, houseCorp, value));
            break;
            default: break;
        }
       
    }

    onChangeCheckBoxConditions = (e) => {
        const { formData, change } = this.props;
        if (e.target.checked) change(REDUX_FORM_NAME, 'districtId', formData.districtIdOrCityId);
    }

    render(){
        const {change, formData, className, ...props} = this.props;
        
        const regionIds = localStorage.getItem('regionIds').split(',');
        const districtIdOrCityIds = localStorage.getItem('districtIdOrCityIds').split(',');

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={6} lg>
                    <ReferenceInput validate={required()} source="cityId" allowEmpty reference="Location" sort={{ field: 'level', order: 'ASC' }} perPage={300} filter={{ type: 'CITY', regionIds: regionIds , districtIdsOrCityIds: districtIdOrCityIds }} onChange={this.changeAddress} options={{ fullWidth: true, helperText:`Например: "Краснодар"` }} {...props} >
                        <CityAutocompleteInput />     
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <ReferenceInput source="streetId" allowEmpty reference="Location" sort={{ field: 'level', order: 'ASC' }} perPage={300} filter={{ type: 'STREET', parentIds: !!formData.cityId ? [formData.cityId] : null }} onChange={this.changeAddress} options={{ fullWidth: true, disabled:!formData.cityId, }} {...props} >
                        <StreetAutocompleteInput formData={formData} />               
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4} lg={1}>
                    <TextInput source="houseNum" disabled={!formData.cityId} onChange={this.changeAddress} fullWidth {...props} />
                </Grid>
                <Grid item xs={4} lg={1}>
                    <TextInput source="houseCorp" disabled={!formData.cityId} onChange={this.changeAddress} fullWidth {...props} />   
                </Grid>
                <Grid item xs={4} lg={1}>
                    <TextInput source="houseFlat" disabled={!formData.cityId} onChange={this.changeAddress} fullWidth {...props} />  
                </Grid>
                <Grid item xs={12}>      
                    <CheckBoxConditionsInput onChange={this.onChangeCheckBoxConditions} checked={(formData.districtId === formData.districtIdOrCityId)} invert label="resources.Order.helper.addressMatches" className={className} {...props}>
                        <ReferenceInput
                            source="districtId"
                            filter={{ type: 'DISTRICT', regionIds: regionIds , districtIdsOrCityIds: districtIdOrCityIds  }}
                            reference="Location"
                            perPage={30}
                            allowEmpty
                        >
                            <AutocompleteInput suggestionComponent={AddressSuggestionComponent} optionText={record => (`${record.typeShort}. ${record.name}`)}                   
                        />  
                        </ReferenceInput>
                    </CheckBoxConditionsInput>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }} >
                        <DisabledInput source="addrManual" {...props} style={{ flexGrow: 1 }}/>
                        <FindDuplicateButton style={{ marginLeft: 8, marginBottom: 8 }} address={formData.addrManual}/>
                    </div>
                    
                    <DisabledInput source="regionId" style={{display: 'none'}}  />
                    <DisabledInput source="districtIdOrCityId" style={{display: 'none'}} />
                </Grid>

            </Grid>
        );
    };
}

const addressGenerate = ( cityId=null, streetId=null, houseNum=null, houseCorp=null, houseFlat=null) => {
    let city = null;
    let street = null;
    
    if (!!cityId) {
        const cityChoise = _arrCities.find(choice => choice.id === cityId);
        city = cityOptionRenderer(cityChoise);
    }

    if (!!streetId) {
        const streetChoise = _arrStreets.find(choice => choice.id === streetId);
        street = optionRendererStreet(streetChoise);
    }

    
    return  `${city ? city : ""}${street ? ", " + street : ""}${houseNum ? ", д. "+houseNum : ""}${houseCorp ? ", корп. " + houseCorp : ""}${ houseFlat ? ", кв. " + houseFlat + "." : ""}`
    
};


const cityOptionRenderer = choice => {
    if (!choice) return null
    let prefix = '';
    if(choice.parents != null) {
        for(let i=choice.parents.length-1; i>=0; i--) {     
            prefix+= choice.parents[i].name+ ' '+choice.parents[i].typeShort + ', ';
        }
    }
    
    var result = prefix + `${choice.typeShort}. ${choice.name}`;
    return result;
};

const CityAutocompleteInput = ( props ) => {
    const { choices } = props
    _arrCities = choices;
    
    return <AutocompleteInput optionText="unrestrictedName" suggestionComponent={AddressSuggestionComponent} shouldRenderSuggestions={(val) => { return val.trim().length > 2 }} {...props}/>

}

const optionRendererStreet = choice => {          
    let prefix = ''
    if(choice.parents != null) {  
        if(choice.parents.length > 1) {
            let flSt = false;
            for(let i=choice.parents.length-1; i>=0; i--) {                        
                let item = choice.parents[i];                                    
                if(item.id === _cityId) { flSt=true; continue; }
                if(flSt) prefix+= item.typeShort + '. ' + item.name + ', ';                                
            }
        }
    }

    var result = prefix + `${choice.typeShort}. ${choice.name}`;
    return result;
};





const StreetAutocompleteInput = ( props ) => {
    const { choices, formData } = props
    _arrStreets = choices;
    _cityId = formData.cityId

    return  <AutocompleteInput optionText={optionRendererStreet} suggestionComponent={AddressSuggestionComponent} shouldRenderSuggestions={(val) => { return val.trim().length > 0 }} inputValueMatcher={() => null} {...props} />               
    
}


const mapStateToProps = (state, { record }) => ({
    formData: getFormValues(REDUX_FORM_NAME)(state) || record,
});

const mapDispatchToProps = {
    change,
}

export default connect( mapStateToProps, mapDispatchToProps )( AddressInput );