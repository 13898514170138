export const INITIAL_NOTIFICATIONS = 'INITIAL_NOTIFICATIONS';
export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
export const DISPLAYED_NOTIFICATION = 'DISPLAYED_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const initialNotifications = ({items, total}) => {
    return {
        type: INITIAL_NOTIFICATIONS,
        payload: {items, total},
    };
};


export const enqueueNotification = notification => {
    return {
        type: ENQUEUE_NOTIFICATION,
        payload: { 
            notification, 
        },
    };
};

export const displayedNotification = id => ({
    type: DISPLAYED_NOTIFICATION,
    payload: { 
        id,
    },
});

export const closeNotification = id => ({
    type: CLOSE_NOTIFICATION,
    payload: { 
        dismissAll: !id, // dismiss all if no key has been defined
        id,
    },
});

export const removeNotification = id => ({
    type: REMOVE_NOTIFICATION,
    payload: {
        removeAll: !id,
        id,
    },
});
