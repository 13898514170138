import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

export const USER_BY_AUTH = gql`
    query UserByAuthId($authId: ID!) {
        UserByAuthId(authId: $authId) {
            id
            authId
            authStatus
            name
            email
            phone
            role
            createdAt
            updatedAt
            regionIds
            districtIdOrCityIds
            notificationTags
            isArchive
            online
            favoriteOrders {
                id
            }
        }
    }
`;

export default (args) => {
    return apolloCoreClient.query({
        query: USER_BY_AUTH,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};
