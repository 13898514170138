import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Title, translate } from 'react-admin';
import WidgetGroup from './WidgetGroup';
//import WidgetBanner from './WidgetBanner';
import WidgetNewYearBanner from './WidgetNewYearBanner';
import WidgetAnalytics from './WidgetAnalytics';
import WidgetFavorites from './WidgetFavorites';
//import WidgetBest from './WidgetBestVerifier';



const styles = theme => ({
    paper: {
        color: theme.palette.text.secondary,
        boxSizing: "border-box",
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036)",
        borderRadius: 8,
    },
});

const Dashboard = ({ classes, ...props }) => (
    <div>
        <Title title="Поверка" />
        {/* <Grid container spacing={24}>
            <Grid item xs={12}>
                <WidgetNewYearBanner />
            </Grid>
        </Grid> */}
        <Grid container spacing={24}>
            {/* <Grid item xs={12} md="auto">
                <WidgetGroup title="Сегодня">
                    <Grid container spacing={24} direction="column">
                        <Grid item>
                            <Grid container spacing={24}>
                                <Grid item>
                                    <WidgetBest className={classes.paper} />
                                </Grid>
                                <Grid item>
                                    <WidgetBest className={classes.paper} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={24}>
                                <Grid item>
                                    <WidgetBest className={classes.paper} />
                                </Grid>
                                <Grid item>
                                <WidgetBest className={classes.paper} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </WidgetGroup> 
            </Grid> */}
            <Grid item xs={12} md lg={6} xl={8}>
                <WidgetGroup title="Аналитика">
                    <WidgetAnalytics />
                </WidgetGroup>
            </Grid>
            <Grid item xs={12} md lg={6} xl={4}>
                <WidgetGroup title="Избранные">
                    <WidgetFavorites className={classes.paper} />
                </WidgetGroup>
            </Grid>
        </Grid>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate,
)



export default enhance(Dashboard);