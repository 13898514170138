import Icon from '@material-ui/icons/People';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserShow from './UserShow';

const UserIcon = Icon;

export {
    UserIcon,
    UserList,
    UserShow,
    UserCreate,
    UserEdit,
};