import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities'
import { GetAccessToken} from '../authProvider';
import { URI_NOTIFICATIONS, WS_NOTIFICATIONS } from '../../appsettings';



const httpLink = createHttpLink({
    uri: URI_NOTIFICATIONS
})

const authLink = setContext(async (_, { headers }) => {           
    const token = await GetAccessToken(); 
   
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
});

const wsLink = new WebSocketLink({
  uri: WS_NOTIFICATIONS,
  options: {
    lazy: true,
    reconnect: true,
    timeout: 30000,
    connectionParams: async () => {
      const token = await GetAccessToken(); 
      
      return { 
        authorization: token ? `Bearer ${token}` : "",
      }
    },
  }
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink),
)

export const apolloNotificationsClient = new ApolloClient({
    link, 
    cache: new InMemoryCache()
});
