import React from 'react';
import PropTypes from 'prop-types';
import IconCancel from '@material-ui/icons/Cancel';
import { TextInput, required, regex } from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const phoneValidation = (value="", allValues, { translate }) => {
    return (
        parsePhoneNumberFromString(value, 'RU')) 
        ? (parsePhoneNumberFromString(value, 'RU').isValid()) 
            ? undefined 
            : translate('raExt.validation.ruPhone')
        : translate('raExt.validation.ruPhone');
  
}

const validatePhone = [required(), phoneValidation];


const phoneFormatter = (value="") => {
    const phoneNumber = parsePhoneNumberFromString(value, 'RU')
    if (phoneNumber) {
        return phoneNumber.formatNational()
    }

    return value
 
};
  
const phoneParser = (input="") => {
    const phoneNumber = parsePhoneNumberFromString(input, 'RU')
    if (phoneNumber) {
        return phoneNumber.number;
    }

    return input
};


const PhoneInput = ( props ) => (
    <TextInput parse={phoneParser} format={phoneFormatter} validate={validatePhone} type="tel" {...props} />  
);
// format={phoneFormatter} parse={phoneParser} validate={validatePhone} 


PhoneInput.propTypes = {
    source: PropTypes.string.isRequired,
};

PhoneInput.defaultProps = {
    source: "phone",
};

export default PhoneInput;