import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, crudUpdateMany } from 'react-admin';
import FavoriteIcon from '@material-ui/icons/Star';
import UnfavoriteIcon from '@material-ui/icons/StarBorder';



const styles = theme => ({
    button: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    favoriteButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            borderColor: theme.palette.primary.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },   
    },
    unfavoriteButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            borderColor: theme.palette.primary.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },    
    },
});
class BulkFavoriteButton extends Component {
    
    isFavorite(udatedUser, id) {
        if(!udatedUser.favoriteOrders) return undefined;
        
        return udatedUser.favoriteOrders.find(x => { 
            return x.id == id 
        });
    }
    
    handleFavorite = () => {
        const {  basePath, resource, selectedIds, crudUpdateMany, user } = this.props;      
        crudUpdateMany(resource, selectedIds, { isFavorite: true }, basePath);

        selectedIds.forEach(id => {
            if(!this.isFavorite(user,id)) {
                user.favoriteOrders.push({ id });                
            } 
        });
    };

    handleUnfavorite = () => {
        const {  basePath, resource, selectedIds, crudUpdateMany, user } = this.props;     
        crudUpdateMany(resource, selectedIds, { isFavorite: false }, basePath); 
        
        selectedIds.forEach(id => {
            if(this.isFavorite(user,id)) {
                user.favoriteOrders = user.favoriteOrders.filter(x => x.id != id);
            }
        });      
    };

    render() {
        const { className, classes, filterValues } = this.props;
        return filterValues.tab === "favorite" ? (
            <Button 
                size="small"
                variant="outlined"
                label="raExt.action.unfavorite"
                onClick={this.handleUnfavorite}
                className={`${classes.button} ${classes.unfavoriteButton} ${className}`}
            >   
              <UnfavoriteIcon  />           
            </Button>
        ) : (
            <Button 
                size="small"
                variant="outlined"
                label="raExt.action.favorite"
                onClick={this.handleFavorite}
                className={`${classes.button} ${classes.favoriteButton} ${className}`}
            >        
                <FavoriteIcon />      
            </Button>
        );
    }
}

const mapStateToProps = state => {
    return { user: state.user }
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        {            
            crudUpdateMany        
        }
    )
);

export default enhance(BulkFavoriteButton);