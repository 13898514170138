import React, { Fragment } from 'react';
import { List, Datagrid, TextField,  SearchInput, DateField, Filter, BulkDeleteButton, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ability } from '../../ability'
import { exporter } from '../../exporter';
import BulkExportButton from '../Buttons/BulkExportButton';
import LinkField from '../Fields/linkField';
import UserField from '../User/UserField';
import ActionChip from './ActionChip';
import AbbrChanges from './AbbrChanges';
import EmptyFieldPlaceholder from './EmptyFieldPlaceholder';
import Pagination from '../Layout/Pagination';


const styles = theme => ({
    textOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        lineClamp: 2,
        boxOrient: "vertical"
    },
    textSecondary: { color: theme.palette.text.textSecondary, },
    ml2:{ marginLeft: ".5rem"},
    deleteButton: {
        color: theme.palette.getContrastText(theme.palette.error.main),
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
});

const exportBuilder = (records, fetchRelatedRecords, translate) => { 
    exporter(records, 'OrderHistory', [
        { field: "id" }, 
        { field: "action", translateValues:"resources.OrderHistory.actions" },
        { field: "orderHumanId"},
        { field: "order.humanId"},
        { field: "userName"},
        { field: "user.name"},  
        { field: "updated" },
    ], translate) 
};

const DatadridSimple = props => (
    <Datagrid rowClick="show" {...props}>
        <ActionChip />
        <LinkField source="order" sortBy="orderHumanId" reference="Order" linkType={false} emptyValue={<EmptyFieldPlaceholder source="orderHumanId"/>} >
            <TextField source="humanId" noWrap/>
        </LinkField>
        <LinkField source="user" sortBy="userName" reference="User" linkType={false} emptyValue={<EmptyFieldPlaceholder source="userName"/>} >
            <UserField />
        </LinkField>
        <AbbrChanges source="changes"/>
        <DateField source="updated" color="textSecondary" textAlign="right" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>      
    </Datagrid>
);

export const OrderHistoryDatagridForOrder = props => (
    <Datagrid rowClick="show" {...props}>
        <ActionChip />
        <LinkField source="user" sortBy="userName" reference="User" linkType={false} emptyValue={<EmptyFieldPlaceholder source="userName"/>} >
            <UserField />
        </LinkField>
        <AbbrChanges source="changes"/>
        <DateField source="updated" color="textSecondary" textAlign="right" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>      
    </Datagrid>
);

export const OrderHistoryDatagridForUser = props => (
    <Datagrid rowClick="show" {...props}>
        <ActionChip />
        <LinkField source="order" sortBy="orderHumanId" reference="Order" linkType={false} emptyValue={<EmptyFieldPlaceholder source="orderHumanId"/>} >
            <TextField source="humanId" noWrap/>
        </LinkField>
        <AbbrChanges source="changes"/>
        <DateField source="updated" color="textSecondary" textAlign="right" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>      
    </Datagrid>
);

const OrderHistoryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
    </Filter>
);

export const BulkActionToolbar = translate(withStyles(styles)(({classes, translate, ...props}) => {
    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate) 
    }
    
    return (
        <Fragment>
             <BulkExportButton variant="outlined" exporter={exportBuilderWithTranslate} {...props}/>
            { ability.can('delete', 'OrderHistory') && <BulkDeleteButton className={`${classes.deleteButton} ${classes.ml2}`} {...props} />}
        </Fragment>
    );
}));

const OrderHistoryList = translate(({ classes, translate, ...props }) => {
    const exportBuilderWithTranslate = (records, fetchRelatedRecords) => {
        exportBuilder(records, fetchRelatedRecords, translate) 
    }

    return (
        <List {...props}
            bulkActionButtons={<BulkActionToolbar />}
            filters={<OrderHistoryFilter />}
            sort={{ field: 'updated', order: 'DESC' }}
            exporter={exportBuilderWithTranslate}
            pagination={<Pagination />}

        >   
            <DatadridSimple />
        </List>
    );
});



export default OrderHistoryList;
