import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudUpdate, SaveButton, Toolbar } from 'react-admin';
import InWorkIcon from '@material-ui/icons/Build';

// A custom action creator which modifies the values before calling the default crudCreate action creator
const saveWithInWork = (values, record, resource, basePath, redirectTo) =>
    crudUpdate(resource, record.id, { ...values, statusRef: "inWork" }, record, basePath, redirectTo);

class SaveWithInWorkButtonView extends Component {
    handleClick = () => {
        const { resource, record, basePath, handleSubmit, redirect, saveWithInWork } = this.props;

        return handleSubmit(values => {
            saveWithInWork(values, record, resource, basePath, redirect);
        });
    };

    render() {
        const { handleSubmitWithRedirect, saveWithInWork, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                label="raExt.action.in_work"
                icon={<InWorkIcon />}
                {...props}
            />
        );
    }
}

const SaveWithInWorkButton = connect(
    undefined,
    { saveWithInWork }
)(SaveWithInWorkButtonView);


export default SaveWithInWorkButton;