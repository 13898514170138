import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

const formatSizeUnits = function(bytes){
    if(bytes == undefined || bytes == null) {
       return '';
    }

    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " ГБ"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " МБ"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " Кб"; }
    else if (bytes > 1)           { bytes = bytes + " байтов"; }
    else if (bytes == 1)          { bytes = bytes + " байт"; }
    else                          { bytes = "0 байт"; }
    return bytes;
}

const FileSizeField = ({ className, source, record = {} }) => {
    return ( <Typography className={className} >{formatSizeUnits(get(record, source))}</Typography> );
}

FileSizeField.defaultProps = {
    addLabel: true,
};

export default FileSizeField