import { INITIAL_NOTIFICATIONS, ENQUEUE_NOTIFICATION, DISPLAYED_NOTIFICATION, CLOSE_NOTIFICATION, REMOVE_NOTIFICATION } from './actions';

const DISPLAYED_NOTIFICATIONS = "displayed-notifications"

const getItem = () => {
    const item = localStorage.getItem(DISPLAYED_NOTIFICATIONS)
    return (item) ? item.split(',') : []
};

const setItem = (store) => {
    localStorage.setItem(DISPLAYED_NOTIFICATIONS, store)
};

export default (previousState = {items: [], total: {count: 0}}, { type, payload }) => {
    const displayedNotifications = getItem();
    switch (type) {
        case INITIAL_NOTIFICATIONS:
            const {items = [], total = {count: 0}} = payload;
            const newItems = items.map(notification => (
                (displayedNotifications.includes(notification.id)) ? { ...notification, displayed: true, dismissed: true } : notification
            ));

            return {
                items: [...previousState.items, ...newItems],
                total 
            }

        case ENQUEUE_NOTIFICATION:
            return {
                items: [(displayedNotifications.includes(payload.notification.id)) ? { ...payload.notification, displayed: true } : payload.notification, ...previousState.items],
                total: {count: ++previousState.total.count},
            }

        case DISPLAYED_NOTIFICATION:
            setItem([...displayedNotifications, payload.id])
            return {
                items: previousState.items.map(notification => (
                    (notification.id === payload.id) ? { ...notification, displayed: true } : notification
                )),
                total: previousState.total,
            }
            
        case CLOSE_NOTIFICATION:
            return {
                items: previousState.items.map(notification => (
                    (payload.dismissAll || notification.id === payload.id) ? { ...notification, dismissed: true } : notification
                )),
                total: previousState.total,
            } ;

        case REMOVE_NOTIFICATION:
            const removeArray = (payload.removeAll) ? [] : previousState.items.filter(notification => {
                return notification.id !== payload.id
            });
            setItem(displayedNotifications.filter(notificationId => notificationId.id !== payload.id));
            return {
                items: removeArray,
                total: {count: --previousState.total.count},
            };

        default:
            return previousState;
    }
};