import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

const GET_ANALYTICS_BETWEEN_DATES = gql`
  query getAnalytics($from: DateTime, $to: DateTime) {
    OrdersAnalytics(from: $from, to: $to)
  }
`

export default (args) => {
  return apolloCoreClient.query({
    query: GET_ANALYTICS_BETWEEN_DATES,
    variables: { ...args },
    //fetchPolicy: 'network-only',
    onError: (error) => {
      alert(error)
    }
  });
};