import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    createStyles,
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import OrderCreateCard from './OrderCreateCard';
import LoginCard from './LoginCard';


import {  
    Notification,
    defaultTheme,
    LoginForm
} from 'react-admin';


const styles = theme => createStyles({
    main: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "start",
        minHeight: '100vh',
        height: '1px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundColor: '#2196f3',
    },
    cardWrapper: {
        display: 'flex',
        marginTop: '6em',
      
    },
    card: {
        display: "flex",
        flexDirection: "column",
        width: 300,
        margin: '1em',
        boxShadow: theme.shadows[8],
        borderRadius: theme.shape.borderRadius*2 ,
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
});

const sanitizeRestProps = ({
    array,
    backgroundImage,
    classes,
    className,
    location,
    staticContext,
    theme,
    title,
    ...rest
}) => rest;

const Login = ({ classes, className, loginForm, theme, backgroundImage, ...props }) => {

    return (
        <MuiThemeProvider theme={createMuiTheme(theme)}>
            <div className={classnames(classes.main, className)} style={{ backgroundImage: `url(${backgroundImage})` }} {...sanitizeRestProps(props)} >
                <div className={`${classes.cardWrapper}`}>
                    <OrderCreateCard />
                    <LoginCard loginForm={loginForm} className={classes.card} />
                </div>
                <Notification />
            </div>
        </MuiThemeProvider>);
}



Login.propTypes = {
    authProvider: PropTypes.func,
    backgroundImage: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    input: PropTypes.object,
    loginForm: PropTypes.element,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
};

Login.defaultProps = {
    theme: defaultTheme,
    loginForm: <LoginForm />,
};

export default withStyles(styles)(Login);
