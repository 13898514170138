import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

export default (args) => {
    return apolloCoreClient.query({
        query: gql`
            query findOrders($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: OrderFilter) {
                findOrders(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                    id
                    humanId
                    statusRef
                    customer {
                        id
                        name
                        phone
                    }
                    addrManual
                    updatedAt
                }
            }
        `,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};