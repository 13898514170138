import React from 'react';
import PropTypes from 'prop-types';
import { Link, translate } from 'react-admin';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import sanitizeRestProps from './sanitizeRestProps';

const styles = theme => ({
    link: {
        color: theme.palette.primary.main,
    },
});

const stopPropagation = e => e.stopPropagation();

const LinkField = ({
    addLabel,
    allowEmpty,
    basePath,
    children,
    className,
    classes = {},
    emptyValue,
    record,
    reference,
    resource,
    source, 
    linkType, 
    translate,
    ...props 
}) =>{
    const referenceRecord = get(record, source)
    
    if (!referenceRecord) {
        if (!emptyValue) {
            return null;
        } else if (typeof emptyValue === 'string') {
            return <EmptyFieldPlaceholder placeholder={emptyValue} className={className} {...sanitizeRestProps(children.props)} />;
        } else {
            return React.cloneElement(emptyValue, { className, record, ...sanitizeRestProps(children.props)});
        }
    }

    const rootPath = basePath.replace(resource, reference);
    const resourceLinkPath = !linkType
    ? false
    : `${rootPath}/${encodeURIComponent(referenceRecord.id)}/${linkType}`;

    if (resourceLinkPath) {
        return (
            <Link
                to={resourceLinkPath}
                className={className}
                onClick={stopPropagation}
                className={className}

            >
                {React.cloneElement(children, {
                    className: classnames(
                        children.props.className,
                        classes.link // force color override for Typography components
                    ),
                    record: referenceRecord,
                    referenceRecord: record,
                    resource: reference,
                    allowEmpty,
                    basePath,
                    ...sanitizeRestProps(props),
                })}
            </Link>
        );
    }

    return React.cloneElement(children, {
        className: `${className} ${children.props.className}`,
        record: referenceRecord,
        referenceRecord: record,
        resource: reference,
        allowEmpty,
        basePath,
        ...sanitizeRestProps(props),
    });
} 


LinkField.propTypes = {
    addLabel: PropTypes.bool,
    allowEmpty: PropTypes.bool.isRequired,
    basePath: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    classes: PropTypes.object,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    reference: PropTypes.string.isRequired,
    resource: PropTypes.string,
    source: PropTypes.string.isRequired,
    linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};


LinkField.defaultProps = {
    allowEmpty: false,
    classes: {},
    linkType: 'show',
    record: {},
};

const EnhancedLinkField = withStyles(styles)(LinkField);

EnhancedLinkField.defaultProps = {
    addLabel: true,
};

export default EnhancedLinkField;



const EmptyFieldPlaceholder = translate(({ placeholder, translate, ...props}) => (<Typography variant="body1" color="textSecondary" {...props}>{translate(placeholder)}</Typography>));