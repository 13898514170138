import React from 'react';
import { stringify } from 'query-string';
import IconButton  from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        fontSize: "0.875rem",
        color: "inherit",
      },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
});

const ButtonLink = ({ record = {}, source, to, basePath, filter, className, classes, ...props }) =>{
 
    const {page=1, perPage=10, filterValues={}} = props;
    

    const FILTER = (record.id !== "Total") 
    ? {
        ...filterValues,
        ...filter,
        districtIdOrCityId: record.id,
    }
    : {
        ...filterValues,
        ...filter,
    }

    return  (

        <div className={classes.wrapper}>
          <IconButton className={classes.button} component={Link} to={{
              pathname: to,
              search: stringify({
                  page: page,
                  perPage: perPage,
                  filter: JSON.stringify(FILTER),
              }),
          }}>
            {record[source]}
          </IconButton >
       </div>
    
    );
} 

ButtonLink.defaultProps = {
    source: 'resultRef',
    pathname:'/',

};


export default withStyles(styles)(ButtonLink);