import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';
import lightBlue from '@material-ui/core/colors/lightBlue';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const rowStyle = (record, index, defaultStyle = {}) => {
    if (!record) return defaultStyle
    if (record.isArchive)
        return {
            ...defaultStyle,
            borderLeftColor: grey[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
            backgroundColor:grey[200],
            '&:hover': {
                backgroundColor: grey[300],
            },
        };
    if (record.statusRef === 'new')
        return {
            ...defaultStyle,
            borderLeftColor: amber[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
        };
    if (record.statusRef === 'processed')
        return {
            ...defaultStyle,
            borderLeftColor: lightBlue[300],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
        };
    if (record.statusRef === 'inWork')
        return {
            ...defaultStyle,
            borderLeftColor: blue[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
        };
    if (record.statusRef === 'completed')
        return {
            ...defaultStyle,
            borderLeftColor: green[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
        };
    if (record.statusRef === 'cancelled')
        return {
            ...defaultStyle,
            borderLeftColor: red[500],
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
        };
    return defaultStyle;
};

export default rowStyle;