import React from 'react';
import Chip from '@material-ui/core/Chip';
import { translate } from 'react-admin';

const MutationField = ({ record = {}, translate }) => {
    
    const style = {
        CREATED:{
            backgroundColor:"#4CAF50",
            color:"#fff"
        },
        UPDATED:{
            backgroundColor:"#2F80ED",
            color:"#fff"
        },
        DELETED:{
            backgroundColor:"#DB4437",
            color:"#fff"
        }
    }
    
    return (<Chip label={translate(`resources.OrderHistory.mutations.${record.mutation}`)} style={style[record.mutation]} />);
}


MutationField.defaultProps = {
    source: 'mutation',
    addLabel: true
};

export default translate(MutationField);




