import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { AutocompleteInput, NumberInput, ReferenceInput, TextInput } from 'react-admin';
import { getFormValues, FormName, change } from 'redux-form';
import get from 'lodash/get';
import { ability } from '../../ability';



const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    flex: { display: "flex" },
    spacer: { width: "1em" },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});



export const SITypeInput = ({ form, formData, change, siType, siTypeId, designation, className, classes, ...props }) => {

    const [manual, setManual] = useState(false);
    const blocked = !manual || !!siTypeId;

    useEffect(() => {
        if (!siType) return;

        change(form, 'siTypeNumber', siType.number);
        change(form, 'siTypeMPIYears', siType.mpiyears);
        change(form, 'siTypeMPIMonths', siType.mpimonths);
        change(form, 'siTypeName', siType.name);
        change(form, 'siTypeManufacturerTotal', siType.manufacturerTotal);
    }, [siType])

    const handleManual = (event) => {
        if (!manual) {
            change(form, 'siTypeId', null);
        }

        setManual(!manual);
    };
    return (
        <div className={classes.root}>
            <div className={classes.flex}>
                {ability.can('update', "Order", "siTypeId") && <ReferenceInput {...props} className={className} source="siTypeId" reference="SIType" filter={{ designation }} filterToQuery={searchText => ({ number: searchText })} allowEmpty options={{ disabled: !blocked }} >
                    <AutocompleteInput optionText="number" />
                </ReferenceInput>}

                <Typography className={classes.message} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                    <span>Это поле получает данные из госреестра ФГИС "АРШИН" УТВЕРЖДЁННЫЕ ТИПЫ СРЕДСТВ ИЗМЕРЕНИЙ, согласно выбранной Модификации. Если список Номеров СИ пуст, проверьте выбранную модификацию или введите данные вручную. Дополнительная информация на <a href="https://fgis.gost.ru/fundmetrology/registry/4">https://fgis.gost.ru/fundmetrology/registry/4</a></span>
                </Typography>
            </div>

            <FormControlLabel control={<Checkbox checked={manual} onChange={handleManual} value="manual" color="primary" />} label="Ввести данные типа Средства измерения вручную" />

            <Grid container spacing={16}>
                <Grid item xs={12} sm={4}>
                    {ability.can('update', "Order", "siTypeNumber") && <TextInput {...props} disabled={blocked} source="siTypeNumber" options={{ fullWidth: true }} />}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {ability.can('update', "Order", "siTypeMPIYears") && <NumberInput {...props} disabled={blocked} source="siTypeMPIYears" step={1} options={{ fullWidth: true }} />}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {ability.can('update', "Order", "siTypeMPIMonths") && <NumberInput {...props} disabled={blocked} source="siTypeMPIMonths" step={1} options={{ fullWidth: true }} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {ability.can('update', "Order", "siTypeName") && <TextInput {...props} disabled={blocked} source="siTypeName" options={{ fullWidth: true }} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {ability.can('update', "Order", "siTypeManufacturerTotal") && <TextInput {...props} disabled={blocked} source="siTypeManufacturerTotal" options={{ fullWidth: true }} />}
                </Grid>
            </Grid>
        </div>


    )
};

const mapStateToProps = (state, { form, record }) => {

    const formData = getFormValues(form)(state) || record;
    const siTypeId = get(formData, ["siTypeId"])
    const meterId = get(formData, ["meter", "id"])
    const siType = siTypeId ? get(state, ["admin", "resources", "SIType", "data", siTypeId]) : undefined;
    const designation = meterId ? get(state, ["admin", "resources", "Meter", "data", meterId, "name"]) : undefined;

    return {
        formData,
        siType,
        siTypeId,
        designation,
    }
};

const mapDispatchToProps = {
    change,
}






const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
);

const ConnectedFormDataConsumerView = enhance(SITypeInput);

const FormDataConsumer = (props) => (
    <FormName>
        {({ form }) => <ConnectedFormDataConsumerView form={form} {...props} />}
    </FormName>
);




export default FormDataConsumer;
