import React, { Fragment } from 'react';
import { AutocompleteInput, Edit, Toolbar, SaveButton, DeleteButton, TabbedForm, NumberInput, FormTab, LongTextInput, maxLength, ReferenceInput, SelectInput, REDUX_FORM_NAME, TextInput, DateInput, BooleanInput, translate, required, } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateIcon from '@material-ui/icons/InsertInvitation';
import Title from './Title';
import FastView from './FastView';
import AddressInput from './AddressInput';
import UserReferenceInput from '../User/UserReferenceInput';
import SaveWithProcessButton from '../Buttons/SaveWithProcessButton';
import SaveWithInWorkButton from '../Buttons/SaveWithInWorkButton';
import SaveWithCompleteButton from '../Buttons/SaveWithCompleteButton';
import SaveWithCancelButton from '../Buttons/SaveWithCancelButton';
import ArchiveButton from '../Buttons/ArchiveButton';
import { ability } from '../../ability';
import EnumInput from '../Inputs/EnumInput';
import FileChip from '../Fields/FileChip';
import FileInput from '../Inputs/FileInput';
import { requiredCustom } from '../../utils';

import SITypeInput from './SITypeInput';
import CCSVTypeInput from './CCSVTypeInput';
import SUTypeInput from './SUTypeInput';
import GSITypeInput from './GSITypeInput';





// class Order {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }

const styles = theme => ({
    mr2: { marginRight: ".5rem" },
    mlAuto: { marginLeft: "auto" },
    option: {
        width: " 100%",
        display: "flex",
        justifyContent: "space-between",
    },
    captionText: {
        ...theme.typography.caption,
        lineHeight: "unset",
    },
    deleteButton: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
});

const validateOrderEdit = (values, { translate, clearSubmitErrors }) => {
    const errors = {};
    clearSubmitErrors();
    if (values.statusRef === 'completed' || values.statusRef === 'cancelled') {
        if (!values.resultRef) errors.resultRef = translate('resources.Order.errors.result');
        if (!values.invoice && values.isSealNotInstalled === false) errors.invoice = translate('resources.Order.errors.invoice');
        if (values.files < 1) errors.files = 'resources.Order.errors.files';
    }

    return errors;
};

const OptionResult = withStyles(styles)(({ record, classes }) => {
    let caption = ""
    switch (record.id) {
        case 'verified':
        case 'notVerified':
        case 'noneSeal':
        case 'swap':
            caption = "Выполнена"
            break;
        case 'refused':
        case 'duplicate':
            caption = "Отменена"
            break;
        default:
            caption = "";
    }

    return (
        <div className={`${classes.option}`}>
            <span className={`${classes.mr2}`}>{record.description}</span>
            <span className={`${classes.captionText}`}>{caption}</span>
        </div>
    )
});

const EditLayoutView = ({ permissions, classes, translate, form, ...props }) => {

    return (
        <Fragment>
            <FastView {...props} />
            <TabbedForm toolbar={<EditToolbar permissions={permissions} form={form} />} /*validate={validateOrderEdit}*/ {...props} >



                <FormTab label="resources.Order.tabs.general">
                    {ability.can('update', "Order", "status") &&
                        <ReferenceInput source="statusRef" reference="Status" sort={{ field: 'description', order: 'ASC' }} >
                            <SelectInput optionText="description" />
                        </ReferenceInput>}
                    {ability.can('update', "Order", "completedDeadlineAt") && <DateInput source="completedDeadlineAt" helperText={translate("resources.Order.helper.completedDeadlineAt")} />}
                    {ability.can('update', "Order", "customer") && <UserReferenceInput source="customer.id" filter={{ role: "CUSTOMER" }} validate={requiredCustom()} />}
                    {ability.can('update', "Order", "addrManual") && <AddressInput />}
                    {ability.can('update', "Order", "verifier") && <UserReferenceInput source="verifier.id" filter={{ role: "VERIFIER" }} />}
                    {ability.can('update', "Order", "dispatcher") && <UserReferenceInput source="dispatcher.id" filter={{ roles: ["SENIORDISPATCHER", "DISPATCHER"] }} />}
                    {ability.can('update', "Order", "comment") && <LongTextInput source="comment" helperText={translate("resources.Order.helper.comment")} validate={maxLength(200)} />}

                </FormTab>
                <FormTab label="resources.Order.tabs.measuringInstrument" path="measuringInstrument">

                    {ability.can('update', "Order", "meterRef") && <ReferenceInput source="meter.id" reference="Meter" sort={{ field: 'name', order: 'ASC' }} allowEmpty >
                        <AutocompleteInput />
                    </ReferenceInput>}
                    {ability.can('update', "Order", "miTypeNumber") && <SITypeInput source="siTypeId" />}
                    {ability.can('update', "Order", "miYear") && <NumberInput source="manufactureYear" inputProps={{ min: "1900", max: "2099" }} step={1} />}
                    {ability.can('update', "Order", "miManufactureNum") && <TextInput source="manufactureNum" />}
                    {ability.can('update', "Order", "meterLocation") && <EnumInput source="meterLocation" Enum="MeterLocation" allowEmpty emptyValue={null} />}
                    {ability.can('update', "Order", "meterUninstallAt") && <DateInput source="meterUninstallAt" />}

                </FormTab>
                {ability.can("Edit", { constructor: { name: "Order" }, tab: "result" }) &&
                    <FormTab label="resources.Order.tabs.result" path="result">

                        {ability.can('update', "Order", "resultRef") && <ReferenceInput source="resultRef" reference="Result" sort={{ field: 'description', order: 'DESC' }} allowEmpty><SelectInput optionText={<OptionResult />} /></ReferenceInput>}
                        {ability.can('update', "Order", "resultDesc") && <LongTextInput source="resultDesc" helperText={translate("resources.Order.helper.resultDesc")} validate={maxLength(200)} />}
                        {ability.can('update', "Order", "personalAccount") && <TextInput source="personalAccount" />}
                        {ability.can('update', "Order", "invoice") && <TextInput source="invoice" />}
                        {ability.can('update', "Order", "certificateNumber") && <TextInput source="certificateNumber" />}
                        {ability.can('update', "Order", "stickerNumber") && <TextInput source="stickerNumber" />}
                        {ability.can('update', "Order", "isSealNotInstalled") && <BooleanInput source="isSealNotInstalled" fullWidth />}
                        {ability.can('update', "Order", "isSIOwnedByVerifier") && <BooleanInput source="isSIOwnedByVerifier" fullWidth />}
                        {ability.can('update', "Order", "signMi") && <BooleanInput source="signMi" fullWidth />}
                        {ability.can('update', "Order", "signPass") && <BooleanInput source="signPass" fullWidth />}
                        <CCSVTypeInput source="ccsv" />
                        <SUTypeInput source="suTypeid" />
                        <GSITypeInput source="gsiTypeId" />

                    </FormTab>
                }
                {ability.can("Edit", { constructor: { name: "Order" }, tab: "files" }) &&
                    <FormTab label="resources.Order.tabs.files" path="files">
                        {ability.can('update', "Order", "files") &&
                            <FileInput source="files" multiple={true} addLabel={false}>
                                <FileChip canOpen source="url" title="desc" />
                            </FileInput>
                        }
                    </FormTab>
                }
            </TabbedForm>
        </Fragment>
    )
};

const mapStateToProps = (state, { record }) => ({
    form: getFormValues(REDUX_FORM_NAME)(state) || record,
});

const enhance = compose(
    connect(mapStateToProps),
    translate,
    pure
);


const EditLayout = enhance(EditLayoutView);



const OrderEdit = ({ permissions, ...props }) => {
    return (
        <Edit title={<Title {...props} />} {...props} >
            <EditLayout permissions={permissions} />
        </Edit>
    );
}


export const EditToolbar = pure(withStyles(styles)(({ classes, submitOnEnter, form, ...props }) => {
    const { permissions, record } = props;

    return (
        <Toolbar {...props} >
            { ability.can("process", { constructor: { name: "Order" }, status: record.statusRef }) && !record.isArchive && record.statusRef !== "processed" && (
                <SaveWithProcessButton className={`${classes.mr2}`} redirect="list" submitOnEnter={false} />
            )}
            {ability.can("inWork", { constructor: { name: "Order" }, status: record.statusRef }) && !record.isArchive && record.statusRef !== "inWork" && (
                <SaveWithInWorkButton className={`${classes.mr2}`} redirect="list" submitOnEnter={false} />
            )}
            { ability.can("complete", { constructor: { name: "Order" }, status: record.statusRef }) && !record.isArchive && record.statusRef !== "completed" && (
                <SaveWithCompleteButton className={`${classes.mr2}`} redirect="list" submitOnEnter={false} disabled={form.resultRef !== 'verified' && form.resultRef !== 'notVerified' && form.resultRef !== 'noneSeal' && form.resultRef !== 'swap'} />
            )}
            { ability.can("cancel", { constructor: { name: "Order" }, status: record.statusRef }) && !record.isArchive && record.statusRef !== "cancelled" && (
                <SaveWithCancelButton className={`${classes.mr2}`} redirect="list" submitOnEnter={false} disabled={form.resultRef !== 'refused' && form.resultRef !== 'duplicate'} />
            )}
            { ability.can('update', { constructor: { name: "Order" }, status: record.statusRef }) && !record.isArchive && (
                <SaveButton className={`${classes.mr2}`} redirect="list" submitOnEnter={true} variant="outlined" />
            )}
            { ability.can('update', { constructor: { name: "Order" }, status: record.statusRef }) && !record.isArchive && (
                <SaveButton className={`${classes.mr2}`} redirect={false} label="raExt.action.save_and_continue" submitOnEnter={true} variant="outlined" />
            )}
            { ability.can("archive", { constructor: { name: "Order" }, status: record.statusRef }) && (
                <ArchiveButton redirect="list" record={props.record} resource={props.resource} className={`${classes.mlAuto} ${classes.mr2}`} />
            )}
            { ability.can("delete", { constructor: { name: "Order" }, status: record.statusRef }) && (
                <WrappedDeleteButton redirect="list" className={classes.deleteButton} />
            )}
        </Toolbar>
    )
}));

const WrappedDeleteButton = ({ submitOnEnter, ...props }) => (<DeleteButton {...props} />)

export default OrderEdit;