import React, { Fragment } from 'react';
import {
    AutocompleteArrayInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceArrayInput,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,    
    required,
    minLength,
    email,
} from 'react-admin';
import { ability } from '../../ability'
import { AddressSuggestionComponent, AddressCheckComponent } from '../Order/AddressComponents';
import CheckBoxGroupInputWithCheckAll from '../Inputs/CheckBoxGroupInputWithCheckAll';
import TypeField from '../Customer/TypeField';
import PaymentField from '../Customer/PaymentField';
import PhoneInput from '../Inputs/PhoneInput';
import FastView from './FastView';
import NotificationTagsInput from './NotificationTagsInput';
import { connect } from 'react-redux';


const arrTypes = [
    { type: 'PHIS'},
    { type: 'JURE'},
];

const arrPayments = [
    { payment: 'CASH'},
    { payment: 'CASHLESS'},
]; 

const validateName = [required(), minLength(2)];
const validateEmail = [ email()];

const EditLayoutView = ({ classes, toolbar, ...props }) => {
    const { record } = props;


    return (
        <Fragment>
            <FastView {...props}/>
            <TabbedForm toolbar={<EditToolbar />} {...props} >
                <FormTab label="resources.User.tabs.general" >
                    <TextInput source="name" fullWidth validate={validateName}/>  
                    {/* <TextInput source="phone" validate={validatePhone} inputProps={{ autocomplete: "new-password"}} /> */}
                    <PhoneInput source="phone" inputProps={{ autoComplete: "new-password"}}/>
                    <TextInput source="email" type="email" validate={validateEmail} inputProps={{ autoComplete: "new-password"}}/>
                    <TextInput source="password" type="password" inputProps={{ autoComplete: "new-password"}} />
                </FormTab>
                {(record.role === 'CUSTOMER') && (
                <FormTab label="resources.User.roles.CUSTOMER" path="customer">
                    <SelectInput key="type" source="customer.type" choices={arrTypes} optionValue="type" optionText={<TypeField />} validate={required()}/>
                    <FormDataConsumer style={{display:"flex", flexDirection: "column"}}>{
                        ({ formData, ...rest }) => {  
                            if (!!formData.customer && (formData.customer.type === undefined || formData.customer.type === "JURE")) {                    
                                return [
                                    <TextInput key="organizationName" source="customer.organizationName" {...rest} />,
                                    <TextInput key="organizationInn" source="customer.organizationInn" {...rest} />,
                                    <SelectInput key="payment" source="customer.payment" choices={arrPayments} optionValue="payment" optionText={<PaymentField />}  {...rest} />,
                                ];    
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>)}
                <FormTab label="resources.User.tabs.accessLocation" path="accessLocation">
                    { ability.can('update', "User", "regionIds") && (
                    <ReferenceArrayInput source="regionIds" reference="Location" filter={{ type: 'REGION' }} options={{ fullWidth: true }}>
                        <AutocompleteArrayInput suggestionComponent={AddressSuggestionComponent} optionText={choice => `${choice.name} ${choice.typeShort}`}  />
                    </ReferenceArrayInput>
                    )}
                    { ability.can('update', "User", "districtIdOrCityIds") && (
                    <FormDataConsumer>{
                        ({ formData, className, ...rest }) =>
                        <ReferenceArrayInput key={formData.regionIds} source="districtIdOrCityIds" reference="Location" filter={{ type: 'DISTRICT',  parentIds: !!formData.regionIds ? formData.regionIds : []}} perPage={1000} {...rest} >
                            <CheckBoxGroupInputWithCheckAll optionText={AddressCheckComponent} />
                        </ReferenceArrayInput>
                    }
                    </FormDataConsumer>
                    )}
                </FormTab>

                {ability.can('read', "Notification") && (
                <FormTab label="resources.User.tabs.notifications" path="notifications">
                   <NotificationTagsInput source="notificationTags" fullWidth/>
                </FormTab>
                )}
            </TabbedForm>
        </Fragment>
    )
};

const connectUserProfile = ({user, dispatch, staticContext, ...props}) => {
    if (!user) return null;
    return (
        <Edit 
            title="resources.Profile.name"
            id={user.id}
            resource="Profile"
            basePath="/Profile"
            redirect={false}
            {...props}
        >
            <EditLayoutView />
        </Edit>
    );
}

const mapStateToProps = state => {
    return { user: state.user }
};

const ProfileEdit = connect(mapStateToProps)(connectUserProfile);

export const EditToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton redirect={false}/>
        </Toolbar>
    )
};

export default {
    edit: ProfileEdit
};