import React from 'react';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';

const MeterField = translate(({ record, referenceRecord, className, translate, addLabel, ...props }) => {
    if (!record) return null;
    return (<Typography className={className} noWrap {...sanitizeRestProps(props)}>{`${translate(`resources.Meter.enums.type.${record.type}`)} ${record.name}`}</Typography>);
});

MeterField.defaultProps = {
    addLabel: true,
};

export const MeterWithLocationField = translate(({ record, referenceRecord, className, translate, ...props }) => {
    return (
        <div>
            <Typography className={className} noWrap>{(record && record.id) ? `${translate(`resources.Meter.enums.type.${record.type}`)} ${record.name}` : translate("resources.Meter.empty")}</Typography>
            <Typography variant="caption" >{translate(`resources.Order.enums.meterLocation.${(!!referenceRecord.meterLocation) ? referenceRecord.meterLocation : "UNKNOWN"}`)}</Typography>
        </div>
    );
});

MeterWithLocationField.defaultProps = {
    addLabel: true,
};

export const OptionMeter = translate(({ record, translate }) => {
    if (!record) return null;
    return (<span>{`${translate(`resources.Meter.enums.type.${record.type}`)} ${record.name}`}</span>);
});

export const optionMeter = translate => choice => `${translate(`resources.Meter.enums.type.${choice.type}`)} ${choice.name}`;



export default MeterField;