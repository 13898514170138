import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
// import { archive, unarchive } from '../Actions/ArchiveAction';

import { Button, crudUpdate } from 'react-admin';
import { startUndoable } from 'ra-core';

const styles = theme => ({
    button: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    archiveButton: {
        color: theme.palette.error.main,
        borderColor: fade(theme.palette.error.main, 0.5),
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            borderColor: theme.palette.error.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },

    },
    unarchiveButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            borderColor: theme.palette.primary.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
});

class ArchiveButton extends Component {


    handleArchive = () => {
        const { startUndoable, resource, record, basePath, redirect } = this.props;
        startUndoable(
            // archive(resource, record.id, record, basePath, redirect)
            crudUpdate(resource, record.id, { id: record.id, isArchive: true, authStatus: "BANNED" }, record, basePath, redirect)
        );
    };

    handleUnarchive = () => {
        const { startUndoable, resource, record, basePath, redirect } = this.props;
        startUndoable(
            // unarchive(resource, record.id, record, basePath, redirect)
            crudUpdate(resource, record.id, { id: record.id, isArchive: false }, record, basePath, redirect)
        );

    };

    render() {
        const { record, className, classes } = this.props;
        if (!record || record.isArchive === undefined) return null;


        return !record.isArchive
            ? (<Button
                size="small"
                variant="outlined"
                label="raExt.action.archive"
                onClick={this.handleArchive}
                className={`${classes.button} ${classes.archiveButton} ${className}`}
            >
                <ArchiveIcon />
            </Button>)
            : (<Button
                size="small"
                variant="outlined"
                label="raExt.action.unarchive"
                onClick={this.handleUnarchive}
                className={`${classes.button} ${classes.unarchiveButton} ${className}`}
            >
                <UnarchiveIcon />
            </Button>);

    }
}

ArchiveButton.propTypes = {
    record: PropTypes.object,
    startUndoable: PropTypes.func,

};

const enhance = compose(
    withStyles(styles),
    connect(
        null,
        {
            startUndoable,
        }
    )
);

export default enhance(ArchiveButton);