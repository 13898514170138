import Icon from '@material-ui/icons/AttachMoney';
import OrderList from './OrderList';
import OrderCreate from './OrderCreate';
import OrderEdit from './OrderEdit';
import OrderShow from './OrderShow';

const OrderIcon = Icon;


export {
    OrderIcon,
    OrderList,
    OrderShow,
    OrderCreate,
    OrderEdit,
};


