import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import LoginCardImage from '../../files/LoginCardImage.svg';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    media: {
        height: 140,
        backgroundSize: "unset",
    },
    titleContent: {
        maxWidth: 256,
        textAlign: "center",
    },
    createButton: {
        color: theme.palette.getContrastText(green["700"]),
        backgroundColor: green["500"],
        '&:hover': {
            backgroundColor:  green["700"],
        },
    },
    mtAuto: { marginTop:"auto" },
    
});

const LoginCard = withStyles(styles)(({classes, className, loginForm, ...props}) => {

  return (
    <Card className={className}>
        <CardMedia
          className={classes.media}
          image={LoginCardImage}
          title="Вход на портал"
        />
        <CardContent  className={classes.titleContent}>
          <Typography variant="headline">
            Вход на портал
          </Typography>
        </CardContent>
        {loginForm}
    </Card>
  );
});

export default LoginCard;


{/* <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    {loginForm}
                </Card> */}