import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate, userLogin } from 'ra-core';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = (theme) => ({
    input: {
        marginTop: '1em',
    },
    icon: {
        height: 20,
        width: 20,
        marginRight: theme.spacing.unit,
    },
    py0: { paddingTop:0, paddingBottom:0}
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {}, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const login = (auth, dispatch, { redirectTo }) => dispatch(userLogin(auth, redirectTo));



const LoginForm = ({ classes, isLoading, handleSubmit, translate }) => (
    <form onSubmit={handleSubmit(login)}>
        <CardContent className={classes.py0}>
            <div className={classes.input}>
                <Field
                    autoFocus
                    id="login"
                    name="login"
                    component={renderInput}
                    label={translate('raExt.auth.phoneOrEmail')}
                    disabled={isLoading}
                />
            </div>
            <div className={classes.input}>
                <Field
                    id="password"
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={isLoading}
                    autocomplete="current-password"
                />
            </div>
        </CardContent>
        <CardContent>
            <Button
                fullWidth
                variant="raised"
                type="submit"
                color="secondary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                {translate('ra.auth.sign_in')}
            </Button>
        </CardContent>
    </form>
);

LoginForm.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.login)
                errors.login = translate('ra.validation.required');
            if (!values.password)
                errors.password = translate('ra.validation.required');
            return errors;
        },
    })
);

export default enhance(LoginForm);
