import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import DeadlineOnIcon from '@material-ui/icons/Timer';
import DeadlineOffIcon from '@material-ui/icons/TimerOff';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment'
import 'moment/locale/ru';

const getStatus = (completedAt, completedDeadlineAt) => {
    if (!!completedAt) {
        if ( moment(completedAt).endOf("day").isSameOrBefore(moment(completedDeadlineAt).endOf("day"))) return "info";
        if ( moment(completedAt).endOf("day").isAfter(moment(completedDeadlineAt).endOf("day"))) return "alarm"
        if ( moment(completedAt).endOf("day").isAfter(moment(completedDeadlineAt).subtract(7, 'days').endOf("day"))) return "danger"
        if ( moment(completedAt).endOf("day").isAfter(moment(completedDeadlineAt).subtract(14, 'days').endOf("day"))) return "warning"
    } else {
        if ( moment().endOf("day").isAfter(moment(completedDeadlineAt).endOf("day"))) return "alarm"
        if ( moment().endOf("day").isAfter(moment(completedDeadlineAt).subtract(7, 'days').endOf("day"))) return "danger"
        if ( moment().endOf("day").isAfter(moment(completedDeadlineAt).subtract(14, 'days').endOf("day"))) return "warning"       
    }

    return "info";
} 

const getTitle = (status, completedDeadlineAt, translate) => {
    switch (status){
        case 'alarm':
            return `${translate('resources.Order.helper.isDeadlineAt')} ${moment(completedDeadlineAt).endOf("day").fromNow()}`;
            break;
        case 'danger':
        case 'warning':
            return `${translate('resources.Order.fields.completedDeadlineAt')} ${moment(completedDeadlineAt).endOf("day").fromNow()}`;
            break;
        default:
            return `${translate('resources.Order.fields.completedDeadlineAt')} ${moment(completedDeadlineAt).format('L')}`
    }
} 

const deadlineIconStyles = theme => ({
    info: { color: theme.palette.grey['300'] },
    warning: { color: amber['500'] },
    danger: { color: red['500'] },
    alarm: { color: theme.palette.text.primary },
});

const DeadlineIconView = ({ className, classes, record, translate, ...props }) => {
    if (!record) return null;
    
    const status = getStatus(record.completedAt, record.completedDeadlineAt);
    const title = getTitle(status, record.completedDeadlineAt, translate );

    return (
        <Tooltip title={title} placement="top">
            {(status === "info") ? <DeadlineOffIcon className={classes[status]}/> : <DeadlineOnIcon className={classes[status]}/>}     
        </Tooltip> 
    );
} 

DeadlineIconView.propTypes = {
    addLabel: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

DeadlineIconView.defaultProps = {
    classes: {},
    record: {},
    addLabel: true,
};

export const DeadlineIcon = compose(
    translate,
    withStyles(deadlineIconStyles)
)(DeadlineIconView);



const deadlineChipStyles = theme => ({
    avatar:{
        color: "inherit",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    },
    info: {
        backgroundColor: theme.palette.grey['300'],
        color: theme.palette.getContrastText(theme.palette.grey['300']),
    },
    warning: { 
        backgroundColor: amber['500'],
        color: theme.palette.getContrastText(amber['500']),
    },
    danger: { 
        backgroundColor: red['500'],
        color: theme.palette.getContrastText(red['500']),
    },
    alarm: { 
        backgroundColor: theme.palette.grey['900'],
        color: theme.palette.getContrastText(theme.palette.grey['900']),
    },
});

const DeadlineChipView = ({ className, classes, record, translate, ...props }) => {
    if (!record) return null;

    const status = getStatus(record.completedAt, record.completedDeadlineAt);
    const title = getTitle(status, record.completedDeadlineAt, translate);

    return (
        <Chip avatar={<Avatar>{(status === "info") ? <DeadlineOffIcon className={classes[status]}/> : <DeadlineOnIcon className={classes[status]}/>}</Avatar>} label={title} classes={{avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className} ${classes[status]}`} />
    );
} 


DeadlineChipView.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    record: PropTypes.object,
  
};


DeadlineChipView.defaultProps = {
    classes: {},
    record: {},
};

export const DeadlineChip = compose(
    translate,
    withStyles(deadlineChipStyles)
)(DeadlineChipView);