import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { crudUpdate } from 'react-admin';
import FavoriteIcon from '@material-ui/icons/Star';
import UnfavoriteIcon from '@material-ui/icons/StarBorder';

const styles = theme => ({
    favoriteIcon: {},
    unfavoriteIcon: {}
});

class FavoriteButton extends Component { 
    isFavorite(udatedUser, id) {
        if(udatedUser && udatedUser.favoriteOrders && udatedUser.favoriteOrders.find) {
            return udatedUser.favoriteOrders.find(x =>  x.id === id );
        } else {
            return false;
        }
    }
    
    handleFavorite = (event) => {
        event.stopPropagation();
        
        const {  resource, record, basePath, crudUpdateAction, user } = this.props;      
        crudUpdateAction(resource, record.id, { id:record.id, isFavorite: true }, record, basePath, false)         
       
        if(!this.isFavorite(user,record.id)) {
            user.favoriteOrders.push({ id: record.id});
            //userLogin(user);
        }  
    };

    handleUnfavorite = (event) => {
        event.stopPropagation();
        
        const {  resource, record, basePath, crudUpdateAction, user } = this.props;        
        crudUpdateAction(resource, record.id, { id:record.id, isFavorite: false }, record, basePath, false) 

        if(this.isFavorite(user,record.id)) {
            user.favoriteOrders = user.favoriteOrders.filter(x => x.id !== record.id);
            //userLogin(user);
        }
    };

    render() {
        const { record, user, classes, favoriteColor } = this.props;
        if (!record) return null;
        

        if (!this.isFavorite(user,record.id)) {
            return (
            <IconButton className={classes.unfavoriteIcon} onClick={this.handleFavorite}>
                <UnfavoriteIcon />
            </IconButton> 
            )
        } else {
            return (         
            <IconButton className={classes.favoriteIcon} onClick={this.handleUnfavorite}>
                <FavoriteIcon />
            </IconButton>
            )
        }  
    }
}

FavoriteButton.defaultProps = {
    favoriteColor: 'inherit',
};

const mapStateToProps = state => {
    return { user: state.user }
};

const enhance = compose(  
    connect(
        mapStateToProps,
        {
            crudUpdateAction: crudUpdate                     
        }
    ),
    withStyles(styles)
);

export default enhance(FavoriteButton);