import React, { Fragment } from 'react';
import { CloneButton, EditButton, EmailField, Pagination, ReferenceArrayField, ReferenceManyField, Show, SingleFieldList, Tab, TabbedShowLayout, TextField, } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ability } from '../../ability'
import { DatagridUser } from '../Order/OrderList';
import { OrderHistoryDatagridForUser } from '../OrderHistory';
import CreateRelatedOrderButton from '../Buttons/CreateRelatedOrderButton';
import PhoneField from '../Fields/PhoneField';
import AddressChip from '../Fields/AddressChip';
import UserTabbedShowLayoutTabs from './UserTabbedShowLayoutTabs';
import FastView from './FastView';
import UserTitle from './Title';

// class User {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }

var styles = theme => ({
    contaiter: {
        zIndex: 2,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
});

const ShowLayout = (props) => {
    const { record } = props;
    return (
        <Fragment>
            <FastView {...props} />

            <TabbedShowLayout tabs={<UserTabbedShowLayoutTabs />} {...props}>
                {ability.can('Show', { constructor: { name: "User" }, tab: "general" }) &&
                    <Tab label="resources.User.tabs.general">
                        {ability.can('read', 'User', "name") && <TextField source="name" />}
                        {ability.can('read', 'User', "phone") && <PhoneField source="phone" noWrap />}
                        {ability.can('read', 'User', "email") && <EmailField source="email" />}
                    </Tab>
                }
                {(record.role === 'CUSTOMER' && ability.can('read', 'User', "customer.type")) && (
                    <Tab label="resources.User.roles.CUSTOMER" path="customer" >
                        <TextField source="customer.type" />{
                            (!!record.customer && record.customer.type === "JURE") && (
                                [
                                    <TextField source="customer.organizationName" {...props} />,
                                    <TextField source="customer.organizationInn" {...props} />,
                                    <TextField source="customer.payment" {...props} />
                                ])
                        }
                    </Tab>
                )}
                {ability.can('Show', { constructor: { name: "User" }, tab: "accessLocation" }) &&
                    <Tab label="resources.User.tabs.accessLocation" path="accessLocation">
                        {ability.can('read', 'User', 'counterparty') && <TextField source="counterparty.name" label="resources.User.fields.counterparty" />}
                        {ability.can('read', 'User', "regionIds") && (
                            <ReferenceArrayField source="regionIds" reference="Location">
                                <SingleFieldList linkType={'show'}>
                                    <AddressChip />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        )}
                        {ability.can('read', 'User', "districtIdOrCityIds") && (
                            <ReferenceArrayField source="districtIdOrCityIds" reference="Location">
                                <SingleFieldList linkType={'show'}>
                                    <AddressChip />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        )}

                    </Tab>
                }
                {ability.can('Show', { constructor: { name: "User" }, tab: "orders" }) &&
                    <Tab label="resources.User.tabs.orders" path="orders">
                        <ReferenceManyField
                            reference="Order"
                            target="oneOfUserMany"
                            addLabel={false}
                            perPage={10}
                            pagination={<Pagination />}
                        >
                            <DatagridUser />
                        </ReferenceManyField>
                    </Tab>
                }
                <Tab label="resources.User.tabs.history" path="history" >
                    <ReferenceManyField reference="OrderHistory" target="user.id" sort={{ field: 'createdAt', order: 'DESC' }} pagination={<Pagination />} addLabel={false} >
                        <OrderHistoryDatagridForUser />
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Fragment>

    )
};

const UserShowActions = withStyles(styles)(({ classes, className, basePath, data, resource }) => {
    if (!data) return null;
    return (
        <CardActions className={`${classes.contaiter}`}>
            { ability.can("Create", "Order") && <CreateRelatedOrderButton basePath={basePath} record={data} />}
            { ability.can("Create", { constructor: { name: "User" }, role: data.role }) && <CloneButton basePath={basePath} record={data} />}
            { ability.can("Edit", { constructor: { name: "User" }, role: data.role }) && <EditButton basePath={basePath} record={data} />}
        </CardActions >
    )
});

const UserShow = (props) => {
    return (
        <Show title={<UserTitle />} actions={<UserShowActions />} {...props}>
            <ShowLayout />
        </Show>
    );
};

export default UserShow;