import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudCreate, SaveButton, Toolbar } from 'react-admin';
import ProcessIcon from '@material-ui/icons/HeadsetMic';

// A custom action creator which modifies the values before calling the default crudCreate action creator
const createWithProcess = (values, resource, basePath, redirectTo) =>
    crudCreate(resource, { ...values, statusRef: "processed" }, basePath, redirectTo);

class CreateWithProcessButtonView extends Component {
    handleClick = () => {
        const { resource, basePath, handleSubmit, redirect, createWithProcess } = this.props;

        return handleSubmit(values => {
            createWithProcess(values, resource, basePath, redirect);
        });
    };

    render() {
        const { handleSubmitWithRedirect, createWithProcess, user, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                label="raExt.action.process"
                icon={<ProcessIcon />}
                {...props}
            />
        );
    }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = { createWithProcess };


const CreateWithProcessButton = connect( mapStateToProps, mapDispatchToProps, )(CreateWithProcessButtonView);


export default CreateWithProcessButton;