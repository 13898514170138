import gql from 'graphql-tag'
import apolloBlobClient from '../Blob/apolloBlobClient';
import apolloCoreClient from './apolloCoreClient';
import { BLOB_NAME_APP } from '../../appsettings';
import { selectMany } from '../../utils';
import { createUser as createAuthUser, updateUser as updateAuthUser } from '../Auth/graphqlProvider';

const REMOVE_FILES = gql`
    mutation($ids:[ID]) {
        multipleRemove(ids: $ids) {
            id
            url
            filename
    }
}
`

async function createCoreFiles(type, resource, files = []) {

    let newFiles = [];
    let newFilesBlob = files.filter(file => !!file.rawFile).map(file => file.rawFile);

    if (newFilesBlob.length > 0) {
        var response = await apolloBlobClient.mutate({
            mutation: gql`
                mutation multipleUpload($appname: String!, $namecatalog: String! $files: [Upload!]!) {
                    multipleUpload(appname: $appname, namecatalog: $namecatalog, files: $files) {
                        id
                        userId
                        catalog 
                        url
                        urlThumbnail 
                        ext
                        sizeInBytes
                        filename
                        mimetype
                    }
                }
            `,
            variables: { appname: BLOB_NAME_APP, namecatalog: resource, files: newFilesBlob },
            onError: (error) => {
                alert(error)
            }
        });

        newFiles = response.data.multipleUpload.map(file => ({ idBlob: file.id, url: file.url, urlThumbnail: file.urlThumbnail, desc: file.filename, catalog: file.catalog, ext: file.ext != null ? file.ext.replace('.', '').toLowerCase() : null, size: file.sizeInBytes, createdByAuth: file.userId, status: 'NEW' }));
    }

    return newFiles;
};


async function updateCoreFiles(type, resource, files = [], previous = []) {

    console.log('++++> START DECORATE updateCoreFiles');

    const currentFileIds = files.filter(file => !!file.idBlob).map(file => file.idBlob);
    const previousFileIds = previous.filter(file => !!file.idBlob);

    let removeFiles = previousFileIds.filter(file => currentFileIds.indexOf(file.idBlob) === -1).map(file => ({ ...file, status: 'REMOVE' }));
    let newFiles = [];
    let newFilesBlob = files.filter(file => !!file.rawFile).map(file => file.rawFile);

    if (removeFiles.length > 0) {
        await apolloBlobClient.mutate({
            mutation: REMOVE_FILES,
            variables: { ids: removeFiles.map(file => (file.idBlob)) },
            onError: (error) => {
                alert(error)
            }
        });
    }

    if (newFilesBlob.length > 0) {
        var response = await apolloBlobClient.mutate({
            mutation: gql`
                mutation multipleUpload($appname: String!, $namecatalog: String! $files: [Upload!]!) {
                    multipleUpload(appname: $appname, namecatalog: $namecatalog, files: $files) {
                        id
                        userId
                        catalog 
                        url
                        urlThumbnail 
                        ext
                        sizeInBytes
                        filename
                        mimetype
                    }
                }
            `,
            variables: { appname: BLOB_NAME_APP, namecatalog: resource, files: newFilesBlob },
            onError: (error) => {
                alert(error)
            }
        });

        newFiles = response.data.multipleUpload.map(file => ({ idBlob: file.id, url: file.url, urlThumbnail: file.urlThumbnail, desc: file.filename, catalog: file.catalog, ext: file.ext != null ? file.ext.replace('.', '').toLowerCase() : null, size: file.sizeInBytes, createdByAuth: file.userId, status: 'NEW' }));
    }

    console.log('++++> END DECORATE updateCoreFiles');

    return newFiles.concat(removeFiles);
};



export default async (type, resource, params) => {

    const { data, previousData } = params;

    if (resource === 'Profile') {

        if (type === 'UPDATE') {

            if (!!params.data.customer) {
                delete params.data.customer['__typename'];
            }

            const authId = params.data.authId;
            const pass = params.data.password;
            let authUser = {};

            if (!!params.data.customer) {
                delete params.data.customer['__typename'];
            }

            //если нет authId и пароля, то обновляем пользователя только в core
            if (!authId && !pass) {
                return { type, resource, params };
            }

            if (!authId && pass) {
                authUser = await createAuthUser(params.data);
            }

            if (authId) {
                authUser = await updateAuthUser(params.data);
            }

            params.data = {
                ...params.data,
                authId: authUser.id,
                authStatus: "ACTIVE",
            }

        }

    }

    if (resource === 'Counterparty') {
        switch (type) {
            case 'CREATE': {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
                break;
            }
            case 'UPDATE': {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }


    if (resource === 'User') {

        if (type === 'CREATE') {

            if (data.counterparty) { data.counterparty = (data.counterparty.id) ? { id: data.counterparty.id } : null }

            if (!params.data.password) {
                return { type, resource, params };
            } //если нет password, то создаем пользователя только в core

            const authUser = await createAuthUser(params.data);

            params.data = {
                ...params.data,
                authId: authUser.id,
                authStatus: "ACTIVE",
            }
        }

        if (type === 'UPDATE') {

            if (data.counterparty) { data.counterparty = (data.counterparty || previousData.counterparty) ? { id: data.counterparty.id } : null }

            const authId = params.data.authId;
            const pass = params.data.password;
            let authUser = {};

            if (!!params.data.customer) {
                delete params.data.customer['__typename'];
            }

            //если нет authId и пароля, то обновляем пользователя только в core
            if (!authId && !pass) {
                return { type, resource, params };
            }

            if (!authId && pass) {
                authUser = await createAuthUser(params.data);
            }

            if (authId) {
                authUser = await updateAuthUser(params.data);
            }

            params.data = {
                ...params.data,
                authId: authUser.id,
                authStatus: "ACTIVE",
            }

        }

    }

    if (resource === 'Order') {
        switch (type) {
            case 'CREATE': {
                if (params.data.customer) { params.data.customer = (params.data.customer.id) ? { id: params.data.customer.id } : null }
                if (params.data.meter) { params.data.meter = (params.data.meter.id) ? { id: params.data.meter.id } : null }
                if (!params.data.meterUninstallAt) { params.data.meterUninstallAt = null }
                break;
            }
            case 'UPDATE': {
                if (params.data.customer) { params.data.customer = (!params.data.customer.id && !params.previousData.customer) ? null : { id: params.data.customer.id } }
                if (params.data.verifier) { params.data.verifier = (!params.data.verifier.id && !params.previousData.verifier) ? null : { id: params.data.verifier.id } }
                if (params.data.dispatcher) { params.data.dispatcher = (!params.data.dispatcher.id && !params.previousData.dispatcher) ? null : { id: params.data.dispatcher.id } }
                if (params.data.meter) { params.data.meter = (!params.data.meter.id && !params.previousData.meter) ? null : { id: params.data.meter.id } }
                if (!params.data.meterUninstallAt) { params.data.meterUninstallAt = null }
                if (!!params.data.files) { params.data.files = await updateCoreFiles(type, resource, params.data.files, params.previousData.files); }
                break;
            }
            case 'DELETE':
            case 'DELETE_MANY': {
                let ids = (params.id !== undefined) ? [params.id] : ((params.ids !== undefined) ? params.ids : []);
                if (ids.length > 0) {
                    const orders = await apolloCoreClient.query({
                        query: gql`
                            query allOrders($page: Int, $perPage: Int, $filter: OrderFilter) {
                                allOrders(page: $page, perPage: $perPage, filter: $filter) {
                                    id
                                    files {
                                        idBlob
                                    }                           
                            }
                        }
                        `,
                        variables: {
                            page: 0, perPage: 100, filter: {
                                ids: ids
                            }
                        },
                        onError: (error) => {
                            alert(error)
                        }
                    });

                    var idsFiles = selectMany(orders.data.allOrders, orders => (orders.files.map(file => file.idBlob)));

                    //var idsFiles = orders.data.allOrders.selectMany(orders => (orders.files.map(file => file.idBlob)))

                    await apolloBlobClient.mutate({
                        mutation: REMOVE_FILES,
                        variables: { ids: idsFiles },
                        onError: (error) => {
                            alert(error)
                        }
                    });
                }
                break;
            }
            default: break;
        }
    }

    // for other request types and resources, fall back to the default request handler
    if (resource === 'Meter') {
        switch (type) {
            case 'CREATE': {
                if (!!params.data.documentation) params.data.documentation = await createCoreFiles(type, resource, params.data.documentation);
                break;
            }
            case 'UPDATE': {
                if (!!params.data.documentation) params.data.documentation = await updateCoreFiles(type, resource, params.data.documentation, params.previousData.documentation);
                break;
            }
            case 'DELETE':
            case 'DELETE_MANY': {
                let ids = (params.id !== undefined) ? [params.id] : ((params.ids !== undefined) ? params.ids : []);
                if (ids.length > 0) {
                    const meters = await apolloCoreClient.query({
                        query: gql`
                            query allMeters($filter: MeterFilter) {
                                allMeters(filter: $filter) {
                                    id
                                    documentation {
                                        idBlob
                                    }                           
                            }
                        }
                        `,
                        variables: { filter: { ids: ids } },
                        onError: (error) => alert(error)
                    });

                    var idsFiles = selectMany(meters.data.allMeters, meters => (meters.documentation.map(file => file.idBlob)));
                    //var idsFiles = meters.data.allMeters.selectMany(meters => (meters.documentation.map(file => file.idBlob)))

                    apolloBlobClient.mutate({
                        mutation: REMOVE_FILES,
                        variables: { ids: idsFiles },
                        onError: (error) => {
                            alert(error)
                        }
                    });
                }
                break;
            }
            default: break;
        }
    }

    return { type, resource, params };
};