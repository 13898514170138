import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import sanitizeRestProps from './sanitizeRestProps';

const PhoneField = ({ className, source, record = {}, ...rest }) => {

    const value = get(record, source) || "";
    let phoneNumber = parsePhoneNumberFromString(value, 'RU')
    if (phoneNumber) {
        phoneNumber = phoneNumber.formatNational()
      }

    return (
        <Typography className={className} {...sanitizeRestProps(rest)} >
            {phoneNumber || value}
        </Typography>
    );
};

PhoneField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
PhoneField.displayName = 'PhoneField';
const PurePhoneField = pure(PhoneField);

PurePhoneField.defaultProps = {
    addLabel: true,
};

export default PurePhoneField;
