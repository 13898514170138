import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from "react-admin";
import moment from 'moment'
import 'moment/locale/ru';

const choices = [
    { id: 2, name: moment.duration(2, 'years').humanize() },
    { id: 3, name: moment.duration(3, 'years').humanize() },
    { id: 4, name: moment.duration(4, 'years').humanize() },
    { id: 5, name: moment.duration(5, 'years').humanize() },
    { id: 6, name: moment.duration(6, 'years').humanize() },
    { id: 7, name: moment.duration(7, 'years').humanize() },
    { id: 8, name: moment.duration(8, 'years').humanize() },
    { id: 9, name: moment.duration(9, 'years').humanize() },
    { id: 10, name: moment.duration(10, 'years').humanize() },
    { id: 11, name: moment.duration(11, 'years').humanize() },
    { id: 12, name: moment.duration(12, 'years').humanize() },
]

const VerifyIntervalInput = props => <SelectInput {...props} choices={choices} />

VerifyIntervalInput.propTypes = {
    source: PropTypes.string,
};


export default VerifyIntervalInput;