import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin'
import { AUTH_TOKEN, AUTH_REFRESH_TOKEN, URI_AUTH } from '../appsettings'
import { apolloCoreClient } from './Core/graphqlProvider'
import gql from 'graphql-tag'
import ApolloClient from "apollo-boost"
import decodeJwt from 'jwt-decode'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ability, defineAbilityFor } from '../ability' //++доступ по роли
import { getAbility } from './caslProvider'

export const apolloAuthClient = new ApolloClient({
    uri: URI_AUTH
});


const LOGIN_MUTATION = gql`
  mutation LoginMutation($login: String!, $password: String!) {
    login(login: $login, password: $password) {
       token,
       refreshToken,
       user {
           id,
           email,
           phone,
           name,
           role
       }
    }
  }
`
const REFRESH_TOKEN_MUTATION = gql`
  mutation refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {       
       token,
       refreshToken,      
    }
  }
`

function checkUserBanned(checkUser) {
    if (checkUser.authStatus === "BANNED") throw new Error(`User is banned`);  
}

export function authProvider(type, params) {
    if (type === AUTH_LOGIN) {   
           
        return Promise.resolve(params).then(function(params){         
            const { login, password } = params

            const phoneNumber = parsePhoneNumberFromString(login, 'RU')

            return apolloAuthClient.mutate({
                mutation: LOGIN_MUTATION,
                variables: {
                    login: (phoneNumber && phoneNumber.isValid()) ? phoneNumber.number : login,
                    password: password,
                },
                onError: (error) => {
                alert(error)
                },
            })
        }).then(async({data, apolloAuthClient, error}) => { 

           
            if (error) throw new Error(error)
            
            const { token, refreshToken, user } = data.login;
                         
            const coreUser = await apolloCoreClient.mutate({
                mutation:  gql`
                    mutation loginUser($id: ID!, $email: String, $name: String, $phone: String, $role: String) {
                        loginUser(authId: $id, email: $email, name: $name, phone: $phone, role: $role) {
                            id
                            name
                            email
                            phone
                            role
                            regionIds
                            districtIdOrCityIds
                            authStatus                   
                        }
                    }
                `,
                variables: { 
                  ...user
                }     
            }); 

            checkUserBanned(coreUser.data.loginUser);

            localStorage.setItem(AUTH_TOKEN, token)
            localStorage.setItem(AUTH_REFRESH_TOKEN, refreshToken)

            defineAbilityFor(await getAbility())  //++доступ по роли  

            return coreUser
        }).then(function(result){
            
            const loginUser = result.data.loginUser
            if(!!loginUser) {
                localStorage.setItem('regionIds', loginUser.regionIds || []);
                localStorage.setItem('districtIdOrCityIds', loginUser.districtIdOrCityIds|| []);          
            }

            return Promise.resolve();
        })
        .catch((error) => {
          
            throw new Error(`Error.${error.message.replace('Error: ', '').replace('GraphQL error: ', '')}`) 
           
        })            
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem(AUTH_REFRESH_TOKEN);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem('regionIds');
        localStorage.removeItem('districtIdOrCityIds');
        return Promise.resolve()
    }
    if (type === AUTH_ERROR) {
      const status  = params.status;
      if (status === 401 || status === 403) {
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(AUTH_REFRESH_TOKEN);
            localStorage.removeItem('regionIds');
            localStorage.removeItem('districtIdOrCityIds');
            return Promise.reject()
      }
      return Promise.resolve()
    }
    if (type === AUTH_CHECK) {                      
        return ((localStorage.getItem(AUTH_TOKEN) && !IsExpiredToken(localStorage.getItem(AUTH_REFRESH_TOKEN))))
        ? Promise.resolve() 
        : Promise.reject();         
    }
    if (type === AUTH_GET_PERMISSIONS) {
        
        const token = localStorage.getItem(AUTH_TOKEN)

        // if (!token) {
        //     ability.update([]);
        //     return Promise.resolve({role: "GUEST"});
        // };

        const permissions = {};
        const decodedToken = decodeJwt(token);
        const role = decodedToken.role
        
       if (!!role) permissions.role = role

        return role ? Promise.resolve(permissions).then(async function(permissions){
            ability.update(defineAbilityFor(await getAbility()))            
            return Promise.resolve(permissions);
        }) : Promise.reject();
    }
    
    return Promise.reject('Unkown method') 
}

export async function GetAccessToken() {
    var authtoken = localStorage.getItem(AUTH_TOKEN);
    var authrefreshtoken = localStorage.getItem(AUTH_REFRESH_TOKEN);
    
    if(IsExpiredToken(authtoken) && !IsExpiredToken(authrefreshtoken, 'refresh')) {
        
        //console.log('====> ['+URI_AUTH+'] Start get NEW TOKEN');
        
        try {
            var response = await apolloAuthClient.mutate({
                mutation: REFRESH_TOKEN_MUTATION,
                variables: {
                    refreshToken: authrefreshtoken,                       
                },
                onError: (error) => {
                    alert(error)
                },
            });
    
            const { token, refreshToken } = response.data.refresh
                                 
            localStorage.setItem(AUTH_TOKEN, token) 
            localStorage.setItem(AUTH_REFRESH_TOKEN, refreshToken)
                    
            authtoken = token;

        } catch (error) {           
            localStorage.removeItem(AUTH_TOKEN);
             localStorage.removeItem(AUTH_REFRESH_TOKEN); 
            return null;
        }
         
    
        //console.log('====> ['+URI_AUTH+'] End get NEW TOKEN ('+token+')');
    }
    
    return authtoken;
}

export function IsExpiredToken(token, arg) {
    if(token) {
        const decodedToken = decodeJwt(token);
        const current_time = Date.now() / 1000;                
        const result = (decodedToken.exp - 20) < current_time;      
        return result;
    } else {     
        return true;
    }
}