import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import RegionIcon from '@material-ui/icons/Layers';
import DistrictIcon from '@material-ui/icons/Texture';
import CityIcon from '@material-ui/icons/LocationCity';
import TownIcon from '@material-ui/icons/Home';
import StreetIcon from '@material-ui/icons/Place';
import LocationIcon from '@material-ui/icons/Directions';

const styles = theme => ({
    root: {
        margin: ".125rem",
    },
    avatar:{
        color: theme.palette.grey["500"],
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    }
});


const getLocationIcon = level => {
    switch( level ){
        case 1:
        case 2:
            return (<RegionIcon />)
        break;
        case 3:
            return (<DistrictIcon />)
        break;
        case 4:
            return (<CityIcon />)
        break;
        case 5:
        case 6:
            return (<TownIcon />)
        break;
        case 7:
            return (<StreetIcon />)
        break;
        default:
            return (<LocationIcon />)
        break;
    }
}


const AddressChip = ({ className, classes, record}) => {
    if (!record) return null;
    
    return (
        <Chip clickable avatar={<Avatar>{getLocationIcon(record.level)}</Avatar>} label={`${record.typeShort}. ${record.name}`} classes={{root:classes.root, avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className}`} />
    );
}


export default withStyles(styles)(AddressChip);