import buildGraphQLProvider from 'ra-data-graphql-simple';
import apolloCoreClient from './apolloCoreClient';
import decorate from './decorate';
import buildQuery from './builtQuery';



const buildCoreProvider = () => buildGraphQLProvider({
  client: apolloCoreClient,
  buildQuery: buildQuery,
});


export const knownResources = ['Counterparty', 'User', 'Order', 'File', 'Meter', 'MeterLocation', 'Statistic', 'Status', 'Result', 'OrderHistory', 'Profile', 'VersionsInfo'];
export const dataProvider = (type, resource, params) => {
  return buildCoreProvider().then(async (provider) => {

    const { params: paramsD } = await decorate(type, resource, params);

    return provider(type, resource, paramsD)
  }
  )
};

export default {
  dataProvider,
  knownResources,
};