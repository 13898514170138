import gql from 'graphql-tag'
import { useSubscription } from '@apollo/react-hooks';
import { apolloNotificationsClient } from './graphqlProvider';

export const SUBSCRIBE_NOTIFICATIONS = gql`
    subscription notification( $filter: NotificationFilter, $mutations: [String] ) {
        notification( filter: $filter, mutations: $mutations ) {
            mutation
            node {            
                    id 
                app 
                title 
                body 
                data 
                actions 
                tag 
                requireInteraction 
                type 
                priority 
                ttl 
                ttlAt 
                timestamp
            }
        }
    }
`

export default (options) => useSubscription(SUBSCRIBE_NOTIFICATIONS, { client: apolloNotificationsClient, ...options });