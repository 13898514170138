import gql from 'graphql-tag'
import { apolloBlobClient } from './graphqlProvider';

export default (orders) => {
    return apolloBlobClient.mutate({
        mutation: gql`
            mutation getUrlArchiveFiles($filesInfo: [ArchiveFilesInfo!]!) {
                getUrlArchiveFiles(filesInfo: $filesInfo) {
                   url
                }
            }
        `,
        variables: { filesInfo: orders },
        onError: (error) => {
            alert(error)
        }
    });
}