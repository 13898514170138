import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { translate } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    container:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: "3rem",
        paddingBottom: "3rem",
        
    },
    title:{
        maxWidth: 256
    },
    subtitle:{
        maxWidth: 256
    },
    mt3:{
        marginTop: "1rem"
    },
   
});


const Infobanner = ({ children, title, subtitle, action, classes, translate }) => (
    <div className={classes.container}>
        {children}
        <Typography variant="title" align="center" gutterBottom className={classes.mt3}>{translate(title, { _: title })}</Typography>
        {subtitle && <Typography variant="subheading" color="textSecondary" align="center" gutterBottom className={`${classes.subtitle}`} >{translate(subtitle, { _: subtitle })}</Typography>}
        {action}
    </div>
);

Infobanner.defaultProps = {
    title: null,
    subtitle: null,
    action: null,

};

Infobanner.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    action: PropTypes.node,
};

export default compose( withStyles(styles), translate )(Infobanner);