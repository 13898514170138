import React from 'react';
import {
    Show,
    SimpleShowLayout,

    TextField,  
    RichTextField,

    DateField,

} from 'react-admin';
import LinkField from '../Fields/linkField';
import JsonField from '../Fields/JsonField';
import UserField from '../User/UserField';
import MutationField from './MutationField';
import ActionChip from './ActionChip';
import AbbrChanges from './AbbrChanges';
import EmptyFieldPlaceholder from './EmptyFieldPlaceholder';
import { withStyles } from '@material-ui/core/styles';


const styles = {
    textOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        lineClamp: 2,
        boxOrient: "vertical"
    },
    textSecondary: {
        color: "rgba(0, 0, 0, 0.54)",
    },
    w100: {
        width: "100%",
    },
};



const OrderHistoryShow = ({classes, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ActionChip source="action"/>

            <LinkField source="user" sortBy="userName" reference="User" emptyValue={<EmptyFieldPlaceholder source="userName"/>} >
                <UserField />
            </LinkField>
            <LinkField source="order" sortBy="orderHumanId" reference="Order" emptyValue={<EmptyFieldPlaceholder source="orderHumanId"/>} >
                <TextField source="humanId" noWrap/>
            </LinkField>
            <AbbrChanges source="changes"/>
            <DateField source="updated" color="textSecondary" textAlign="right" showTime options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>      
            <JsonField source="changes"/>
            <JsonField source="meta"/>
        </SimpleShowLayout>
    </Show>
);

export default withStyles(styles)(OrderHistoryShow);