import { buildQuery } from 'ra-data-graphql-simple'
import globalBuiltQuery from '../globalBuiltQuery'

const customBuildQuery = introspection => (fetchType, resource, params) => {

    const builtQuery = globalBuiltQuery(buildQuery(introspection)(fetchType, resource, params), fetchType, resource, params);
    return builtQuery;
};

export default customBuildQuery;
