import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import WidgetCard from "./WidgetCard";
// import NewYearBG from "../../files/NewYearBG.svg";
import NewYearImage from "../../files/NewYearImage.png";

const styles = theme => ({
    banner: {
        backgroundImage: `url('${NewYearImage}')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom 0px right 3em",
        height: 192,

    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        flexGrow: 1,
        zIndex: 1,
    },
    title: {
        fontWeight: 500,
    },
    text: {
        maxWidth: 640,
    },
    button: {
        marginTop: "auto",
    },
});

const WidgetBanner = withStyles(styles)(({ classes, ...props }) => {


    const stopPropagation = e => e.stopPropagation();

    return (
        <WidgetCard className={classes.banner}>

            {/* <img src={NewYearBG} alt="lorem" className={classes.bg}/> */}
            {/* <img src={NewYearImage} alt="lorem" className={classes.image} /> */}
            <div className={classes.content}>
                <Typography variant="headline" color="primary" gutterBottom className={classes.title}>Добро пожаловать на портал!</Typography>
                <Typography variant="subheading" color="inherit" className={classes.text}>Команда АКСИТЕХ поздравляет всех пользователей портала с наступающим Новым годом и Рождеством!</Typography>
                <Button
                    className={classes.button}
                    component={Link}
                    to="/Order/create"
                    onClick={stopPropagation}
                    variant="outlined"
                    color="primary"

                >
                    Создать новогоднюю заявку
                </Button>

            </div>
        </WidgetCard>

    )

});


export default WidgetBanner;


