import React, { Component } from 'react';
import compose from 'recompose/compose';
import { translate } from 'react-admin';
import Card from '@material-ui/core/Card';
import gql from 'graphql-tag'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { withStyles } from '@material-ui/core/styles';
import AuthIcon from '@material-ui/icons/VpnKey';
import CoreIcon from '@material-ui/icons/Memory';
import BlobIcon from '@material-ui/icons/Backup';
import CaslIcon from '@material-ui/icons/Security';
import FiasIcon from '@material-ui/icons/Room';

import apolloCoreClient from './../../dataProviders/Core/apolloCoreClient';
import { apolloAuthClient } from './../../dataProviders/Auth/graphqlProvider';
import apolloBlobClient from './../../dataProviders/Blob/apolloBlobClient';
import apolloFiasClient from './../../dataProviders/Fias/apolloFiasClient';
import { apolloCaslClient } from './../../dataProviders/caslProvider';


const Promise_all = promises => {
  return new Promise((resolve, reject) => {
    const results = [];
    let count = 0;
    promises.forEach((promise, idx) => {
      promise
        .catch(err => {
          return err;
        })
        .then(valueOrError => {
          results[idx] = valueOrError;
          count += 1;
          if (count === promises.length) resolve(results);
        });
    });
  });
};


const styles = theme => ({
  container: {
    width: "24em",
    marginLeft: 3 * theme.spacing.unit,
  },
  stickyContent: {
    position: "sticky",
    top: 3 * theme.spacing.unit,
    zIndex: 1200,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    // width: "100%",
    // height: 640,
  },
});


class VersionModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    Promise_all([
      apolloCoreClient.query({ query: gql`query { Version { name, version, dependencies } }`, fetchPolicy: 'network-only' }),
      apolloAuthClient.query({ query: gql`query { Version { name, version, dependencies } }`, fetchPolicy: 'network-only' }),
      apolloCaslClient.query({ query: gql`query { Version { name, version, dependencies } }`, fetchPolicy: 'network-only' }),
      apolloBlobClient.query({ query: gql`query { Version { name, version, dependencies } }`, fetchPolicy: 'network-only' }),
      apolloFiasClient.query({ query: gql`query { Version { name, version, dependencies } }`, fetchPolicy: 'network-only' })
    ]).then((results) => {
      let mds = [];
      mds.push({ name: 'Core', icon: CoreIcon, data: results[0] });
      mds.push({ name: 'Auth', icon: AuthIcon, data: results[1] });
      mds.push({ name: 'Casl', icon: CaslIcon, data: results[2] });
      mds.push({ name: 'Blob', icon: BlobIcon, data: results[3] });
      mds.push({ name: 'Fias', icon: FiasIcon, data: results[4] });
      this.setState({ modules: mds, isLoading: false });
    });
  }

  render() {
    const { modules, isLoading } = this.state;
    const { translate, classes } = this.props;

    if (isLoading) {
      return (
        <div className={classes.container}>
          <div className={classes.stickyContent}>
            <p>{`${translate(`ra.page.loading`)}...`}</p>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <div className={classes.stickyContent}>
          <Card>
            <List disablePadding>
              {modules.map(module =>
                <ListItem divider key={module.name}>
                  <ListItemIcon>
                    {(module.icon) ? <module.icon /> : <LabelImportantIcon />}
                  </ListItemIcon>
                  <ListItemText primary={translate(`resources.AboutApp.modules.${module.name}.name`, { _: module.name })} secondary={!(module.data instanceof Error) ? `${module.data.data.Version.name}: v${module.data.data.Version.version}` : translate(`resources.AboutApp.errrors.not_available`)} />
                </ListItem>
              )}
            </List>
          </Card>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  translate,
)(VersionModules);




// export default (type, resource, params) => (
//   <>
//     <Card>
//       <Title title="resources.AboutApp.name" />
//       <CardContent>
//           <VersionModules></VersionModules>
//       </CardContent>
//     </Card> 
//     <Card>
//       <CardContent>
//         <MarkdownField path={CHANGELOG} />
//       </CardContent>

//     </Card> 

// </>
// );

