import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GetAccessToken } from '../authProvider';
import { URI_BLOB } from '../../appsettings';

// eslint-disable-next-line no-extend-native
Array.prototype.selectMany = function (fn) {
    return this.map(fn).reduce(function (x, y) { return x.concat(y); }, []);
};

const httpLink = createUploadLink({
    uri: URI_BLOB
})

const blobLink = setContext(async (_, { headers }) => {
    const token = await GetAccessToken();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const apolloBlobClient = new ApolloClient({
    link: blobLink.concat(httpLink),
    cache: new InMemoryCache()
})

export default apolloBlobClient;