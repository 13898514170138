import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GetAccessToken } from '../authProvider';
import { URI_FGIS } from '../../appsettings';



const httpLink = createHttpLink({
    uri: URI_FGIS
})

const fiasLink = setContext(async (_, { headers }) => {
    const token = await GetAccessToken();
    //console.log('++++> ['+URI_FIAS+'] header get TOKEN from localStorage ('+token+')');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const apolloFgisClient = new ApolloClient({
    link: fiasLink.concat(httpLink),
    cache: new InMemoryCache()
})

export default apolloFgisClient