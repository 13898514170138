import { UPDATE } from 'react-admin';

export const ARCHIVE = 'ARCHIVE';
export const ARCHIVE_LOADING = 'ARCHIVE_LOADING';
export const ARCHIVE_FAILURE = 'ARCHIVE_FAILURE';
export const ARCHIVE_SUCCESS = 'ARCHIVE_SUCCESS';

export const archive = (resource, id, data, basePath, redirectTo = 'show') => {

    return {
        type: ARCHIVE,
        payload: { id, data: { id: data.id, isArchive: true, authStatus: "BANNED" }, previousData: data },
        meta: {
            resource,
            fetch: UPDATE,
            onSuccess: {
                notification: {
                    body: 'raExt.notification.archive_success',
                    level: 'info',
                },
                redirectTo,
                basePath,
            },
            onFailure: {
                notification: {
                    body: 'raExt.notification.archive_failure',
                    level: 'warning',
                },
            },
        },
    }
};

export const UNARCHIVE = 'UNARCHIVE';
export const UNARCHIVE_LOADING = 'UNARCHIVE_LOADING';
export const UNARCHIVE_FAILURE = 'UNARCHIVE_FAILURE';
export const UNARCHIVE_SUCCESS = 'UNARCHIVE_SUCCESS';

export const unarchive = (resource, id, data, basePath, redirectTo = 'show') => ({
    type: UNARCHIVE,
    payload: { id, data: { id: data.id, isArchive: false }, previousData: data },
    meta: {
        resource,
        fetch: UPDATE,
        onSuccess: {
            notification: {
                body: 'raExt.notification.unarchive_success',
                level: 'info',
            },
            redirectTo,
            basePath,
        },
        onFailure: {
            notification: {
                body: 'raExt.notification.unarchive_failure',
                level: 'warning',
            },
        },
    },
});