import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { AUTH_TOKEN } from '../../appsettings'
import { submit, reset, isSubmitting } from 'redux-form';
import ReactCardFlip from 'react-card-flip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import { translate, SimpleForm, TextInput, required, } from 'react-admin';
import PhoneInput from '../Inputs/PhoneInput';
import CreateOrderImage from '../../files/CreateOrderImage.svg';
import getTokenForGuest from '../../dataProviders/Auth/getTokenForGuest';
import createOrderForGuest from '../../dataProviders/Core/createOrderForGuest';
import OrderCreateDialog from './OrderCreateDialog';
import AutocompleteCityInput from './AutocompleteCityInput';


const styles = theme => ({
  flipContainer: {
    margin: "1em",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: 300,
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius * 2,
    position: "relative",
  },
  py0: {
    paddingTop: 0,
    paddingBottom: 0
  },
  media: {
    height: 140,
    backgroundSize: "unset",
  },
  titleContent: {
    maxWidth: 256,
    textAlign: "center",
  },
  createButton: {
    color: theme.palette.getContrastText(green["700"]),
    backgroundColor: green["500"],
    '&:hover': {
      backgroundColor: green["700"],
    },
  },
  mtAuto: {
    marginTop: "auto"
  },
  backButton: {
    color: theme.palette.text.hint,
    position: "absolute",
    top: 8,
    left: 8,
  },
});



const OrderCreateCard = withStyles(styles)(({ classes, className, isLoading, translate, submit, reset, ...props }) => {
  const [isFlipped, setFlipped] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState(null);

  const handleFlip = (e) => {
    e.preventDefault();
    setFlipped(true);
  }

  const handleClose = (e) => {
    e.preventDefault();
    setFlipped(false);
  }

  const handleSubmit = async (values) => {

    const { data: { guest: { token } } } = await getTokenForGuest();
    localStorage.setItem(AUTH_TOKEN, token);

    const order = await createOrderForGuest(values);
    reset('order-quick-create');
    setOrder(order.data.createOrderForGuest);
    setOpen(true);
  }

  const handleCreate = (e) => {
    submit('order-quick-create');
  };

  const handleCloseDialog = (e) => {
    setOpen(false);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} containerStyle={{ margin: "1em" }} >
      <Card className={classes.card} key="front">
        <CardMedia className={classes.media} image={CreateOrderImage} title="Создание заявки" />
        <CardContent className={classes.titleContent}>
          <Typography gutterBottom variant="headline">Заявка онлайн</Typography>
          <Typography variant="body1" color="textSecondary">Отличная новость для наших заказчиков! Теперь Вы можете оформить заявку онлайн. Заполните несколько полей и наш диспетчер свяжется с Вами.</Typography>
        </CardContent>
        <CardContent className={classes.mtAuto}>
          <Button fullWidth variant='contained' className={classes.createButton} onClick={handleFlip}>Заполнить поля</Button>
        </CardContent>
      </Card>

      <Card className={classes.card} key="back">
        <IconButton className={classes.backButton} onClick={handleClose}><BackIcon /></IconButton>
        <SimpleForm form="order-quick-create" resource="User" onSubmit={handleSubmit} toolbar={null} redirect={false} style={{ marginTop: 24 }}>
          <AutocompleteCityInput form="order-quick-create" fullWidth source="cityId" label={translate('resources.Order.fields.cityId')} validate={required()} disabled={isLoading} />
          <TextInput fullWidth source="name" label={translate('resources.User.fields.name')} validate={required()} disabled={isLoading} />
          <PhoneInput fullWidth source="phone" label={translate('resources.User.fields.phone')} disabled={isLoading} />
        </SimpleForm>
        <CardContent className={classes.mtAuto}>
          <Button
            fullWidth
            variant="raised"
            type="submit"
            onClick={handleCreate}
            disabled={isLoading}
            className={classes.createButton}
          >
            {isLoading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              Создать заявку
          </Button>
          <OrderCreateDialog record={order} open={open} onClose={handleCloseDialog} />
        </CardContent>
      </Card>
    </ReactCardFlip>
  );
});



const mapStateToProps = state => {
  return ({ isLoading: state.admin.loading > 0 });
}



const mapDispatchToProps = {
  submit,
  reset,
};

const enhance = compose(
  withStyles(styles),
  translate,
  connect(mapStateToProps, mapDispatchToProps),
  // reduxForm({
  //     form: 'order-quick-create',
  //     validate: (values, props) => {
  //         const errors = {};
  //         const { translate } = props;
  //         if (!values.login)
  //             errors.login = translate('ra.validation.required');
  //         if (!values.password)
  //             errors.password = translate('ra.validation.required');
  //         return errors;
  //     },
  // })
);

export default enhance(OrderCreateCard);
