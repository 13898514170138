import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.5,17H14L12,9.5L10,17H8.5L6.1,7H7.8L9.34,14.5L11.3,7H12.7L14.67,14.5L16.2,7H17.9M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
    </SvgIcon>
  );
}

export default Icon;