import gql from 'graphql-tag';

const SITYPE_TEMPLATE = `{
    id
    name
    number
    mpiyears
    mpimonths
    manufacturerTotal
}`

export const GET_LIST_SITYPE = gql`
    query allSITypes($page: Int, $perPage: Int, $sort: [SITypeSort!], $filter: SITypeFilter) {
        data: allSITypes(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
            data: items ${SITYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_MANY_SITYPES = gql`
    query allSITypes($filter: SITypeFilter) {
        data: allSITypes(filter: $filter) {
            data: items ${SITYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_ONE_SITYPE = gql`
    query SIType( $id: String! ) {
        data: SIType( id: $id ) ${SITYPE_TEMPLATE}
    }
`


const CCSVTYPE_TEMPLATE = `{
    id
    numberOrder
    nameOrg
    ccsv
}`

export const GET_LIST_CCSVTYPE = gql`
    query allCCSVTypes($page: Int, $perPage: Int, $sort: [CCSVTypeSort!], $filter: CCSVTypeFilter) {
        data: allCCSVTypes(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
            data: items ${CCSVTYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_MANY_CCSVTYPES = gql`
    query allCCSVTypes($filter: CCSVTypeFilter) {
        data: allCCSVTypes(filter: $filter) {
            data: items ${CCSVTYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_ONE_CCSVTYPE = gql`
    query CCSVType( $id: String! ) {
        data: CCSVType( id: $id ) ${CCSVTYPE_TEMPLATE}
    }
`



const SUTYPE_TEMPLATE = `{
    id
    name
    number
    nameOrg
}`

export const GET_LIST_SUTYPE = gql`
    query allSUTypes($page: Int, $perPage: Int, $sort: [SUTypeSort!], $filter: SUTypeFilter) {
        data: allSUTypes(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
            data: items ${SUTYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_MANY_SUTYPES = gql`
    query allSUTypes($filter: SUTypeFilter) {
        data: allSUTypes(filter: $filter) {
            data: items ${SUTYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_ONE_SUTYPE = gql`
    query SUType( $id: String! ) {
        data: SUType( id: $id ) ${SUTYPE_TEMPLATE}
    }
`



const GSITYPE_TEMPLATE = `{
    id
    name
    number
    type
}`

export const GET_LIST_GSITYPE = gql`
    query allGSITypes($page: Int, $perPage: Int, $sort: [GSITypeSort!], $filter: GSITypeFilter) {
        data: allGSITypes(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
            data: items ${GSITYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_MANY_GSITYPES = gql`
    query allGSITypes($filter: GSITypeFilter) {
        data: allGSITypes(filter: $filter) {
            data: items ${GSITYPE_TEMPLATE}
            total: totalCount
        }
    }
`

export const GET_ONE_GSITYPE = gql`
    query GSIType( $id: String! ) {
        data: GSIType( id: $id ) ${GSITYPE_TEMPLATE}
    }
`