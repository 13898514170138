import React from 'react';
import { ShowController, ShowView, SimpleShowLayout, TextField, DateField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import { ability } from '../../ability';
import EnumField from '../Fields/EnumField';
import PhoneField from '../Fields/PhoneField';


const CounterpartyShow = (props) => (
    <ShowController {...props}>
        {controllerProps => {
            const type = controllerProps.record && controllerProps.record.type;
            return (
                <ShowView {...props} {...controllerProps} >
                    <SimpleShowLayout>
                        {ability.can('read', 'Counterparty', 'name') && <TextField source="name" />}
                        {ability.can('read', 'Counterparty', 'description') && <TextField source="description" />}
                        {ability.can('read', 'Counterparty', 'type') && <EnumField source="type" />}
                        {ability.can('read', 'Counterparty', 'inn') && <TextField source="inn" />}
                        {ability.can('read', 'Counterparty', 'kpp') && (type === "LEGALENTITY" || type === "GOVERNMENTAGENCY") && <TextField source="kpp" />}
                        {ability.can('read', 'Counterparty', 'okpo') && (type === "LEGALENTITY" || type === "GOVERNMENTAGENCY") && <TextField source="okpo" />}
                        {ability.can('read', 'Counterparty', 'ogrn') && (type === "LEGALENTITY" || type === "GOVERNMENTAGENCY") && <TextField source="ogrn" />}
                        {ability.can('read', 'Counterparty', 'fio') && type === "INDIVIDUAL" && <TextField source="fio" />}
                        {ability.can('read', 'Counterparty', 'passport') && type === "INDIVIDUAL" && <TextField source="passport" />}
                        {ability.can('read', 'Counterparty', 'passdateissue') && type === "INDIVIDUAL" && <DateField source="passdateissue" />}
                        {ability.can('read', 'Counterparty', 'parentId') && type === "SEPARATEDIVISION" && <TextField source="parentId" />}
                        {ability.can('read', 'Counterparty', 'addressLegal') && <TextField source="addressLegal" />}
                        {ability.can('read', 'Counterparty', 'addressActual') && <TextField source="addressActual" />}
                        {ability.can('read', 'Counterparty', 'addressPostal') && <TextField source="addressPostal" />}
                        {ability.can('read', 'Counterparty', 'phone') && <PhoneField source="phone" />}
                        {ability.can('read', 'Counterparty', 'phoneMobile') && <PhoneField source="phoneMobile" />}
                        {ability.can('read', 'Counterparty', 'email') && <TextField source="email" />}
                        {ability.can('read', 'Counterparty', 'users') && <ReferenceArrayField source="usersIds" reference="User" linkType="show" >
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField >}
                    </SimpleShowLayout>
                </ShowView>
            )
        }}
    </ShowController>

);

export default CounterpartyShow;
