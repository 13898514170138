import radatagraphqlsimple from '../customdatagraphql'
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import blobBuiltQuery from './builtQuery';
import { GetAccessToken} from '../authProvider';
import { URI_BLOB } from '../../appsettings';

Array.prototype.selectMany = function (fn) {
  return this.map(fn).reduce(function (x, y) { return x.concat(y); }, []);
};

const httpLink = createUploadLink({
    uri: URI_BLOB
})

const blobLink = setContext(async (_, { headers }) => {           
    const token = await GetAccessToken(); 
    //console.log('++++> ['+URI_BLOB+'] header get TOKEN from localStorage ('+token+')');
   
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
});

export const apolloBlobClient = new ApolloClient({
    link: blobLink.concat(httpLink), 
    cache: new InMemoryCache()
})

export const resourcebindingBlob = []
export default () => radatagraphqlsimple({ 
    client : apolloBlobClient, 
    buildQuery : blobBuiltQuery(apolloBlobClient)
})