import React from 'react';
import compose from 'recompose/compose';
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import PhoneIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import { ability } from '../../ability'

import ArchiveChip from '../Fields/ArchiveChip';
import PhoneField from '../Fields/PhoneField';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { white } from 'ansi-colors';

import RoleChip from '../Fields/RoleChip';
import AuthChip from '../Fields/AuthChip';


const styles = theme => ({
    avatar: {
        width: 128,
        height: 128,
        backgroundColor:theme.palette.grey["400"],
    },
    headline:{
        display: "flex",
    },
    subheading:{
        display: "flex",
        color:theme.palette.text.secondary
    },
    flex: {
        display: "flex",
    },
    flexColumn: {
        flexDirection: "column"
    },
    justifyEnd: {
        justifyContent: "flex-end"
    },
    mlAuto: {
        marginLeft: "auto",
    },
    ml1:{
        marginLeft: "0.25rem"
    },
    ml2:{
        marginLeft: "0.5rem"
    },
    ml3:{
        marginLeft: "1rem"
    },
    iconHeadline:{
        fontSize:"inherit",
        marginRight:"0.5rem",
        height: "1.35417em"
    },
    iconSubheading:{
        fontSize:"inherit",
        marginRight:"0.5rem",
        height: "1.5em"
    },
    chipSuccess:{
        color: white,
        backgroundColor: green["400"],
    },
    chipError:{
        backgroundColor: red["400"],
    },
    iconChip:{
        marginLeft:"4px",
        height: 24,
        width: 24,
    
    },
});



const FastView = ({ className, classes, locales, options={}, translate, record, ...props }) =>{

    if (!record) return null;
   
    const createdAt = moment(record.createdAt).format('L');
    const updatedAt = moment(record.updatedAt).format('L');

    return(
        <CardContent >
            <Grid container spacing={16}>
                <Grid item xs className={`${classes.flex}`} >
                    <Avatar className={classes.avatar} >NN</Avatar>
                    <div className={`${classes.ml3}`}>
                        <Typography className={classes.headline} variant="headline" component="h2" gutterBottom>{record.name}</Typography>
                        <Typography className={classes.subheading} variant="subheading" component="span"><PhoneIcon className={classes.iconSubheading}/><PhoneField record={record} source="phone" variant="subheading" color="inherit"/></Typography>
                        
                        {(ability.can('read', "User", "email") && record.email) && <Typography className={classes.subheading} variant="subheading" component="span" ><EmailIcon className={classes.iconSubheading}/>{record.email}</Typography>}
                    </div>
                </Grid>
                <Grid item sm className={`${classes.flex} ${classes.justifyEnd}`}>
                    <RoleChip record={record}/>
                    {ability.can('read', "User", "authStatus") && <AuthChip record={record} className={`${classes.ml1}`}/> }
                    <ArchiveChip record={record} className={`${classes.ml1}`} />
                
                </Grid>
            </Grid>
            <div className={`${classes.flex} ${classes.justifyEnd}`}>
                {ability.can('read', "User", "createdAt") && <Typography color="textSecondary" variant="caption" >{`${translate('resources.User.fields.createdAt')}: ${createdAt}`}</Typography> }
                {ability.can('read', "User", "updatedAt") && <Typography color="textSecondary" variant="caption" className={`${classes.ml2}`} >{`${translate('resources.User.fields.updatedAt')}: ${updatedAt}`}</Typography> }
            </div>

            
        </CardContent>
    )
};


export default compose( withStyles(styles), translate, )(FastView);

