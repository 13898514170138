import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    dflex:{
        display:"flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding:"0.25rem 0",
    },
    root:{
       height:"unset",
       margin:"0.125rem",
       backgroundColor: "rgba(0, 0, 0, 0.06)",   
       cursor: "inherit",
        
    },
    label:{
        padding:"0.125rem 0.5rem",
    },
});

const AbbrChanges = ({ record, source, translate, classes, ...props }) => {
    const data = get(record, source, {});
    const renderField = field => (<Chip key={field} classes={{root: classes.root, label:classes.label,}} label={translate(`resources.Order.fields.${field}`)} />)
    const renderChange = (action) => {
        
        switch (action) { 
            case "CREATED":{ 
                return ( 
                    <div className={classes.dflex}>
                        <Typography variant="body1">
                            {`${translate(`resources.OrderHistory.helper.CREATED`)} ${translate(`resources.OrderHistory.helper.CREATED_FIELD`)}`}
                        </Typography>
                        {Object.keys(data).filter(field => data[field] !== null).map(field => { return renderField(field)})}
                    </div>
                   )
            } break;

            case "UPDATED":
            case "PROCESSED":
            case "INWORK":
            case "COMPLETED":
            case "CANCELLED":{ 
                return ( 
                    <div className={classes.dflex}>
                        <Typography variant="body1">
                            {`${translate(`resources.OrderHistory.helper.${action}`)} ${ (Object.keys(data).length > 0) ? translate(`resources.OrderHistory.helper.UPDATED_FIELD`) :""}`}
                        </Typography>
                        {Object.keys(data).filter(field => data[field] !== null).map(field => { return renderField(field)})}
                    </div>
                   )
            } break;


            default: { 
                return ( 
                    <div className={classes.dflex}>
                        <Typography variant="body1">
                            {`${translate(`resources.OrderHistory.helper.${action}`)}`}
                        </Typography>
                    </div>
                   )
            } break;

        }
     
    }

    return renderChange(record.action);
       
}





const enhaenceAbbrChanges = compose( withStyles(styles), translate, )(AbbrChanges)
enhaenceAbbrChanges.defaultProps = {
    addLabel: true,
};

export default enhaenceAbbrChanges;