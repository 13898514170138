import React, { Fragment } from 'react';
import { Show, EditButton, CloneButton, TabbedShowLayout, Tab, BooleanField, DateField, Pagination, ReferenceField, ReferenceManyField, TextField, NumberField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import FilesGridList from '../Layout/FilesGridList';
import LinkField from '../Fields/linkField';
import { MeterWithLocationField } from '../Meter/MeterField';
import UserField from '../User/UserField';
import Title from './Title';
import FastView from './FastView';
import OrderTabbedShowLayoutTabs from './OrderTabbedShowLayoutTabs';
import AddressField from './AddressField';
import { OrderHistoryDatagridForOrder } from '../OrderHistory';
import { ability } from '../../ability';
import DownloadFilesButton from '../Buttons/DownloadFilesButton';
import OrdersToXmlButton from './OrdersToXmlButton';
import EnumField from '../Fields/EnumField';



// class Order {
//     constructor(attrs) {
//        Object.assign(this, attrs)
//     }
// }


const ShowLayoutView = ({ classes, ...props }) => {
    return (
        <Fragment>
            <FastView {...props} />
            <TabbedShowLayout tabs={<OrderTabbedShowLayoutTabs />} {...props}>
                <Tab label="resources.Order.tabs.general">

                    {ability.can('read', "Order", "dispatcher") && <LinkField source="dispatcher" reference="User" emptyValue="raExt.notification.order_user_empty_data"><UserField /></LinkField>}
                    {ability.can('read', "Order", "customer") && <LinkField source="customer" reference="User" emptyValue="raExt.notification.order_user_empty_data"><UserField /></LinkField>}
                    {ability.can('read', "Order", "verifier") && <LinkField source="verifier" reference="User" emptyValue="raExt.notification.order_user_empty_data"><UserField /></LinkField>}
                    {ability.can('read', "Order", "addrManual") && <AddressField source="addrManual" />}
                    {ability.can('read', "Order", "comment") && <TextField source="comment" />}
                </Tab>
                <Tab label="resources.Order.tabs.measuringInstrument" path="measuringInstrument">
                    {ability.can('read', "Order", "meterRef") && <LinkField source="meter" reference="Meter" emptyValue="raExt.notification.order_meter_empty_data">
                        <TextField source="name" />
                    </LinkField>}
                    {ability.can('read', "Order", "manufactureYear") && <NumberField source="manufactureYear" />}
                    {ability.can('read', "Order", "manufactureNum") && <TextField source="manufactureNum" />}
                    {ability.can('read', "Order", "siTypeName") && <TextField source="siTypeName" />}
                    {ability.can('read', "Order", "siTypeNumber") && <TextField source="siTypeNumber" />}
                    {ability.can('read', "Order", "siTypeMPIYears") && <NumberField source="siTypeMPIYears" />}
                    {ability.can('read', "Order", "siTypeMPIMonths") && <NumberField source="siTypeMPIMonths" />}
                    {ability.can('read', "Order", "siTypeManufacturerTotal") && <TextField source="siTypeManufacturerTotal" />}
                    {ability.can('read', "Order", "meterLocation") && <EnumField source="meterLocation" Enum="MeterLocation" allowEmpty emptyValue={null} />}
                    {ability.can('read', "Order", "meterUninstallAt") && <DateField source="meterUninstallAt" />}
                </Tab>
                {ability.can("Show", { constructor: { name: "Order" }, tab: "result" }) &&
                    <Tab label="resources.Order.tabs.result" path="result">
                        {ability.can('read', "Order", "resultRef") &&
                            <ReferenceField source="resultRef" reference="Result" linkType={false} allowEmpty {...props}>
                                <TextField source="description" />
                            </ReferenceField>}
                        {ability.can('read', "Order", "resultDesc") && <TextField source="resultDesc" />}
                        {ability.can('read', "Order", "personalAccount") && <TextField source="personalAccount" />}
                        {ability.can('read', "Order", "invoice") && <TextField source="invoice" />}
                        {ability.can('read', "Order", "isSealNotInstalled") && <BooleanField source="isSealNotInstalled" />}
                        {ability.can('read', "Order", "isSIOwnedByVerifier") && <BooleanField source="isSIOwnedByVerifier" />}
                        {ability.can('read', "Order", "signMi") && <BooleanField source="signMi" />}
                        {ability.can('read', "Order", "signPass") && <BooleanField source="signPass" />}

                        {ability.can('read', "Order", "ccsvTypeCCSV") && <TextField source="ccsvTypeCCSV" />}
                        {ability.can('read', "Order", "certificateNumber") && <TextField source="certificateNumber" />}
                        {ability.can('read', "Order", "stickerNumber") && <TextField source="stickerNumber" />}
                        {ability.can('read', "Order", "gsiTypeType") && <TextField source="gsiTypeType" />}
                        {ability.can('read', "Order", "gsiTypeNumber") && <TextField source="gsiTypeNumber" />}
                        {ability.can('read', "Order", "gsiTypeName") && <TextField source="gsiTypeName" />}
                        {ability.can('read', "Order", "suTypeNumber") && <TextField source="suTypeNumber" />}
                        {ability.can('read', "Order", "suTypeName") && <TextField source="suTypeName" />}
                        {ability.can('read', "Order", "validAt") && <DateField source="validAt" />}
                    </Tab>
                }
                {ability.can("Show", { constructor: { name: "Order" }, tab: "files" }) &&
                    <Tab label="resources.Order.tabs.files" path="files">
                        {ability.can('read', "Order", "files") && <FilesGridList source="files" addLabel={false} />}
                    </Tab>
                }
                {ability.can("Show", { constructor: { name: "Order" }, tab: "history" }) &&
                    <Tab label="resources.Order.tabs.history" path="history" >
                        {ability.can('read', "Order", "history") &&
                            <ReferenceManyField reference="OrderHistory" target="order.id" sort={{ field: 'updated', order: 'DESC' }} perPage={10} pagination={<Pagination />} addLabel={false} >
                                <OrderHistoryDatagridForOrder />
                            </ReferenceManyField>}
                    </Tab>
                }
            </TabbedShowLayout>

        </Fragment>
    )
};

var styles = theme => ({
    contaiter: {
        zIndex: 2,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
});

const OrderShowActions = withStyles(styles)(({ classes, className, basePath, data, resource }) => {
    if (!data) return null;
    const omitId = ({ id, statusRef, ...rest }) => rest;
    return (
        <CardActions className={`${classes.contaiter}`}>
            { ability.can('read', "Order", "files") && <DownloadFilesButton record={data} />}
            { ability.can('Create', 'Order') && <CloneButton basePath={basePath} record={{ ...omitId(data) }} />}
            { ability.can('exportfgis', 'Order') && <OrdersToXmlButton record={data} />}
            { ability.can('Edit', 'Order') && <EditButton basePath={basePath} record={data} />}

        </CardActions >
    );
});

const OrderShow = (props) => {
    return (
        <Show title={<Title {...props} />} actions={<OrderShowActions />} {...props}>
            <ShowLayoutView />
        </Show>
    );
};

export default OrderShow;