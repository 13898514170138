import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { translate } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    container:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: "3rem",
        paddingBottom: "3rem",
        
    },
    subtitle:{
        maxWidth: "16rem"
    },
    mt3:{
        marginTop: "1rem"
    },
    mb3:{
        marginBottom: "1rem"
    },
   
});


const EmptyState = ({ className, classes = {}, translate, imageUrl, title, subtitle, button, ...rest }) => (
    <div className={classes.container}>
        {!!imageUrl && <img src={imageUrl} alt={translate(title, { _: title })}  />}
        <Typography variant="body1" align="center" gutterBottom className={classes.mt3}>{translate(title, { _: title })}</Typography>
        {!!subtitle && <Typography variant="caption" align="center" gutterBottom className={`${classes.subtitle} ${classes.mb3}`} >{translate(subtitle, { _: subtitle })}</Typography>}
        {!!button && React.cloneElement(button, { className, ...rest })}
    </div>
);

EmptyState.defaultProps = {
    imageUrl: undefined,
    title: "Empty data",
    subtitle: undefined,
    button: undefined,

};

EmptyState.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    button: PropTypes.element,

};

export default compose( withStyles(styles), translate )(EmptyState);