
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import OpenIcon from '@material-ui/icons/OpenInNew';
import get from 'lodash/get';
import { GetAccessToken } from '../../dataProviders/authProvider';

const OpenButton = ({ record, source, ...props }) => {

    const url = get(record, source);

    const onClick = async (event) => {
        event.stopPropagation();
        const TOKEN = await GetAccessToken();
        window.open(url.indexOf('blob:') < 0 ? `${url}?${TOKEN}` : url, '_blank');
    }

    return (
        <IconButton onClick={onClick} >
            <OpenIcon />
        </IconButton >
    );
}

OpenButton.defaultProps = {
    source: 'url',
    addLabel: false,
};


export default OpenButton;