import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import OrderIcon from '@material-ui/icons/AttachMoney';
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';

const stopPropagation = e => e.stopPropagation();

const sanitizeRestProps = ({
    // the next 6 props are injected by Toolbar
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    saving,
    submitOnEnter,
    ...rest
}) => rest;

export const CreateRelatedOrderButton = ({
    basePath = '',
    label = 'raExt.action.create_related_order',
    record = {},
    resource,
    ...rest
}) => {
    if (record.role !== "CUSTOMER") return null

    return (
        <Button
            component={Link}
            to={{
                pathname: `/Order/create`,
                state: { 
                    record: {
                        customer: {
                            id: record.id,
                            name: record.name,
                            phone: record.phone,
                        }
                    }
                },
            }}
            label={label}
            onClick={stopPropagation}
            {...sanitizeRestProps(rest)}
        >
            <OrderIcon />
        </Button>
    );
}




CreateRelatedOrderButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
};

const enhance = compose(
    pure,
);

export default enhance(CreateRelatedOrderButton);