import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import pure from 'recompose/pure';

import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    emptyContainer:{
        width:"100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
        
    },
    emptyContainerImage:{
        paddingTop: '1rem',
      
    },
    noResult:{
        paddingBottom: '1rem',
        textAlign: 'center',
        color:theme.palette.text.secondary
    },
    tileBar: {
        background:'linear-gradient(to bottom, rgba(51,51,51,0.8) 4%, rgba(51,51,51,0.38) 47%, rgba(44,44,44,0.01) 85%)',
    },
    link: {
        color: '#fff',
    },
    GridList_root: {
        paddingTop: '1rem'
    },
    GridListTile_tile: {
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #EAEAEA',
        borderRadius: '0.25rem',
    },
    GridListTile_imgFullWidth: {
        top: 'unset',
        width: 'unset',
        position: 'relative',
        transform: 'unset',
    },
    placeholderImage: {
        cursor: 'pointer',
    },
});







const ArrayMapField = ({ basePath, record, source, resource, children, className, classes, empty, translate, ...props }) => {
    
    if (React.Children.count(children) !== 1) throw new Error('<ArrayMapField> only accepts a single child');
    const array = get(record, source);
    if (!array || !Array.isArray(array)) throw new Error('Source is not an array'); 
    
    
    if (array.length === 0) {
        if (!empty) return null;
        if (typeof empty === 'string') return <Typography variant="caption">{translate(empty, { _: empty })}</Typography>;
        if (typeof empty === 'object') return React.cloneElement(empty, { className, record, ...props });
    } 
    
    return (
        <div>
        {array.map( (item, i) => {    
                return React.cloneElement(children, {
                    key: i,
                    basePath,
                    resource,
                    record: item,
                    ...props
                }); 
        })}
        </div>
    )

} 

ArrayMapField.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    source: PropTypes.string,
    empty: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool])
};

ArrayMapField.defaultProps = {
    empty: false,
};

const EnhancArrayMapField = compose(
    withStyles(styles),
    translate,
    pure,
)(ArrayMapField);

EnhancArrayMapField.defaultProps = {
    addLabel: true,
};

export default EnhancArrayMapField;

