import gql from 'graphql-tag'
import { apolloNotificationsClient } from './graphqlProvider';

const CLEAR_NOTIFICATIONS = gql`
    mutation disconnectNotifications($id_in: [ID!]) {
        data: disconnectNotifications(id_in: $id_in) {
            count 
        }
    }
`;


export default (args) => {
    return apolloNotificationsClient.mutate({
        mutation: CLEAR_NOTIFICATIONS,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    });
};
