import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting, reset } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {
    required,
    minLength,
    email,
    translate,
    fetchEnd,
    fetchStart,
    showNotification,
    FormDataConsumer,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    SelectInput,
    CREATE,
    resetForm,
    REDUX_FORM_NAME,
    crudGetMatching, // функция которая заставлякт обновить ReferenceInput
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TypeField from '../Customer/TypeField';
import PhoneInput from '../Inputs/PhoneInput';
import PaymentField from '../Customer/PaymentField';
import { dataProviderFactory } from '../../dataProviders/dataProviderFactory';

const styles = theme => ({
    toolbar: {
        margin: 0,
        backgroundColor: theme.palette.grey['100'],
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        justifyContent: "space-between",
    },
    cancelButton: {
        color: fade(theme.palette.grey[900], 0.64),

    },
});

class UserQuickCreateButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            showDialog: false
        };
    }

    role = this.props.source.substring(0, this.props.source.indexOf('.')).toUpperCase();

    handleClick = () => {
        const { reset, resetForm } = this.props;
        resetForm('user-quick-create')
        reset('user-quick-create')
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        const { reset, resetForm } = this.props;
        resetForm('user-quick-create')
        reset('user-quick-create')
        this.setState({ showDialog: false });

    };

    handleSaveClick = () => {
        const { submit } = this.props;
        submit('user-quick-create');
    };

    handleSubmit = values => {
        const { change, crudGetMatching, fetchStart, fetchEnd, showNotification, source, reset, resetForm, } = this.props;



        fetchStart();

        dataProviderFactory().then(dataProvider => {

            if (this.role !== "CUSTOMER") delete values.customer;

            dataProvider(CREATE, 'User', {
                data: {
                    ...values,
                    role: this.role,
                }
            })
                .then(({ data }) => {


                    crudGetMatching(
                        'User',
                        'Order@' + source,
                        { page: 1, perPage: 25 },
                        { field: 'createdAt', order: 'DESC' },
                        { role: this.role }
                    );

                    //change(REDUX_FORM_NAME, source, data.id);               
                    //resetForm('user-quick-create');
                    //reset('user-quick-create'); 
                    //this.setState({ showDialog: false });

                    //TODO: временное решение до исправления !!!  
                    let self = this;
                    //change(REDUX_FORM_NAME, source, data.id);                      
                    setTimeout(function () {
                        change(REDUX_FORM_NAME, source, data.id);
                        self.setState({ showDialog: false });

                        //resetForm('user-quick-create')
                        //reset('user-quick-create') 
                    }, 1000);

                })
                .catch(error => {
                    showNotification(error.message, 'error');

                })
                .finally(() => {
                    fetchEnd();
                });
        });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, classes } = this.props;


        const validateName = [required(), minLength(2)];
        const validateEmail = [email()];

        const arrTypes = [
            { type: 'PHIS' },
            { type: 'JURE' },
        ];

        const arrPayments = [
            { payment: 'CASH' },
            { payment: 'CASHLESS' },
        ];

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="ra.action.create">
                    <IconContentAdd />
                </Button>
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create user">

                    <DialogTitle><TitleHeader role={this.role}></TitleHeader></DialogTitle>

                    <DialogContent>
                        <SimpleForm form="user-quick-create" resource="User" onSubmit={this.handleSubmit} toolbar={null} >

                            <TextInput source="name" fullWidth validate={validateName} />
                            <PhoneInput source="phone" inputProps={{ autoComplete: "new-password" }} />
                            <TextInput source="email" type="email" validate={validateEmail} inputProps={{ autoComplete: "new-password" }} />
                            <TextInput source="password" type="password" inputProps={{ autoComplete: "new-password" }} />
                            <FormDataConsumer style={{ display: "flex", flexDirection: "column" }}>{
                                ({ formData, ...props }) => {

                                    if (this.role === "CUSTOMER") {
                                        let arrCustomerInputs = [
                                            <SelectInput key="type" source="customer.type" choices={arrTypes} optionValue="type" optionText={<TypeField />} defaultValue={"PHIS"} {...props} />,
                                        ]

                                        if (formData.customer !== undefined && (formData.customer.type === undefined || formData.customer.type === "JURE")) {
                                            arrCustomerInputs.push(
                                                <TextInput key="organizationName" source="customer.organizationName" {...props} />,
                                                <TextInput key="organizationInn" source="customer.organizationInn" {...props} />,
                                                <SelectInput key="payment" source="customer.payment" choices={arrPayments} optionValue="payment" optionText={<PaymentField />} defaultValue={"CASH"} {...props} />
                                            )
                                        }

                                        return arrCustomerInputs
                                    }

                                }
                            }
                            </FormDataConsumer>
                        </SimpleForm>
                    </DialogContent>

                    <DialogActions className={`${classes.toolbar}`}>
                        <SaveButton
                            label="ra.action.create"
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button className={`${classes.cancelButton}`} label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const TitleHeader = translate(({ translate, role }) => (
    <span>{translate(`resources.User.dialogTitle.create.${role}`)}</span>
))

const mapStateToProps = state => {
    return ({
        isSubmitting: isSubmitting('user-quick-create')(state)
    });
}

const mapDispatchToProps = {
    change,
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification,
    submit,
    reset,
    resetForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(UserQuickCreateButton)

);