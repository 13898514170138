
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PhoneField from '../Fields/PhoneField';

const UserField = ({ record = {}, ...props }) => (
    <div style={props.style}>
        <Typography variant="body1" color="inherit">{record.name}</Typography>
        <PhoneField source="phone" variant="caption" noWrap record={record}/>
    </div>
   
);

export const UserOption = ({ record = {}, className, classes, ...props }) => (
    <div style={{display:"flex", flexDirection: "column"}}>
        <span>{record.name}</span>
        <PhoneField source="phone" variant="caption" noWrap record={record}/>
    </div>
   
);

UserField.defaultProps = {
    linkType: false,
    addLabel: true,
};

export default UserField;




