import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import ArchiveIcon from '@material-ui/icons/Archive';

const styles = theme => ({
    avatar:{
        color: "inherit",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginRight: "-8px",
        
    },
    avatarChildren:{
        width: '1em',
        height: '1em',
    },
});




const ArchiveChip = ({ className, classes, status, translate, record, }) => {
    if (!record || !record.isArchive) return null;
    
    return (
        <Chip avatar={<Avatar><ArchiveIcon /></Avatar>} label={translate(`raExt.notification.is_archive`)} classes={{avatar:classes.avatar, avatarChildren: classes.avatarChildren}} className={`${className}`} />
    );
}


export default compose( withStyles(styles), translate, )(ArchiveChip);