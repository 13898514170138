import gql from 'graphql-tag'
import { apolloAuthClient } from './graphqlProvider';

export default () => {
    return apolloAuthClient.mutate({
        mutation: gql`
            mutation getTokenForGuest {
                guest {
                    token
                }
            }
        `,
        onError: (error) => {
            alert(error)
        }
    });
}
